import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { AiOutlineSearch, AiFillCaretDown } from 'react-icons/ai';
import { Button, Card, DatePicker, Form, Input, Select, Spin } from 'antd';
import React, { useState } from 'react';
import searchIcon from 'assets/img/icons/search-icon.svg';
import exportIcon from 'assets/img/icons/export-icon.svg';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import dateIcon from 'assets/img/icons/date-icon.svg';
import { renderStatusOptions } from '../../util/ProjectUtil';
import { COMMON_STATUS_TAG } from 'constants/Projects';
import { formatDatePiker } from 'helpers/formatDate';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE } from '../DailyReport';
import { useQuery } from 'react-query';
import { USE_GET_USER_PROJECT } from 'constants/Projects';
import { apiGetUserProject } from 'api/projects';
import classNames from 'classnames';
import { FIELD_KEY } from 'constants/Projects';
import { handleExportExcel } from 'helpers';
import configs from 'config';

const FilterDailyReport = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isCollapse, setIsCollapse] = useState(true);

  const { data: projects, isLoading } = useQuery([USE_GET_USER_PROJECT], () => apiGetUserProject(), {
    select: (res) =>
      res?.data?.projects.map((project) => {
        return { value: project.id, label: project.name };
      }),
    onError: (error) => console.log(error),
  });

  const handleReset = () => {
    setFilter({ page: DEFAULT_CURRENT, limit: DEFAULT_PAGE_SIZE });
    form.resetFields();
  };

  const handleFinish = (values) => {
    form.setFieldValue(FIELD_KEY.KEYWORD, values?.key_word?.trim());
    setFilter({
      ...filter,
      ...values,
      from_date: formatDatePiker(values?.from_date, 'YYYY-MM-DD'),
      to_date: formatDatePiker(values?.to_date, 'YYYY-MM-DD'),
      [FIELD_KEY.KEYWORD]: values?.key_word?.trim(),
      page: DEFAULT_CURRENT,
    });
  };

  const handleExport = () => {
    const data = form.getFieldsValue(true);
    form.setFieldValue(FIELD_KEY.KEYWORD, data?.key_word?.trim());
    const params = {
      ...data,
      key_word: data?.key_word?.trim(),
      from_date: formatDatePiker(data?.from_date, 'YYYY-MM-DD'),
      to_date: formatDatePiker(data?.to_date, 'YYYY-MM-DD'),
    };

    handleExportExcel(`${configs.API_DOMAIN}/api/export-daily-reports`, params, 'daily_reports_member.xlsx', '');
  };

  return (
    <>
      <Card
        title={
          <span className={styles.searchContainer} onClick={() => setIsCollapse(!isCollapse)}>
            <img src={searchIcon} alt="" className={styles.searchIcon} />
            <span className={styles.searchTitle}>{t('project.daily.filter.search')}</span>
          </span>
        }
        extra={
          <Button
            type="ghost"
            shape="circle"
            size="middle"
            className={styles.collapse}
            icon={isCollapse ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            onClick={() => setIsCollapse(!isCollapse)}
          />
        }
        className={styles.filterContainer}
        bodyStyle={{ display: !isCollapse ? 'none' : 'block' }}
      >
        <Form
          form={form}
          name="filter-daily-report"
          layout="vertical"
          className="materialStyle"
          size="middle"
          onFinish={handleFinish}
          autoComplete="off"
        >
          <div className={`${styles.filterRow} ${styles.filterSearch}`}>
            <Form.Item label={t('project.daily.filter.search')} name={FIELD_KEY.KEYWORD}>
              <Input allowClear prefix={<AiOutlineSearch />} maxLength={50} />
            </Form.Item>
          </div>
          <div className={`${styles.filterRow} ${styles.filterGrid}`}>
            <Form.Item label={t('project.daily.filter.project')} name={FIELD_KEY.PROJECT_ID}>
              <Select
                showArrow="true"
                allowClear
                showSearch
                className={classNames(styles.projectName, 'customAutoComplete')}
                suffixIcon={<AiFillCaretDown />}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={projects}
                notFoundContent={
                  isLoading && (
                    <div className="text-center">
                      <Spin />
                    </div>
                  )
                }
                maxTagCount="responsive"
                mode="multiple"
              />
            </Form.Item>
            <Form.Item label={t('project.daily.filter.status')} name={FIELD_KEY.STATUS}>
              <Select
                showArrow="true"
                allowClear
                suffixIcon={<AiFillCaretDown />}
                dropdownRender={(menu) => menu}
                options={renderStatusOptions(COMMON_STATUS_TAG)}
              />
            </Form.Item>

            <Form.Item label={t('project.daily.filter.startDate')} name={FIELD_KEY.FROM_DATE}>
              <DatePicker
                placeholder=""
                allowClear
                inputReadOnly
                format="DD/MM/YYYY"
                // eslint-disable-next-line jsx-a11y/alt-text
                suffixIcon={<img src={dateIcon} />}
                disabledDate={(date) => {
                  const endDate = form.getFieldValue(FIELD_KEY.TO_DATE)?.startOf('day');
                  return date && endDate && date.startOf('day') > endDate;
                }}
              />
            </Form.Item>
            <Form.Item label={t('project.daily.filter.endDate')} name={FIELD_KEY.TO_DATE}>
              <DatePicker
                placeholder=""
                allowClear
                inputReadOnly
                format="DD/MM/YYYY"
                // eslint-disable-next-line jsx-a11y/alt-text
                suffixIcon={<img src={dateIcon} />}
                disabledDate={(date) => {
                  const startDate = form.getFieldValue(FIELD_KEY.FROM_DATE);
                  return date && startDate && date < startDate;
                }}
              />
            </Form.Item>
          </div>
          <div className={`${styles.filterRow} ${styles.filterRowButton}`}>
            <Button
              className={styles.btnExport}
              icon={<img src={exportIcon} alt="export-icon" />}
              onClick={handleExport}
            >
              {t('project.daily.listReport.export')}
            </Button>
            <Button className={styles.btnReset} onClick={handleReset}>
              {t('project.daily.filter.reset')}
            </Button>
            <Button className={styles.btnSubmit} htmlType="submit">
              {t('project.daily.filter.submit')}
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default FilterDailyReport;
