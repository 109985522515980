import React from 'react';
import { useTranslation } from 'react-i18next';
import NavComponent from './../../../components/Nav';

const Nav = () => {
  const { t } = useTranslation();

  const routes = [
    {
      path: '/companies/handbook',
      name: t('company.handbookEmployee'),
      tab: 1,
    },
    {
      path: '/companies/employees',
      name: t('company.employeeList'),
      tab: 2,
    },
  ];

  return (
    <>
      <NavComponent routes={routes} />
    </>
  );
};

export default Nav;
