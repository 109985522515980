import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { NavLink as RouteLink } from 'react-router-dom';
import { useParams } from 'react-router';

import './styles.scss';
import Loading from 'components/Loading';
import { useDetailDepartment, useOtherMember } from 'hook/useDepartment';
import { useInforAuth } from 'hook/useAuth';
import AlertComponent from 'components/Alert';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import { NOT_VIEW } from 'constants/Company';
import { handleOnError } from 'helpers';

const DepartmentDiagram = () => {
  const { t } = useTranslation();
  let param = useParams();
  const departmentId = param.departmentId;
  const { data: dataInforUser } = useInforAuth();

  const { data: udata, isLoading: isLoadingMember } = useDetailDepartment(departmentId);
  const { data: uOtherMember, isLoading: isLoadingOtherMember } = useOtherMember({ departmentId });

  const [dataOrignal, setDataOrignal] = useState([]);
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);

  const notification = () => {
    setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
    setShowAlert(true);
  };

  useEffect(() => {
    setDataOrignal(udata?.data);
  }, [udata]);

  const checkRole = (userId) => {
    if (
      (dataInforUser?.hr_permission &&
        (dataInforUser?.hr_permission?.user_detail_permission !== NOT_VIEW || dataInforUser?.user_id === userId)) ||
      (!dataInforUser?.hr_permission && dataInforUser?.user_id === userId)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container fluid className="deparment-detail">
      <Breadcrumb className="detail-breadcrumb">
        <BreadcrumbItem>
          <RouteLink className="previous-name" to={{ pathname: '/companies/handbook' }}>
            {t('divisionDiagram.name')}
          </RouteLink>
        </BreadcrumbItem>
        <BreadcrumbItem className="active-name" active>
          {dataOrignal?.division?.name}
        </BreadcrumbItem>
      </Breadcrumb>

      <Row>
        <Col md="3">
          <div className="information">
            <div className="header">
              <div className="bg-img"></div>
              <div className="avatar">
                <img
                  onError={handleOnError}
                  alt="just cool man"
                  src={dataOrignal?.division?.avatar ? dataOrignal?.division?.avatar : defaultAvatar}
                />
              </div>
              <p className="department-name">{dataOrignal?.division?.name}</p>
            </div>
            <div className="description">
              <ul>
                <li>
                  <p className="head-title">{t('divisionDiagram.divisionDetail.headTitle')}</p>

                  {checkRole(dataOrignal?.manager?.user_id) && dataOrignal?.manager != null && (
                    <RouteLink to={{ pathname: '/member/detail/' + dataOrignal?.manager?.user_id }}>
                      {dataOrignal?.manager?.name}
                    </RouteLink>
                  )}
                  {!checkRole(dataOrignal?.manager?.user_id) && dataOrignal?.manager != null && <div>{dataOrignal?.manager?.name}</div>}
                  {dataOrignal?.manager === null && (t('divisionDiagram.hasNotInfo'))}
                </li>
                {dataOrignal?.custom_role_division?.length !== 0 &&
                  dataOrignal?.custom_role_division?.map((item, key) => {
                    return (
                      <li index={key} className="border-top">
                        <p className="head-title">{item.role_name_division}</p>
                        {checkRole(item?.user_id) && (
                          <RouteLink
                            to={{
                              pathname: '/member/detail/' + item?.user_id,
                            }}
                          >
                            {item.name}
                          </RouteLink>
                        )}
                        {!checkRole(item?.user_id) && <div>{item.name}</div>}
                      </li>
                    );
                  })}
                <li className="border-top">
                  <p className="head-title">{t('divisionDiagram.divisionDetail.foundation')}</p>
                  <p>{dataOrignal?.division?.date}</p>
                </li>
                <li className="border-top">
                  <p className="head-title">{t('divisionDiagram.divisionDetail.branch')}</p>
                  <p>{dataOrignal?.division?.parent_name}</p>
                </li>
                <li className="border-top">
                  <p className="head-title">{t('divisionDiagram.divisionDetail.membersAmount')}</p>
                  <p>{dataOrignal?.division?.total_member}</p>
                </li>
                <li className="border-top">
                  <p className="head-title">{t('divisionDiagram.divisionDetail.address')}</p>
                  <p>{dataOrignal?.division?.address}</p>
                </li>
                <li className="border-top">
                  <p className="head-title ">{t('divisionDiagram.divisionDetail.description')}</p>
                  <p>{dataOrignal?.division?.description}</p>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col md="9">
          <div className="members-projects">
            {!isLoadingMember &&
              !isLoadingOtherMember &&
              !dataOrignal?.member?.length &&
              !uOtherMember?.data?.member?.length &&
              t('divisionDiagram.divisionDetail.emptyTeam')}
            {isLoadingMember && (
              <p className="error-no-data">
                <Loading addClass="mt-3" />
              </p>
            )}

            {uOtherMember?.data?.check !== 0 && (
              <Row>
                <div className="list-members">
                  <div className="header">
                    <p className="head-title border-bot list-title">
                      {!!dataOrignal?.member?.length
                        ? t('divisionDiagram.divisionDetail.member')
                        : t('divisionDiagram.divisionDetail.other')}{' '}
                      ({uOtherMember?.data?.total_member})
                    </p>
                  </div>
                  <div className="body border-top">
                    {uOtherMember?.data?.member?.map((member, i) => {
                      return (
                        <>
                          {checkRole(member?.user_id) && (
                            <RouteLink
                              className="member"
                              to={{
                                pathname: '/member/detail/' + member?.user_id,
                              }}
                            >
                              <div index={i}>
                                <div className="member-body">
                                  <img onError={handleOnError} className="avatar-member" alt="just cool" src={member.avatar || defaultAvatar} />
                                  <div className="description">
                                    <div className="name">{member.name}</div>
                                    <div className="description-body">
                                      <div className="email">{member.email}</div>
                                      <div className="code">
                                        {t('divisionDiagram.divisionDetail.memberCode')} {member.code}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </RouteLink>
                          )}
                          {!checkRole(member?.user_id) && (
                            <div className="member" onClick={notification}>
                              <div index={i}>
                                <div className="member-body">
                                  <img onError={handleOnError} className="avatar-member" alt="just cool" src={member.avatar || defaultAvatar} />
                                  <div className="description">
                                    <div className="name">{member.name}</div>
                                    <div className="description-body">
                                      <div className="email">{member.email}</div>
                                      <div className="code">
                                        {t('divisionDiagram.divisionDetail.memberCode')} {member.code}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </Row>
            )}

            {dataOrignal?.member?.map((team, key) => {
              return (
                <Row index={key}>
                  <div className="list-members">
                    <div className="header">
                      <p className="head-title border-bot list-title">
                        {team.name} ({team.total_member})
                      </p>
                    </div>
                    <div className="body border-top">
                      {team?.member?.length === 0 && t('divisionDiagram.divisionDetail.emptyTeam')}
                      {team?.member?.map((member, i) => {
                        return (
                          <>
                            {checkRole(member?.user_id) && (
                              <RouteLink
                                className="member"
                                to={{
                                  pathname: '/member/detail/' + member?.user_id,
                                }}
                              >
                                <div index={i}>
                                  <div className="member-body">
                                    <img
                                      onError={handleOnError}
                                      className="avatar-member"
                                      alt="just cool"
                                      src={member.avatar || defaultAvatar}
                                    />
                                    <div className="description">
                                      <div className="name">{member.name}</div>
                                      <div className="description-body">
                                        <div className="email">{member.email}</div>
                                        <div className="code">
                                          {t('divisionDiagram.divisionDetail.memberCode')} {member.code}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </RouteLink>
                            )}
                            {!checkRole(member?.user_id) && (
                              <div className="member" onClick={notification}>
                                <div index={i}>
                                  <div className="member-body">
                                    <img
                                      onError={handleOnError}
                                      className="avatar-member"
                                      alt="just cool"
                                      src={member.avatar || defaultAvatar}
                                    />
                                    <div className="description">
                                      <div className="name">{member.name}</div>
                                      <div className="description-body">
                                        <div className="email">{member.email}</div>
                                        <div className="code">
                                          {t('divisionDiagram.divisionDetail.memberCode')} {member.code}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </Row>
              );
            })}

            {!!uOtherMember?.data?.member?.length && uOtherMember?.data?.check === 0 && (
              <Row>
                <div className="list-members">
                  <div className="header">
                    <p className="head-title border-bot list-title">
                      {!!dataOrignal?.member?.length
                        ? t('divisionDiagram.divisionDetail.member')
                        : t('divisionDiagram.divisionDetail.other')}{' '}
                      ({uOtherMember?.data?.total_member})
                    </p>
                  </div>
                  <div className="body border-top">
                    {uOtherMember?.data?.member?.map((member, i) => {
                      return (
                        <>
                          {checkRole(member?.user_id) && (
                            <RouteLink
                              className="member"
                              to={{
                                pathname: '/member/detail/' + member?.user_id,
                              }}
                            >
                              <div index={i}>
                                <div className="member-body">
                                  <img onError={handleOnError} className="avatar-member" alt="just cool" src={member.avatar || defaultAvatar} />
                                  <div className="description">
                                    <div className="name">{member.name}</div>
                                    <div className="description-body">
                                      <div className="email">{member.email}</div>
                                      <div className="code">
                                        {t('divisionDiagram.divisionDetail.memberCode')} {member.code}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </RouteLink>
                          )}
                          {!checkRole(member?.user_id) && (
                            <div className="member" onClick={notification}>
                              <div index={i}>
                                <div className="member-body">
                                  <img onError={handleOnError} className="avatar-member" alt="just cool" src={member.avatar || defaultAvatar} />
                                  <div className="description">
                                    <div className="name">{member.name}</div>
                                    <div className="description-body">
                                      <div className="email">{member.email}</div>
                                      <div className="code">
                                        {t('divisionDiagram.divisionDetail.memberCode')} {member.code}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </Container>
  );
};

export default DepartmentDiagram;
