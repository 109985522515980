import React, { useCallback, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';
import { ButtonBase, Grid, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import './style.scss';
import "../../Projects/style.scss"
import SearchComponent from 'components/Search';
import iconLoading from 'assets/img/icons/loading.png';
import OT from 'assets/img/icons/ot.svg';
import Fire from 'assets/img/icons/fire.svg';
import Loading from 'components/Loading';
import * as Constants from 'constants/index.js';
import TimeKeeping from './../index.js';
import DateMonthComponent from 'components/DateTime/DateMonth.js';
import DateDayComponent from 'components/DateTime/DateDay.js';
import { useListTableOT } from 'hook/useTimeSheet';
import { DEFAULT_LIMIT_LARGE, DEFAULT_PAGE_MUI } from 'constants/index.js';
import { USE_LIST_TABLE_OT } from 'constants/TimeSheet';
import { ThemContext } from 'layouts/Member';

const ListOT = () => {
  const { t } = useTranslation();
  const userId = useContext(ThemContext);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    month: moment().format('MM/YYYY'),
    date_ot: null,
    key_word: '',
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT_LARGE,
    user_id: userId,
  });

  useEffect(() => {
    setFilter((prev) => {
      return {
        ...prev,
        user_id: userId,
      };
    });
  }, [userId]);

  const { data: uListTableOT, isLoading: isLoadingListTableOT } = useListTableOT(filter);

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNameEmployee = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        key_word: value,
        page: DEFAULT_PAGE_MUI,
      });
    }),
  );

  const handleReset = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilter({
      month: moment().format('MM/YYYY'),
      date_ot: null,
      key_word: '',
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT_LARGE,
      user_id: userId,
    });
    await queryClient.invalidateQueries(USE_LIST_TABLE_OT);
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid container className="list-overtime">
      <Grid className="menu-slider-around nav-fixed">
        <TimeKeeping />
      </Grid>
      <div className="list-assets">
        <div>
          <div className="profile-box table-list table-border-show m-0">
            <Paper>
              <TableContainer className={`${true && 'scroll-custom'}`}>
                <Table aria-label="sticky table" className={`${true && 'table-scroll'}`}>
                  <TableHead className="asset-header">
                    <TableRow className="asset-header-row">
                      <TableCell className="short">{t('timekeeping.request.STT')}</TableCell>
                      <TableCell className="project-date">{t('timekeeping.OT.project')}</TableCell>
                      <TableCell className="project-date">{t('timekeeping.OT.date')}</TableCell>
                      <TableCell>{t('timekeeping.OT.workTime')}</TableCell>
                      <TableCell>{t('timekeeping.OT.workTimeOtOn')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingListTableOT ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Loading addClass="mt-3" />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {uListTableOT && uListTableOT?.data?.data?.length ? (
                          <>
                            {uListTableOT?.data?.data?.map((item, index) => {
                              let stt = (filter.page * filter.per_page) + index + 1;
                              return (
                                <TableRow hover key={index}>
                                  <TableCell>{stt >= 10 ? stt : <>0{stt}</>}</TableCell>
                                  <TableCell>{item.project}</TableCell>
                                  <TableCell>{moment(item?.date).format('DD/MM/YYYY')}</TableCell>
                                  <TableCell>{item.total}</TableCell>
                                  <TableCell>{item.value}</TableCell>
                                </TableRow>
                              )
                            })}
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div className="error-no-data">{t('common.notData')}</div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {uListTableOT?.data?.total > 0 && (
                <TablePagination
                  rowsPerPageOptions={Constants.PER_PAGE_OPTION_V2}
                  component="div"
                  count={uListTableOT?.data?.total}
                  rowsPerPage={filter?.per_page}
                  page={filter?.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                />
              )}
            </Paper>
          </div>
        </div>
        <div className="list-assets__condition">
          <div className="box-filter m-0">
            <div className="box-waiting-content">
              <img src={OT} alt={OT} />
              <div className="box-waiting-text">
                <div className="waiting-text">{t('timekeeping.OT.otDoesNotIncludeCoefficients')}</div>
                <div className="waiting-number">{uListTableOT?.total_overtime}</div>
              </div>
            </div>
            <div className="box-waiting-content second">
              <div className="content">
                <img src={OT} alt={OT} />
                <div className="box-waiting-text">
                  <div className="waiting-text">{t('timekeeping.OT.otFactorIncluded')}</div>
                  <div className="waiting-number">{uListTableOT?.total_overtime_coefficient}</div>
                </div>
              </div>
            </div>
            <div className="box-waiting-content second pb-0">
              <div className="content">
                <img src={Fire} alt={Fire} />
                <div className="box-waiting-text">
                  <div className="waiting-text">{t('timekeeping.OT.numberOfOtProjects')}</div>
                  <div className="waiting-number">{uListTableOT?.total_project}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-filter m-0">
            <div className={classNames(`text`)}>
              <DateMonthComponent
                label=""
                name="input_date"
                valueDefault={typeof filter.month === 'string' ? moment(filter.month, 'MM') : filter.month}
                onChangeHandle={(value) => {
                  setFilter({
                    ...filter,
                    month: moment(value).format('MM/YYYY'),
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                placeholder={t('common.selectMonth')}
                sizeDate="small"
              />
            </div>
            {/* Filter by search input */}
            <div className={classNames(`custom`)}>
              <SearchComponent
                txtPlaceholder={t('employees.searchNameProject')}
                addClass="input-search-box"
                handleSearch={handleSearchNameEmployee}
                defaultValue={filter?.key_word}
              />
            </div>
            <div className={classNames(`custom`)}>
              <DateDayComponent
                label=""
                name="start_date"
                valueDefault={
                  typeof filter.date_ot === 'string' ? moment(filter.date_ot, 'DD/MM/YYYY') : filter.date_ot
                }
                // onChangeHandle={(value) => handleStartDate(value)}
                placeholder={t('timekeeping.OT.dateOT')}
                onChangeHandle={(value) => {
                  setFilter({
                    ...filter,
                    date_ot: moment(value).format('DD/MM/YYYY'),
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                sizeDate="small"
              />
            </div>
            <div className="border-top">
              <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                {t('common.reset')}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default ListOT;
