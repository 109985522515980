import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidate = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    work_name: Yup.string().trim().required(t('messages.thisIsARequiredField')),
    hr_date_log: Yup.string().required(t('messages.thisIsARequiredField')),
    hr_shift_log: Yup.string().required(t('messages.thisIsARequiredField')),
    content: Yup.string().trim().required(t('messages.thisIsARequiredField')),
    next_person: Yup.array().min(1, t('messages.thisIsARequiredField')).required(t('messages.thisIsARequiredField')),
  });
  return validationSchema;
};
