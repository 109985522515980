import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ButtonBase, FormControl, debounce } from '@mui/material';

import totalIcon from 'assets/img/icons/common/company/total-icon.svg';
import iconLoading from './../../../../assets/img/icons/loading.png';
import stylesCommon from './../styles.module.scss';
import './styles.scss';

import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import DateDayComponent from 'components/DateTime/DateDay.js';
import RadioComponent from 'components/RadioComponent';
import { ThemContext } from 'layouts/Member';
import Nav from '../NavAssets/Nav.js';
import { DEFAULT_LIMIT, PER_PAGE_OPTION } from 'constants/index.js';
import { STATUS_ASSET_USING, STATUS_ASSET_CONFISCATION, USE_USER_LIST_ASSETS } from 'constants/Assets.js';
import { useGetListAssetsUser } from 'hook/userAssets.js';
import configs from 'config';

function getTextNull(text) {
  if (!text) {
    return 'N/A';
  }
  return text;
}

const DEFAULT_PAGE_MUI = 1;

const ListAsset = () => {
  const { t } = useTranslation();
  const userId = useContext(ThemContext);
  const queryClient = useQueryClient();

  const [filter, setFilter] = useState({
    start_date: null,
    end_date: null,
    status: '',
    key_word: null,
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT,
    user_id: userId,
  });

  useEffect(() => {
    setFilter((prev) => {
      return {
        ...prev,
        user_id: userId,
      };
    });
  }, [userId]);

  const { data: dataAsset, isLoading } = useGetListAssetsUser(filter);

  const [dataStatus, setDatStatus] = useState([]);

  useEffect(() => {
    if (dataAsset) {
      const status = [
        { id: STATUS_ASSET_USING, name: t('assets.using'), total: dataAsset?.asset_using },
        { id: STATUS_ASSET_CONFISCATION, name: t('assets.confiscated'), total: dataAsset?.asset_recover },
      ];
      setDatStatus(status);
    }
  }, [t, dataAsset]);

  const getStatusAsset = (status) => {
    if (status === STATUS_ASSET_USING) {
      return {
        color: 'color-green2',
        text: t('assets.using'),
      };
    }

    return {
      color: 'color-red',
      text: t('assets.confiscated'),
    };
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        key_word: value,
        page: DEFAULT_PAGE_MUI,
      });
    }),
  );

  const handleStartDate = (value) => {
    setFilter({ ...filter, date: moment(value).format('DD/MM/YYYY') });
  };

  const handleChangeStatus = (event) => {
    if (event.target.value === '') {
      setFilter({ ...filter, status: '', page: DEFAULT_PAGE_MUI });
    } else {
      setFilter({ ...filter, status: event.target.value, page: DEFAULT_PAGE_MUI });
    }
  };

  const handleResetFilter = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilter({
      start_date: null,
      end_date: null,
      status: '',
      key_word: '',
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT,
      user_id: userId,
    });
    await queryClient.invalidateQueries(USE_USER_LIST_ASSETS);
  };

  const { control, setValue } = useForm({
    mode: 'onBlur',
  });

  return (
    <section className="list-assets-employee">
      <Grid container spacing={2}>
        <Grid container className="nav-fixed pl-15">
          <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
            <Grid container>
              <Nav />
            </Grid>
          </Grid>
          <Grid item xs sm md lg xl className={stylesCommon.buttonGroupTop}>
            <a href={`${configs.DOMAIN_WORK_FOLLOW}/request`} target="blank">
              <ButtonComponent icon={<AddIcon />} text={t('request.createRequest')} />
            </a>
          </Grid>
        </Grid>
      </Grid>
      <div className={stylesCommon.assets}>
        <div className={stylesCommon.assets__list}>
          <div className="table-list table-border-show">
            <Paper>
              <TableContainer>
                <Table className="table-border-bottom-none">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('assets.codeAsset')}</TableCell>
                      <TableCell>{t('assets.nameAsset')}</TableCell>
                      <TableCell>{t('assets.dateDelivery')}</TableCell>
                      <TableCell>{t('assets.dateConfiscation')}</TableCell>
                      <TableCell>{t('assets.usageStatus')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Loading addClass="mt-3" />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {dataAsset?.assets?.data && dataAsset?.assets?.data.length ? (
                          <>
                            {dataAsset?.assets?.data?.map((item) => (
                              <TableRow hover key={item.id}>
                                <TableCell>{item.asset_code}</TableCell>
                                <TableCell className="font-weight-bold">{item.asset_name}</TableCell>
                                <TableCell>{getTextNull(item.delivery_date)}</TableCell>
                                <TableCell>{getTextNull(item.return_date)}</TableCell>
                                <TableCell className={getStatusAsset(item.status).color}>
                                  {getStatusAsset(item.status).text}
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div className="error-no-data">{t('common.notData')}</div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {dataAsset?.total_assets > 0 && (
                <div className="table-pagination">
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTION}
                    component="div"
                    count={dataAsset?.total_assets}
                    rowsPerPage={filter?.per_page}
                    page={filter?.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                  />
                </div>
              )}
            </Paper>
          </div>
        </div>

        <div className={stylesCommon.assets__condition}>
          <div className={classNames(`${stylesCommon.boxTotal} border-show`)}>
            <img src={totalIcon} alt={totalIcon} />
            <div className={stylesCommon.boxText}>
              <div className={stylesCommon.text}>{t('assets.totalAsset')}</div>
              <div className={stylesCommon.number}>{dataAsset?.total_assets > 0 ? dataAsset?.total_assets : 0}</div>
            </div>
          </div>

          <div className={'bg-white pd-15 border-show '}>
            <div className="search mb-3">
              <SearchComponent
                txtPlaceholder={t('assets.findAssetNameAssetCode')}
                addClass="reset-btn-search"
                handleSearch={handleSearch}
                defaultValue={filter?.key_word}
              />
            </div>
            <div>
              <DateDayComponent
                label=""
                name="start_date"
                valueDefault={typeof filter.date === 'string' ? moment(filter.date, 'DD/MM/YYYY') : filter.date}
                onChangeHandle={(value) => handleStartDate(value)}
                placeholder={t('assets.dateHandoverDateConfiscation')}
                sizeDate="small"
              />
            </div>
            <div className="strike" />
            <div>
              <FormControl component="fieldset">
                <div className={stylesCommon.filterTitle}>{t('common.status')}</div>
                {dataStatus && (
                  <RadioComponent
                    name="status"
                    control={control}
                    defaultValue={filter?.status}
                    isHaveAll={true}
                    radioList={dataStatus}
                    handleChange={handleChangeStatus}
                    setValue={setValue}
                  />
                )}
              </FormControl>
            </div>

            <div className="strike" />

            <ButtonBase className="btn-reset" size="lg" onClick={handleResetFilter}>
              <img src={iconLoading} alt="Reset" width="20px" /> &emsp;
              {t('common.reset')}
            </ButtonBase>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListAsset;
