import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import iconInfo from 'assets/img/icons/info.png';
import styles from './../style.module.scss';
import Loading from 'components/Loading';

const Infomation = (props) => {
  const { uProfileUserInforContract, isLoading, userId } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.profileBoxInfo}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className={styles.profileTitle}>
              <img alt={iconInfo} src={iconInfo} className="ml-13" />
              {t('contract.contractCurrent')}
            </div>
          </Col>
        </Row>

        {(isLoading || !userId) &&
          <p className="error-no-data">
            <Loading addClass="mt-3" />
          </p>}
        {!isLoading && userId && (uProfileUserInforContract ? (
          <Grid container spacing={2} className={styles.styleInfoMui}>
            <Grid item xs={12} md={12} lg={7}>
              <div className={styles.col1InfoLeft}>{t('contract.contractType')}</div>
              <div>
                {uProfileUserInforContract?.contract_category_name === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.contract_category_name
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <div className={styles.col2InfoLeft}>{t('contract.division')}</div>
              <div className={classNames(` auto-scroll-hidden`)}>
                {uProfileUserInforContract?.division_name === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.division_name
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={12} lg={7} className={styles.styleBackground}>
              <div className={styles.col1InfoLeft}>{t('contract.contractCode')}</div>
              <div className={classNames(` auto-scroll-hidden`)}>
                {uProfileUserInforContract?.code === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.code
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={5} className={styles.styleBackground}>
              <div className={styles.col2InfoLeft}>{t('contract.dateCreateContract')}</div>
              <div className={classNames(` auto-scroll-hidden`)}>
                {uProfileUserInforContract?.date_created === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.date_created
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={12} lg={7}>
              <div className={styles.col1InfoLeft}>{t('contract.contractStart')}</div>
              <div>
                {uProfileUserInforContract?.start_date === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.start_date
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <div className={styles.col2InfoLeft}>{t('contract.userCreateContract')}</div>
              <div>
                {uProfileUserInforContract?.created_user === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.created_user
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={12} lg={7} className={styles.styleBackground}>
              <div className={styles.col1InfoLeft}>{t('contract.contractEnd')}</div>
              <div>
                {uProfileUserInforContract?.end_date === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.end_date
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={5} className={styles.styleBackground}>
              <div className={styles.col2InfoLeft}>{t('contract.userEditContract')}</div>
              <div>
                {uProfileUserInforContract?.user_updated === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.user_updated
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={12} lg={7}>
              <div className={styles.col1InfoLeft}>{t('contract.employeeType')}</div>
              <div className={styles.tableCell}>
                {uProfileUserInforContract?.group_name === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.group_name
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <div className={styles.col2InfoLeft}>{t('contract.dateEditContract')}</div>
              <div>
                {uProfileUserInforContract?.date_updated === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.date_updated
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={12} lg={7} className={styles.styleBackground}>
              <div className={styles.col1InfoLeft}>{t('contract.position')}</div>
              <div className={styles.tableCell}>
                {uProfileUserInforContract?.position_name === null ? (
                  <span>{t('infoEmployee.notUpdate')}</span>
                ) : (
                  uProfileUserInforContract?.position_name
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={5} className={classNames(styles.styleBackground, "p-0")}></Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} className={styles.styleInfoMui}>
            <Grid item xs={12} className={styles.styleBackground}>
              <div className="error">{t('common.notData')}</div>
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );
};

export default Infomation;
