import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LENGTH_NO_BORDER } from 'constants/TimeSheet';
import {
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS,
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED,
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY
} from 'constants/TimeKeeping';
import { STYLE_SCROLL_CUSTOM } from 'constants/StyleScroll'
import { useInforAuth } from 'hook/useAuth';

const { Tooltip } = require('@mui/material');

export const StyledTooltip = styled((props) => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    background-color: #f3f7fb;
    padding: 0 !important;
    border-radius: 5px;
    border: 1px solid #e9ebee;
    box-shadow: 0px 5px 25px rgb(34 51 84 / 15%);
    overflow: auto;
    max-height: 320px;
    margin-top: 7px !important;

    .request-info-day {
      font-size: 16px;
      color: #223354;
      margin-bottom: 0;
      font-weight: 500;
    }

    .request-item {
      padding: 5px 8px!important;
    }

    .active-border-bottom {
      border-bottom: 1px solid #e9ebee;

      &:last-child {
        border-bottom: none;
      }
    }

    .added-info {
      color: #8d8d8d;
      font-size: 12px;
    }
    .title {
      color: #223354;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin: 14px 0 0 10px;
    }
    .text-color {
      color: black;
      font-weight: 500;
    }
    .value {
      font-weight: 500;
    }
    .status {
      font-weight: 500;
      color: #0057FF;
    }
    p {
      margin: 0 0 0 0;
    }
    ${STYLE_SCROLL_CUSTOM}
  }
  
`;

export const TooltipContent = ({ item, type }) => {
  const { t } = useTranslation();
  const { data: me } = useInforAuth();
  let data = item.map(it => it).filter(ind => type.includes(String(ind.type)));

  return (
    <>
      <p className='title'>{t('timekeeping.listRequets')}</p>
      {data &&
        data?.map((info, index) => (
          <div
            className={classNames('request-item text-color', item.length === LENGTH_NO_BORDER ? 'border-0' : 'active-border-bottom')}
            key={index}
          >
            <p>{t('timekeeping.request.typeRequest')} : <span className='value'>{info.type_name}</span></p>
            <p>{t('timekeeping.request.createdDay')} : <span>{info.date}</span></p>
            <p>{t('timekeeping.created_by')} : <span className='value'>{info.user_name}</span></p>
            <p>{t('timekeeping.status')} : &nbsp;
              <span className='status-open value'>{info.status === STATUS_REQUEST_OPEN && t('timekeeping.request.statusOpen')}</span>
              <span className='status-in-progress value'>{info.status === STATUS_REQUEST_IN_PROGRESS && t('timekeeping.request.statusInProgress')}</span>
              <span className='status-completed value'>{info.status === STATUS_REQUEST_COMPLETED && t('timekeeping.request.statusCompleted')}</span>
              <span className='status-deny value'>{info.status === STATUS_REQUEST_DENY && t('timekeeping.request.statusDeny')}</span>
              <span className='status-rework value'>{info.status === STATUS_REQUEST_REWORK && t('timekeeping.request.statusRework')}</span>
              <span className='status-recall value'>
                {info.status === STATUS_REQUEST_RECALL ?
                  me.user_id === info.updated_by ? t('timekeeping.request.statusCancelled') : t('timekeeping.request.statusHasBeenRemoved')
                  : null}
              </span>
            </p>
          </div>
        ))}
    </>
  );
};
