const ProgressBar = (props) => {
  const { bgcolor, completed, classNameAdd } = props;

  const containerStyles = {
    height: 4,
    width: '95%',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
    marginTop: 10,
    marginRight: 10,
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor ? bgcolor : '#2ECC71',
    borderRadius: 'inherit',
    textAlign: 'right',
  };

  return (
    <div className={classNameAdd} style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
