import { Button, Card, Divider, Space, Spin, Tooltip, message } from 'antd';
import React, { useState } from 'react';
import infoIcon from 'assets/img/icons/info-icon.svg';
import addIcon from 'assets/img/icons/add.svg';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { FIELD_KEY } from 'constants/Projects';
import { CreateDailyReport, UpdateDailyReport } from '../create-update';
import { MdDelete, MdEdit } from 'react-icons/md';
import { isShowButtonEditDelete, renderStatusTag } from '../../util/ProjectUtil';
import CustomTable from './CustomTable';
import { formatDatePiker } from 'helpers/formatDate';
import showConfirm from '../create-update/showConfirm';
import { useMutation } from 'react-query';
import { apiDeleteDailyReport } from 'api/projects';
import { REPORT_APPROVED, ALL_PROCESS_TYPE } from 'constants/Projects';
import { MAX_LENGTH_30, MAX_LENGTH_50 } from 'constants/index.js';
import DetailDailyReport from './DetailDailyReport';

const TableDailyReport = ({ listDailyReport, setFilter, filter, isFetching, refetchDaiLyReports }) => {
  const { t } = useTranslation();
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  let isCanClickOnRow = true;

  const deleteDailyReport = useMutation((id) => apiDeleteDailyReport(id), {
    onSuccess: (res) => {
      message.success(t('common.deleteDataSuccess'));
      refetchDaiLyReports();
    },
    onError: (error) => console.log(error),
  });

  const columns = [
    {
      id: FIELD_KEY.INDEX,
      dataIndex: FIELD_KEY.INDEX,
      title: t('project.daily.listReport.index'),
      width: 60,
      align: 'center',
      fixed: 'left',
      render: (_, record, index) => {
        const stt = (filter?.page - 1) * filter?.limit + index + 1;
        return stt >= 10 ? stt : `0${stt}`;
      },
    },
    {
      id: FIELD_KEY.TASK_ID,
      dataIndex: FIELD_KEY.TASK_ID,
      width: 245,
      title: t('project.daily.listReport.task'),
      fixed: 'left',
      render: (_, record) => (
        <Tooltip title={record?.title} overlayClassName="custom-tooltip" color="white">
          <span className={`${styles.textOver1Lines} ${styles.cursorPointer} text-bold`}>{`${record?.title?.substring(
            0,
            MAX_LENGTH_30,
          )} ${record?.title?.length >= MAX_LENGTH_30 ? '...' : ''}`}</span>
        </Tooltip>
      ),
    },
    {
      id: FIELD_KEY.PROJECT_ID,
      dataIndex: FIELD_KEY.PROJECT_ID,
      width: 180,
      title: t('project.daily.listReport.project'),
      render: (_, record) => (
        // <Tooltip title={record?.project?.name} overlayClassName="custom-tooltip" color="white" className={styles.minVw}>
        <span className={`${styles.textOver1Lines}`}>{`${record?.project?.name?.substring(0, MAX_LENGTH_30)} ${
          record?.project?.name?.length >= MAX_LENGTH_30 ? '...' : ''
        }`}</span>
        // </Tooltip>
      ),
    },
    {
      id: FIELD_KEY.PROCESS_TYPE,
      dataIndex: FIELD_KEY.PROCESS_TYPE,
      width: 165,
      align: 'center',
      title: t('project.daily.listReport.processType'),
      render: (value, record) => t(ALL_PROCESS_TYPE.find((item) => item.key === value)?.text),
    },
    {
      id: FIELD_KEY.ACTUAL_TIME,
      dataIndex: FIELD_KEY.ACTUAL_TIME,
      width: 135,
      align: 'center',
      title: t('project.daily.listReport.actualTime'),
      render: (value, record) => `${value}h`,
    },
    {
      id: FIELD_KEY.WORK_DATE,
      dataIndex: FIELD_KEY.WORK_DATE,
      width: 130,
      align: 'center',
      title: t('project.daily.listReport.workDate'),
      render: (_, record) => formatDatePiker(record.work_date, 'DD/MM/YYYY'),
    },
    {
      id: FIELD_KEY.LINK_BACKLOG,
      dataIndex: FIELD_KEY.LINK_BACKLOG,
      width: 215,
      align: 'center',
      title: t('project.daily.listReport.backlog'),
      render: (_, record) => (
        <a
          href={record.link_backlog}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            isCanClickOnRow = false;
          }}
        >
          {record.link_backlog && `${record.link_backlog?.substring(0, 25)}...`}
        </a>
      ),
    },
    {
      id: FIELD_KEY.DESCRIPTION,
      dataIndex: FIELD_KEY.DESCRIPTION,
      title: <div className="d-flex justify-content-center">{t('project.daily.listReport.description')}</div>,
      render: (_, record) => (
        <Tooltip title={record?.description} overlayClassName="custom-tooltip" color="white">
          <span className={`${styles.textOver1Lines}`}>
            {record?.description &&
              `${record?.description?.substring(0, MAX_LENGTH_50)} ${
                record?.description?.length >= MAX_LENGTH_50 ? '...' : ''
              }`}
          </span>
        </Tooltip>
      ),
    },
    {
      id: FIELD_KEY.STATUS,
      dataIndex: FIELD_KEY.STATUS,
      width: 150,
      title: t('project.daily.listReport.status'),
      align: 'center',
      render: (text) => renderStatusTag(text),
    },
    {
      id: 'action',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <Space direction="horizontal">
            {isShowButtonEditDelete(record) && (
              <Button
                disabled={record.status === REPORT_APPROVED}
                icon={<MdEdit size={25} />}
                onClick={() => {
                  isCanClickOnRow = false;
                  setSelectedReport(record);
                  setIsOpenModalEdit(!isOpenModalEdit);
                }}
              />
            )}
            {isShowButtonEditDelete(record) && (
              <MdDelete
                className={record.status === REPORT_APPROVED ? styles.disableDelete : styles.btnDelete}
                size={25}
                onClick={() => {
                  isCanClickOnRow = false;
                  record.status !== REPORT_APPROVED &&
                    showConfirm({
                      content: (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t('project.daily.listReport.deleteConfirm', { taskName: record?.title }),
                          }}
                        />
                      ),
                      width: 420,
                      onOk() {
                        if (deleteDailyReport?.isLoading) return;
                        deleteDailyReport.mutate(record.id);
                      },
                    });
                }}
              />
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card
        title={
          <span className={styles.infoContainer}>
            <img src={infoIcon} alt="" className={styles.infoIcon} />
            <span className={styles.infoTitle}>{t('project.daily.listReport.info')}</span>
          </span>
        }
        extra={
          <Button
            icon={<img src={addIcon} alt="" />}
            type="primary"
            className={styles.btnCreate}
            onClick={() => setIsOpenModalCreate(!isOpenModalCreate)}
          >
            {t('project.daily.listReport.create')}
          </Button>
        }
        className={styles.cardReport}
      >
        <>
          <Divider />
          {deleteDailyReport.isLoading || isFetching ? (
            <div className={styles.loading}>
              <Spin />
            </div>
          ) : (
            listDailyReport?.data?.length > 0 && (
              <CustomTable
                key={'daily-report'}
                rowKey={'id'}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      isCanClickOnRow && setIsOpenModalDetail(!isOpenModalDetail);
                      isCanClickOnRow = true;
                      setSelectedReport(record);
                    },
                  };
                }}
                className={styles.tableReport}
                columns={columns}
                dataSource={listDailyReport?.data}
                scroll={{ x: 'max-content' }}
                paginationProps={{
                  totalPage: listDailyReport?.total,
                  currentPage: filter?.page,
                  limit: filter?.limit,
                  from: listDailyReport?.from,
                  to: listDailyReport?.to,
                  onChangePage: (page, limit) => setFilter({ ...filter, page, limit }),
                }}
              />
            )
          )}
          {listDailyReport?.data?.length === 0 && (
            <div className={styles.noData}>
              <span>{t('common.noData')}</span>
            </div>
          )}
        </>
      </Card>
      <CreateDailyReport
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        refetchDaiLyReports={refetchDaiLyReports}
      />
      <UpdateDailyReport
        isOpen={isOpenModalEdit}
        setIsOpen={setIsOpenModalEdit}
        refetchDaiLyReports={refetchDaiLyReports}
        selectedReport={selectedReport}
      />
      <DetailDailyReport
        isOpen={isOpenModalDetail}
        setIsOpen={setIsOpenModalDetail}
        setIsOpenEdit={setIsOpenModalEdit}
        selectedReport={selectedReport}
        deleteReport={deleteDailyReport}
      />
    </>
  );
};

export default TableDailyReport;
