/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { createContext, useEffect } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';

// reactstrap components
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminFooter from 'components/Footers/index.js';
import Header from '../components/Headers/Header.js';
import routes from 'routes.js';
import { useInforAuth } from 'hook/useAuth';
import storageFC from 'helpers/storage';
import configs from 'config';
import ReactGA from 'react-ga4';

export const ThemContext = createContext();

const Member = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const { data: dataInforUser } = useInforAuth();
  window.OneSignal = window.OneSignal || [];

  React.useEffect(() => {
    ReactGA.initialize("G-G4DRD5GKLR");
    ReactGA.send("/");
  }, []);
  
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  };

  useEffect(() => {
    if (!storageFC.getItem('token')) {
      storageFC.setToken(window.location.href, 'previous_url');
      window.location.href = configs.API_DOMAIN_LOGIN;
    }
    // Handle Filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemContext.Provider value={dataInforUser?.user_id}>
        <Header routes={routes} />
        <div className="main-content" ref={mainContent}>
          <Container className="" fluid>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/index" />
            </Switch>
          </Container>
          <Container fluid className="mb-6">
            <AdminFooter />
          </Container>
        </div>
      </ThemContext.Provider>
    </>
  );
};

export default Member;
