import React, { useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import { MdDateRange } from 'react-icons/md';
import { MdAddAlarm } from 'react-icons/md';
import { MdPayments } from 'react-icons/md';
import { RiTimerLine } from 'react-icons/ri';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import IconClock from 'assets/img/icons/dashboard/clock.svg';
import fullIcon from 'assets/img/icons/dashboard/full-icon.svg';
import lateIcon from 'assets/img/icons/dashboard/late-icon.svg';
import offIcon from 'assets/img/icons/dashboard/off-icon.svg';
import iconMenuTotal from 'assets/img/icons/menu-total.png';

import { ThemContext } from 'layouts/Member';
import styles from './style.module.scss';
import DateMonthComponent from 'components/DateTime/DateMonth.js';
import { formatNumberCurrency } from 'helpers/format.js';
import { useGetInfoTimekeepingDay } from 'hook/useDashboard.js';
import { useTotalTimeSheet } from 'hook/useTimeSheet';

const timeType = {
  full_time: 1,
  late_time: 2,
  lost_time: 3,
  off_time: 4,
};

export const TimekeepingToDay = () => {
  const { t } = useTranslation();

  const { data: dataInfoTimekeepingDay } = useGetInfoTimekeepingDay(useContext(ThemContext));

  const getCheckoutStatus = useCallback(function (checkout, late_minute, label) {
    if (checkout) {
      if (late_minute === 0) {
        return (
          <div className="d-flex">
            <img className="mr-2" src={fullIcon} alt="" />
            <div className={classNames(styles.in, styles.full)}>
              {label}: {checkout ? checkout : 'N/A'}
            </div>
          </div>
        );
      }

      return (
        <div className="d-flex">
          <img className="mr-2" src={lateIcon} alt="" />
          <div className={classNames(styles.in, styles.late)}>
            {label}: {checkout ? checkout : 'N/A'}
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex">
        <img className="mr-2" src={offIcon} alt="" />
        <div className={classNames(styles.in, styles.off)}>
          {label}: {checkout ? checkout : 'N/A'}
        </div>
      </div>
    );
  }, []);

  return (
    <Box className={classNames(`border-show pd-15 ${styles.boxTimeKeeping}`)}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <div className="body-top">
            <div className="d-flex">
              <img src={IconClock} alt={IconClock} className="icon" />
              <h4 className="title-body">{t('boxInfoEmployee.dashboard.attendanceToday')}</h4>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box className="">
            {dataInfoTimekeepingDay ? (
              <div
                className={classNames(styles.boxTimekeepingDetail, {
                  [styles.fulltime]: dataInfoTimekeepingDay?.status === timeType.full_time,
                  [styles.lateTime]: dataInfoTimekeepingDay?.status === timeType.late_time,
                  [styles.loseTime]: dataInfoTimekeepingDay?.status === timeType.lost_time,
                  [styles.offTime]: dataInfoTimekeepingDay?.status === timeType.off_time,
                })}
              >
                <div className="timekeeping-info d-flex justify-content-between">
                  <p className="date">{dataInfoTimekeepingDay?.date}</p>
                  <div className="d-flex">
                    <p>
                      {t('timekeeping.dashboard.workingTime')}:{' '}
                      {dataInfoTimekeepingDay?.work_time ? dataInfoTimekeepingDay?.work_time : 0}
                    </p>
                    <p>&nbsp; - &nbsp; </p>
                    <p>{`${t('timekeeping.dashboard.late')}: ${
                      dataInfoTimekeepingDay?.late_time ? dataInfoTimekeepingDay?.late_time : 0
                    }'`}</p>
                  </div>
                </div>
                <div className="timekeeping-date">
                  <div>
                    {getCheckoutStatus(
                      dataInfoTimekeepingDay?.checkin,
                      dataInfoTimekeepingDay?.late_time,
                      t('timekeeping.dashboard.in'),
                    )}
                  </div>
                  <div>
                    {getCheckoutStatus(
                      dataInfoTimekeepingDay?.checkout,
                      dataInfoTimekeepingDay?.early_time,
                      t('timekeeping.dashboard.out'),
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p className="error">{t('divisionDiagram.divisionDetail.noData')}</p>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ItemTimekeepingStatistical = ({ bgClass, icon, text, number , type}) => {
  return (
    <div className={classNames(`${styles.itemTimeKeepingStatistical} ${bgClass}`)}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {icon}
          <p className={styles.text}>{text}</p>
        </div>
        <div className="text-bold">{number}</div>
      </div>
    </div>
  );
};

export const TimeKeepingStatistical = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({ month: moment() });

  const { data: dataInfoTimekeeping } = useTotalTimeSheet({
    userId: useContext(ThemContext),
    date: filter.month.format('MM/YYYY'),
  });

  const handleDateMonth = (date) => {
    setFilter({ ...filter, month: moment(date) });
  };

  return (
    <Box className={classNames(`border-show pd-15 h-100 ${styles.boxTimeKeepingStatistical}`)}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <div className="body-top mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img src={IconClock} alt={IconClock} className="icon" />
                <h4 className="title-body">{t('boxInfoEmployee.dashboard.timekeeping')}</h4>
              </div>
              <div className={styles.filterDate}>
                <DateMonthComponent label="" valueDefault={filter.month} onChangeHandle={handleDateMonth} />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box className="">
            <ItemTimekeepingStatistical
              bgClass={styles.totalWorkBlock}
              icon={<img src={iconMenuTotal} className={styles.totalWork} alt="Total" />}
              text={t('timekeeping.totalWorkingTime')}
              number={(dataInfoTimekeeping?.total || 0) + '/' + (dataInfoTimekeeping?.hour_default || 0)}
            />

            <ItemTimekeepingStatistical
              bgClass={styles.OT}
              icon={<MdAddAlarm />}
              text={t('boxInfoEmployee.dashboard.hourOT')}
              number={dataInfoTimekeeping?.over_time || 0}
            />

            <ItemTimekeepingStatistical
              bgClass={styles.minuteLate}
              icon={<RiTimerLine />}
              text={t('boxInfoEmployee.dashboard.numberLateTime')}
              number={dataInfoTimekeeping?.late_time || 0}
            />

            <ItemTimekeepingStatistical
              bgClass={styles.leaveDay}
              icon={<RiTimerLine />}
              text={t('timekeeping.numberLateEarlyTimePropost')}
              number={((dataInfoTimekeeping?.total_time_late_early) || 0) + `/${dataInfoTimekeeping?.default_time_late_early || 0}`}
            />
            
            <ItemTimekeepingStatistical
              bgClass={styles.leaveDay}
              icon={<MdPayments />}
              text={t('boxInfoEmployee.dashboard.fine')}
              number={formatNumberCurrency(dataInfoTimekeeping?.fines) || 0}
              type="fine"
            />
            
            <ItemTimekeepingStatistical
              bgClass={styles.leaveDay}
              icon={<MdDateRange />}
              text={t('timekeeping.totalOffDayHasSalaryUnit')}
              number={dataInfoTimekeeping?.paid_leave || 0}
            />

            <ItemTimekeepingStatistical
              bgClass={styles.leaveDay}
              icon={<MdDateRange />}
              text={t('timekeeping.totalOffDayNoSalaryUnit')}
              number={dataInfoTimekeeping?.unpaid_leave || 0}
            />
          </Box>
        </Grid>
        <div className={styles.detail}>
          <NavLink
            to={{
              pathname: '/timekeeping/time-sheets',
            }}
          >
            {t('boxInfoEmployee.showDetail')}
          </NavLink>
        </div>
      </Grid>
    </Box>
  );
};
