import { Tag } from 'antd';
import { COMMON_STATUS_TAG, REPORT_APPROVED, NUMBER_LOG_WORK_DAYS_IN_PAST } from 'constants/Projects';
import { t } from 'i18next';
import moment from 'moment';
import _ from 'lodash';

export const renderStatusTag = (status) => {
  const tag = _.find(COMMON_STATUS_TAG, (item) => item.key === status);
  return (
    <Tag color={tag?.color} className="rounded-pill">
      {t(tag?.text)}
    </Tag>
  );
};

export const renderStatusOptions = (tags) =>
  _.map(tags, (tag) => {
    return {
      value: tag.key,
      label: (
        <Tag color={tag.color} className="rounded-pill">
          {t(tag.text)}
        </Tag>
      ),
    };
  });

export const isShowButtonEditDelete = (dailyReportRecord) => {
  const workDate = moment(dailyReportRecord?.work_date);

  return dailyReportRecord.status !== REPORT_APPROVED && workDate.isSameOrAfter(moment().subtract(NUMBER_LOG_WORK_DAYS_IN_PAST, 'days').startOf('day'));
};
