import React from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Col, Row } from 'reactstrap';

import iconCareer from '../../../../assets/img/icons/career.png';
import iconDot from '../../../../assets/img/icons/dot.png';
import iconNext from '../../../../assets/img/icons/next.png';
import iconPrev from '../../../../assets/img/icons/prev.png';

import styles from './../style.module.scss';
import classNames from 'classnames';
import { customDate } from 'helpers/formatDate';
import moment from 'moment';

SwiperCore.use([Navigation]);

const Contract = (props) => {
  const { uProfileUser } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.profileBoxContract}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className={classNames(`${styles.profileTitle} w-100`)}>
              <img alt={iconCareer} className={styles.imgTitle} src={iconCareer} />
              {t('infoEmployee.titleCareer')}
            </div>
          </Col>
          <Col xs="2"></Col>
        </Row>
        <div>
          {uProfileUser && (uProfileUser?.user_group?.length === 0) | (uProfileUser?.user_group === null) ? (
            <div className={styles.profileContentInfoAlert}>{t('boxInfoEmployee.alertNotInfo')}</div>
          ) : (
            <Swiper
              modules={[Navigation]}
              spaceBetween={30}
              slidesPerView={3.005}
              breakpoints={{
                1400: {
                  slidesPerView: 3,
                },
                1366: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
              navigation={{
                prevEl: '.prevCa',
                nextEl: '.nextCa',
              }}
              className={styles.salaryBoxParent}
            >
              {uProfileUser &&
                uProfileUser?.user_group?.map((itemContract, index) => (
                  <SwiperSlide key={index} className={`${itemContract?.active ? styles.salaryBoxNow : styles.salaryBox} ${index === uProfileUser?.user_group?.length - 1 ? styles.salaryBoxActive : "" }`}>
                    <div>
                      <div className={`${styles.point} ${index === uProfileUser?.user_group?.length - 1 ? styles.pointActive : "" }`}></div>
                      <div className={styles.contentBox}>
                      <div className={styles.profileContentInfo}>
                          <img alt={iconDot} src={iconDot} />
                          {itemContract.date ? (
                            customDate(itemContract.date, 'DD/MM/YYYY')
                          ) : (
                            <span>{t('infoEmployee.notUpdate')}</span>
                          )}
                        </div>

                        <div className={styles.profileContentInfo}>
                          <img alt={iconDot} src={iconDot} />
                          {uProfileUser?.user_group?.[index + 1] ? (
                            moment(uProfileUser?.user_group?.[index + 1]?.date).subtract(1 , "days").format("DD/MM/YYYY")
                          ) : (
                            <span>{t('infoEmployee.notUpdate')}</span>
                          )}
                        </div>

                      </div>
                      <div className={styles.profileContentInfo}>
                        {/* <p className="text-bold  mb-2">
                          {t('common.position')}: {itemContract?.position_name}
                        </p> */}
                        <p className="text-bold mb-3">{t('common.employee') + ' ' + itemContract?.group_name}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

              {uProfileUser && uProfileUser?.contract?.length <= 3 ? (
                ''
              ) : (
                <div className={`${styles.prevButton} prevCa`}>
                  <img alt={iconPrev} src={iconPrev} />
                </div>
              )}

              {uProfileUser && uProfileUser?.contract?.length <= 3 ? (
                ''
              ) : (
                <div className={`${styles.nextButton} nextCa`}>
                  <img alt={iconNext} src={iconNext} />
                </div>
              )}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};

export default Contract;
