import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Grid } from '@mui/material';
import Nav from '..';
import { Button, Card, Space, Tag } from 'antd';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { getStartOfMonth, getEndOfMonth, getDayOfWeek, customDate } from 'helpers/formatDate';

import { AiOutlinePlus } from 'react-icons/ai';
import infoIcon from 'assets/img/icons/info-icon.svg';
import preIcon from 'assets/img/icons/pre-icon.svg';
import nextIcon from 'assets/img/icons/next-icon.svg';
import approvedIcon from 'assets/img/icons/approved-icon.svg';
import overTime from 'assets/img/icons/over-time.svg';
import pendingIcon from 'assets/img/icons/pending-icon.svg';
import rejectedIcon from 'assets/img/icons/reject-icon.svg';
import { CreateDailyReport } from '../DailyReport/create-update';
import { useQuery } from 'react-query';
import { USE_GET_CALENDAR_REPORT } from 'constants/Projects';
import { apiGetCalendarReport } from 'api/projects';
import Loading from 'components/Loading';
import { FULL_TIME_REPORT } from 'constants/Projects';
import ModalDetailDailyReports from './ModalDetailDailyReports';
import { formatNumberTwoChar } from 'helpers/format';
import { useGetHolidays } from 'hook/useTimeSheet';

const weekDay = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0,
};

const CalendarReport = () => {
  const { t } = useTranslation();
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [selectMonth, setSelectMonth] = useState(getStartOfMonth());
  const [listDate, setListDate] = useState();
  const [reports, setReports] = useState([]);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [selectReport, setSelectReport] = useState([]);
  const [selectDate, setSelectDate] = useState();
  const { data: holidays } = useGetHolidays({});

  const week = [
    {
      id: 1,
      value: t('common.monday'),
    },
    {
      id: 2,
      value: t('common.tuesday'),
    },
    {
      id: 3,
      value: t('common.wednesday'),
    },
    {
      id: 4,
      value: t('common.thursday'),
    },
    {
      id: 5,
      value: t('common.friday'),
    },
    {
      id: 6,
      value: t('common.saturday'),
    },
    {
      id: 7,
      value: t('common.sunday'),
    },
  ];

  const checkMonthCurrent = (date) => {
    return moment(date, 'YYYY-MM-DD').format('MM') === customDate(selectMonth, 'MM');
  };

  const isDayHasReport = (date) => {
    return (
      moment(date, 'YYYY-MM-DD') < moment() &&
      moment(date, 'YYYY-MM-DD') <= moment(getEndOfMonth(selectMonth)) &&
      moment(date, 'YYYY-MM-DD') >= moment(getStartOfMonth(selectMonth)) && !holidays.includes(date)
    );
  };

  const isDayHasAction = (date) => {
    return (
      // ![weekDay.saturday, weekDay.sunday].includes(moment(date, 'YYYY-MM-DD').day()) &&
      moment(date, 'YYYY-MM-DD') < moment() &&
      moment(date, 'YYYY-MM-DD') <= moment(getEndOfMonth(selectMonth)) &&
      moment(date, 'YYYY-MM-DD') >= moment(getStartOfMonth(selectMonth))
    );
  };

  useEffect(() => {
    const startOfMonth = getStartOfMonth(selectMonth);
    const endOfMonth = getEndOfMonth(selectMonth);

    const dayStart = getDayOfWeek(startOfMonth);
    const dayEnd = getDayOfWeek(endOfMonth);

    const startDate = moment(startOfMonth)
      .subtract(dayStart === weekDay.sunday ? 6 : dayStart - 1, 'day')
      .format('YYYY-MM-DD');
    const endDate = moment(endOfMonth)
      .add(7 - dayEnd, 'day')
      .format('YYYY-MM-DD');

    const dates = [];

    for (let date = startDate; date <= endDate; date = moment(date).add(1, 'day').format('YYYY-MM-DD')) {
      dates.push(date);
    }

    setListDate(dates);
  }, [selectMonth]);

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchCalendarReports,
  } = useQuery(
    [USE_GET_CALENDAR_REPORT, selectMonth],
    () => apiGetCalendarReport({ month_in_year: customDate(selectMonth, 'YYYY-MM') }),
    {
      select: (res) => res.data.data,
    },
  );

  useEffect(() => {
    const cloneCalendarReports = [];
    data &&
      data.forEach((item) => {
        let workDate = item.work_date;
        if (cloneCalendarReports[workDate]) {
          cloneCalendarReports[workDate] = [...cloneCalendarReports[(workDate, item)]];
        } else {
          cloneCalendarReports[workDate] = item;
        }
      });
    setReports(cloneCalendarReports);
  }, [data]);

  return (
    <section className="list-request-assets">
      <Grid container spacing={2}>
        <Grid container className="nav-fixed pl-15">
          <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
            <Grid container>
              <Nav />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Card
        title={
          <span className={styles.infoContainer}>
            <img src={infoIcon} alt="" className={styles.infoIcon} />
            <span className={styles.infoTitle}>{t('project.daily.listReport.info')}</span>
          </span>
        }
        extra={
          <Space className={styles.extraCalendar}>
            <div className="d-flex justify-content-center align-items-center">
              <div className={styles.fullWorkHour}></div>
              {t('project.calendarReport.enough')}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className={styles.notFullWorkHour}></div>
              {t('project.calendarReport.notEnough')}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className={styles.notReport}></div>
              {t('project.calendarReport.notReport')}
            </div>
            <Button
              type="primary"
              icon={<AiOutlinePlus />}
              className={styles.btnCreate}
              onClick={() => setIsOpenModalCreate(!isOpenModalCreate)}
            >
              {t('project.daily.listReport.create')}
            </Button>
          </Space>
        }
        className={styles.cardReport}
      >
        <Grid container spacing={2} className={styles.minWidth1200}>
          <Grid item xs={12} className={styles.filterMonth}>
            <Button
              className={styles.preBtn}
              onClick={() => setSelectMonth(moment(selectMonth).subtract(1, 'month').format('YYYY/MM/DD'))}
            >
              <img src={preIcon} alt="" />
            </Button>
            <div className={styles.month}>
              {t('timekeeping.month')} {customDate(selectMonth, 'MM / YYYY')}
            </div>
            <Button
              className={styles.nextBtn}
              onClick={() => setSelectMonth(moment(selectMonth).add(1, 'month').format('YYYY/MM/DD'))}
            >
              <img src={nextIcon} alt="" />
            </Button>
          </Grid>

          <Grid item xs={12}>
            {(isLoading || isFetching) && (
              <div className="center">
                <Loading addClass="mt-3 mb-3" />
              </div>
            )}
            {!isLoading && !isFetching && listDate && (
              <div className={styles.calendar}>
                <div className={styles.calendarHeader}>
                  <div className={styles.bottom}>
                    {week.map((weekDay) => (
                      <div key={weekDay.id} className={styles.weekDay}>
                        {weekDay.value}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.calendarBody}>
                  {listDate?.map((date) => (
                    <div
                      className={classNames(styles.date, {
                        [styles.enoughTime]: reports?.[date]?.total_actual_time_success >= FULL_TIME_REPORT,
                        [styles.notEnoughTime]:
                          reports?.[date]?.total_actual_time_success < FULL_TIME_REPORT &&
                          moment(date, 'YYYY-MM-DD') < moment(),
                        [styles.notReport]:
                          !reports?.[date] &&
                          moment(date, 'YYYY-MM-DD') < moment(),
                        [styles.pastDay]: !checkMonthCurrent(date),
                        [styles.actionDate]: isDayHasAction(date),
                        [styles.holiday]: holidays.includes(date)
                      })}
                      key={date}
                      id={date}
                      onClick={() => {
                        if (isDayHasAction(date)) {
                          setSelectReport(reports?.[date]);
                          setIsOpenDetail(!isOpenDetail);
                          setSelectDate(date);
                        }
                      }}
                    >
                      {isDayHasReport(date) ? (
                        <>
                          <div
                            className={classNames(styles.borderLeft, {
                              [styles.borderLeftSuccess]:
                                reports?.[date]?.total_actual_time_success >= FULL_TIME_REPORT,
                              [styles.borderLeftWarning]: reports?.[date]?.total_actual_time_success < FULL_TIME_REPORT,
                            })}
                          ></div>
                          <div className={classNames(styles.content)}>
                            <div className={styles.timeAndTask}>
                              <div className={styles.dateTime}>{customDate(date, 'DD/MM')}</div>
                              <div className={styles.task}>
                                {t('project.calendarReport.task', {
                                  number: formatNumberTwoChar(reports?.[date]?.task || 0),
                                })}
                              </div>
                              {reports?.[date]?.overtime > 0 && (
                                <Tag
                                  icon={<img src={overTime} alt="" />}
                                  className={styles.overTimeTag}
                                >{`${reports?.[date]?.overtime}h`}</Tag>
                              )}
                            </div>
                            <div className={styles.statusContent}>
                              <div className={styles.approved}>
                                <img src={approvedIcon} alt="approved icon" />
                                <span className={styles.status}>{t('project.calendarReport.approved')}</span>
                                <span className={styles.timeReport}>
                                  {t('project.calendarReport.timeReport', {
                                    number: reports?.[date]?.total_actual_time_success || 0,
                                  })}
                                </span>
                              </div>
                              <div className={styles.pending}>
                                <img src={pendingIcon} alt="pending icon" />
                                <span className={styles.status}>{t('project.calendarReport.pending')}</span>
                                <span className={styles.timeReport}>
                                  {t('project.calendarReport.timeReport', {
                                    number: reports?.[date]?.total_actual_time_pending || 0,
                                  })}
                                </span>
                              </div>
                              <div className={styles.rejected}>
                                <img src={rejectedIcon} alt="rejected icon" />
                                <span className={styles.status}> {t('project.calendarReport.rejected')}</span>
                                <span className={styles.timeReport}>
                                  {t('project.calendarReport.timeReport', {
                                    number: reports?.[date]?.total_actual_time_reject || 0,
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className={styles.normalDate}>
                          <div className={styles.dateTime}>{customDate(date, 'DD/MM')}</div>
                          {isDayHasAction(date) && reports?.[date]?.overtime > 0 && (
                            <Tag
                              icon={<img src={overTime} alt="" />}
                              className={styles.overTimeTag}
                            >{`${reports?.[date]?.overtime}h`}</Tag>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>

      <CreateDailyReport
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        refetchDaiLyReports={refetchCalendarReports}
      />

      <ModalDetailDailyReports
        isOpen={isOpenDetail}
        setIsOpen={setIsOpenDetail}
        selectReport={selectReport}
        selectDate={selectDate}
      />
    </section>
  );
};

export default CalendarReport;
