export const USE_GET_LIST_PROJECTS = 'use_get_list_projects';
export const USE_GET_LIST_STATUS_PROJECTS = 'use_get_list_status_projects';
export const USE_GET_EFFORT_NOW = 'use_get_effort_now';
export const USE_GET_ALL_DAILY_REPORT = 'use_get_all_daily_report';
export const USE_GET_USER_PROJECT = 'use_get_user_project';
export const USE_GET_CALENDAR_REPORT = 'use_get_CALENDAR_REPORT';
export const USE_GET_CALENDAR_REPORT_DETAIL = 'use_get_CALENDAR_REPORT_DETAIL';

export const STATUS_OPEN = 1;
export const STATUS_CLOSE = 2;
export const STATUS_IN_PROGRESS = 3;
export const STATUS_CANCELLED = 4;
export const STATUS_POSTPONED = 5;
export const STATUS_OPPORTUNITY = 6;
export const STATUS_DAFT = 7;
export const STATUS_PENDING = 8;
export const STATUS_GUARANTEE = 9;

export const DEVELOPER = 1;
export const MAINTENANCE = 2;

export const FIXED_PRICE = 1;
export const LABO = 2;

export const PM = 1;
export const DEVELOP = 2;
export const TESTER = 3;
export const BA = 4;
export const TECH_LEAD = 5;
export const TEST_LEAD = 6;
export const COMTOR = 7;
export const BRSE = 8;
export const PQA = 9;
export const DESIGNER = 10;

export const FIELD_KEY = {
  KEYWORD: 'key_word',
  INDEX: 'index',
  TASK_ID: 'title',
  PROJECT_ID: 'project_id',
  ACTUAL_TIME: 'actual_time',
  HOUR: 'hour',
  MINUTE: 'minute',
  WORK_DATE: 'work_date',
  LINK_BACKLOG: 'link_backlog',
  PROCESS_TYPE: 'process_type',
  DESCRIPTION: 'description',
  STATUS: 'status',
  FROM_DATE: 'from_date',
  TO_DATE: 'to_date',
  START_TIME: 'start_time',
  END_TIME: 'end_time',
  OT: 'ot',
  OT_WITH_COEFFICIENT: 'ot_with_coefficient',
};

export const REPORT_PENDING = 1;
export const REPORT_APPROVED = 2;
export const REPORT_REJECTED = 3;

export const COMMON_STATUS_TAG = [
  {
    key: REPORT_PENDING,
    text: 'project.daily.statusTag.pending',
    color: '#FEA628',
  },
  {
    key: REPORT_APPROVED,
    text: 'project.daily.statusTag.approved',
    color: '#66D997',
  },
  {
    key: REPORT_REJECTED,
    text: 'project.daily.statusTag.rejected',
    color: '#E74C3C',
  },
];

export const NUMBER_LOG_WORK_DAYS_IN_PAST = 7;

export const FRIDAY_CODE = 5;
export const SATURDAY_CODE = 6;
export const SUNDAY_CODE = 0;
export const WEEKEND_CODE = [SATURDAY_CODE, SUNDAY_CODE];
export const FULL_TIME_REPORT = 8;
export const MINUTE_STEP = 15;

export const REQUIREMENT = 1;
export const DESIGN = 2;
export const CODING = 3;
export const TESTING = 4;
export const UAT = 5;
export const WARRANTY = 6;
export const MEETING = 7;
export const PROJECT_PLAN = 8;
export const MONITORING_CONTROL = 9;
export const MANAGEMENT = 10;
export const OTHER = 11;
export const TRAINER = 12;
export const TRAINEE = 13;
export const ORGANIZER = 14;
export const PARTICIPANT = 15;

export const ALL_PROCESS_TYPE = [
  {
    key: REQUIREMENT,
    text: 'project.daily.processType.requirement',
  },
  {
    key: DESIGN,
    text: 'project.daily.processType.design',
  },
  {
    key: CODING,
    text: 'project.daily.processType.coding',
  },
  {
    key: TESTING,
    text: 'project.daily.processType.testing',
  },
  {
    key: UAT,
    text: 'project.daily.processType.uat',
  },
  {
    key: WARRANTY,
    text: 'project.daily.processType.warranty',
  },
  {
    key: MEETING,
    text: 'project.daily.processType.meeting',
  },
  {
    key: PROJECT_PLAN,
    text: 'project.daily.processType.projectPlan',
  },
  {
    key: MONITORING_CONTROL,
    text: 'project.daily.processType.monitoringControl',
  },
  // {
  //   key: MANAGEMENT,
  //   text: 'project.daily.processType.management',
  // },
  {
    key: OTHER,
    text: 'project.daily.processType.other',
  },
  {
    key: TRAINER,
    text: 'project.daily.processType.trainer',
  },
  {
    key: TRAINEE,
    text: 'project.daily.processType.trainee',
  },
  {
    key: ORGANIZER,
    text: 'project.daily.processType.organizer',
  },
  {
    key: PARTICIPANT,
    text: 'project.daily.processType.participant',
  },
];
