import { useQuery } from 'react-query';
import { HTTP_OK } from './../constants/StatusCode.js';

import {
  apiGetProfileEmployee,
  apiGetProjectAllocations,
  apiGetProjectAllocationsByMonth,
  apiGetProjectStats,
  apiGetInfoAssets,
  apiGetInfoTimekeeping,
  apiGetInfoTimekeepingDay,
  apiGetRequests,
} from 'api/dashboard.js';

import * as Constants from 'constants/Dashboard.js';
import { apiGetUnReportDays } from 'api/dashboard.js';

export function useGetRequests(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    params.constant_api,
    async () => {
      const response = await apiGetRequests({ ...params, constant_api: null });
      if (response.status === HTTP_OK) {
        return response;
      }
      return null;
    },
    { enabled: params.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetProfileEmployee(userId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_DASHBOARD_INFO_EMPLOYEE, params],
    async () => {
      const response = await apiGetProfileEmployee(userId, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetProjectAllocations(userId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_DASHBOARD_PROJECT_ALLOCATIONS, params],
    async () => {
      const response = await apiGetProjectAllocations(userId, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetProjectAllocationsByMonth(userId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_DASHBOARD_PROJECT_ALLOCATIONS_BY_MONTH, params],
    async () => {
      const response = await apiGetProjectAllocationsByMonth(userId, params);
      if (response.status === HTTP_OK) {
        return response;
      }
      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetProjectStats(userId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_DASHBOARD_PROJECT_STATS, params],
    async () => {
      const response = await apiGetProjectStats(userId, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetInfoAssets(userId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_DASHBOARD_INFO_ASSETS, params],
    async () => {
      const response = await apiGetInfoAssets(userId, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetInfoTimekeeping(userId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_DASHBOARD_INFO_TIMEKEEPING, params],
    async () => {
      const response = await apiGetInfoTimekeeping(userId, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetInfoTimekeepingDay(userId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_DASHBOARD_INFO_TIMEKEEPING_DAY, params],
    async () => {
      const response = await apiGetInfoTimekeepingDay(userId, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetUnReportDays(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DASHBOARD_GET_UNREPORTED_DAYS, params], async () => {
    const response = await apiGetUnReportDays(params);
    if (response.status === HTTP_OK) {
      return response?.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}
