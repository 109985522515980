export const USE_MONEY_LATE = 'master_data_money_late';
export const USE_CATEGORY_ASSET = 'master_data_category_asset';
export const USE_CATEGORY_CERTIFICATE = 'master_data_category_certificate';
export const USE_WAREHOUSE_LIST = 'master_data_warehouse_list';
export const USE_CATEGORY_CONTRACT = 'list_category_contract';
export const USE_CATEGORY_POSITION = 'list_category_position';
export const USE_LIST_CATEGORY_EMPLOYEES = 'list_category_employees';
export const USE_LIST_SKILL = 'master_data_list_skill';
export const USE_LIST_SCHEDULE = 'master_data_list_schedule';
export const USE_LIST_BLOCK_TIME = 'master_data_show_block_time';
export const USE_CERTIFICATE = 'master_data_list_certificate';
export const USE_LIST_LEVEL = 'master_data_list_level';
export const USE_LIST_HOLIDAY = 'master_data_list_holiday';
export const USE_LIST_OVER_TIME = 'master_data_list_over_time';
export const USE_LIST_SPECIAL_TIME_KEEPING = 'master_data_list_time_keeping';
export const USE_LIST_USER_POSITION = 'master_data_list_user_position';
export const USE_LIST_GROUP_ACCOUNT = 'master_data_list_group_account';
export const USE_LIST_ALL_DIVISION = 'master_data_list_all_division';
export const USE_LIST_ALL_DIVISION_POSITION = 'master_data_list_all_division_position';
export const USE_LIST_ALL_MEMBER_COMMUNITY_CUSTOM = 'master_data_list_all_community_member_custom';

export const USE_LIST_MEMBER_CREATE_GROUP_ACCOUNT_FILTER = 'master_data_list_member_create_group_account_filter';
export const USE_LIST_DIVISION_CREATE_GROUP_ACCOUNT_FILTER = 'master_data_list_division_create_group_account_filter';
export const USE_LIST_POSITION_CREATE_GROUP_ACCOUNT_FILTER = 'master_data_list_position_create_group_account_filter';
export const USE_CREATE_GROUP_ACCOUNT_FILTER = 'master_data_create_group_account_filter';
export const USE_DETAIL_GROUP_ACCOUNT_FILTER = 'master_data_detail_group_account_filter';
export const IS_DISABLE_CHECKBOX_INFINITY = 0;
export const IS_NOT_DISABLE_CHECKBOX_INFINITY = 1;
export const IS_CHECK_RADIO_INDEPENDENCE = 0;
export const IS_CHECK_RADIO_DEPENDENCE = 1;
export const CATEGORY_CERTIFICATE = 1;
export const MANAGER_CERTIFICATE = 2;

export const IS_INPUT_SEARCH = true;

export const TIME_DATA_OTHER = {
  hour_start_morning: '08:00',
  hour_end_morning: '12:00',
  hour_start_afternoon: '13:30',
  hour_end_afternoon: '17:30',
};

export const USE_TYPE_GROUP = {
  filter: 1,
  custom: 2,
};

export const SYSTEM_ACMS_HR = 1;
