import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

import styles from './styles.module.scss';
import { TYPE_EXPORT_PDF } from './../../constants';
import { HTTP_NOT_FOUND } from 'constants/StatusCode';
import storageFC from 'helpers/storage';

const ButtonComponent = (props) => {
  const { icon, imgIcon, text, addClass, href, type, hasDisabled, isLoading } = props;
  const { apiLink, nameFile } = props;
  let _type = type;

  const [isDisable, setIsDisable] = useState(false);
  if (!_type) {
    _type = 'button';
  }

  const handleExport = async () => {
    setIsDisable(true);
    fetch(apiLink, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization': `Bearer ${storageFC.getToken()}`
      },
    })
      .then((response) => {
        if (response.status === HTTP_NOT_FOUND) {
          return '';
        }
        return response.blob();
      })
      .then((blob) => {
        if (blob) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${nameFile}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
        setIsDisable(false);
      });
  };

  const TagButton = () => {
    if (_type === TYPE_EXPORT_PDF) {
      _type = 'button';
      return (
        <Button
          type={_type}
          href={href}
          onClick={handleExport}
          disabled={isDisable}
          className={classNames(`${styles.ctButton} ${addClass}`)}
        >
          {imgIcon && <img src={imgIcon} alt={imgIcon} />}
          {icon && icon}
          {text}
          <i
            className={classNames(
              `fa fa-spinner fa-pulse fa-3x fa-fw ${isDisable ? '' : 'hidden'} ${styles.iconLoading}`,
            )}
          />
        </Button>
      );
    } else {
      return (
        <Button
          type={_type}
          href={href}
          onClick={props.handleClick}
          className={classNames(`${styles.ctButton} ${addClass}`)}
          disabled={hasDisabled}
        >
          {imgIcon && <img src={imgIcon} alt={imgIcon} />}
          {icon && icon}
          {text}
          <i
            className={classNames(
              `fa fa-spinner fa-pulse fa-3x fa-fw ${isLoading ? '' : 'hidden'} ${styles.iconLoading}`,
            )}
          />
        </Button>
      );
    }
  };

  return (
    <>
      <TagButton />
    </>
  );
};

export default ButtonComponent;
