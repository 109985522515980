import NumberFormat from 'react-number-format';

export function FormatNumber({ value }) {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      renderText={(formattedValue) => <div>{formattedValue}</div>}
    />
  );
}

export const formatNumberCurrency = (value) => {
  if (!value) return value;

  let _value = value.toString().replace(/,/g, '');
  _value = value.toString().replace(/\./g, '');
  return new Intl.NumberFormat('vi-VN').format(_value).replace(/\./g, '.');
};

export const formatCurrencyNumber = (value) => {
  if (!value) return value;
  return value.toString().replace(/\./g, '');
};

export const formatNumber = (value) => {
  if (!value) return value;

  let _value = value.toString().replace(/,/g, '');
  return new Intl.NumberFormat('vi-VN').format(_value).replace(/\./g, ',');
};

export const formatNumberTwoChar = (number) => {
  if (number >= 10) return `${number}`;
  else if (number === 0) return '0';

  return `0${number}`;
};
