import React, { useState, useEffect, useCallback, useContext } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ButtonBase, FormControl, debounce, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import sanitizeHtml from 'sanitize-html';

import './style.scss';
import totalIcon from 'assets/img/icons/common/company/total-icon.svg';
import iconLoading from './../../../../assets/img/icons/loading.png';
import stylesCommon from './../styles.module.scss';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import { BG_POSITION } from 'constants/ColorMap';
import { WHEAT } from 'constants/ColorForm';
import { ThemContext } from 'layouts/Member';
import Nav from '../NavAssets/Nav.js';
import { DEFAULT_LIMIT, DEFAULT_PAGE_MUI, PER_PAGE_OPTION } from 'constants/index.js';
import { useGetListRequest, useRequestFifth } from 'hook/userAssets.js';
import CustomInputDate from 'components/InputYupAndMui/CustomInputDate';
import { convertFormatTimeSend } from 'helpers';
import configs from 'config';
import useResize from 'helpers/useResize';
import { CONTROL_RADIO } from 'components/InputYupAndMui/constant';
import * as Constants from 'constants/index.js';
import { transferStatus, transferStatusToColor, transferStatusToBackgroundColor } from '../../TimeKeeping/ListRequests/ultil';
import {
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS,
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED,
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY,
} from 'constants/TimeKeeping';

function getTextNull(text) {
  if (!text) {
    return 'N/A';
  }
  return text;
}

const ListAssetRequest = () => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm();

  const userId = useContext(ThemContext);

  const [filter, setFilter] = useState({
    start_date: null,
    status: 0,
    key_word: null,
    end_date: convertFormatTimeSend(new Date(), 'DD/MM/YYYY'),
    category_id: null,
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT,
    user_ids: [userId],
  });

  const STATUS_REQUEST = [
    {
      value: STATUS_REQUEST_OPEN,
      labelName: t('timekeeping.request.statusOpen'),
    },
    {
      value: STATUS_REQUEST_IN_PROGRESS,
      labelName: t('timekeeping.request.statusInProgress'),
    },
    {
      value: STATUS_REQUEST_COMPLETED,
      labelName: t('timekeeping.request.statusCompleted'),
    },
    {
      value: STATUS_REQUEST_DENY,
      labelName: t('timekeeping.request.statusDeny'),
    },
    {
      value: STATUS_REQUEST_REWORK,
      labelName: t('timekeeping.request.statusRework'),
    },
    {
      value: STATUS_REQUEST_RECALL,
      labelName: t('timekeeping.request.statusRecall'),
    }
  ];

  const { data: dataRequestFifth } = useRequestFifth(configs.ID_ASSET_REQUEST);

  const { data: dataAsset, isLoading } = useGetListRequest(filter);

  useEffect(() => {
    setFilter({ ...filter, category_id: dataRequestFifth?.code });
    // eslint-disable-next-line
  }, [dataRequestFifth]);

  useEffect(() => {
    setFilter((prev) => {
      return {
        ...prev,
        user_ids: [userId],
      };
    });
  }, [userId]);

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Filter by start time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByStartTime = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        start_date: convertFormatTimeSend(newValue, 'DD/MM/yyyy'),
      });
    }),
  );

  // Filter by end time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByEndTime = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        end_date: convertFormatTimeSend(newValue, 'DD/MM/yyyy'),
      });
    }),
  );

  const handleChangeRequest = (event) => {
    if (!event.target.value || event.target.value === '0') {
      setFilter({ ...filter, status: 0, page: DEFAULT_PAGE_MUI });
    } else {
      setFilter({ ...filter, status: event.target.value, page: DEFAULT_PAGE_MUI });
    }
  };

  const handleResetFilter = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setValue('request', 0);
    setFilter({
      start_date: null,
      status: 0,
      key_word: null,
      category_id: dataRequestFifth?.code,
      end_date: convertFormatTimeSend(new Date(), 'DD/MM/YYYY'),
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT,
      user_ids: [userId],
    });
    setValue('start_date', null);
    setValue('end_date', convertFormatTimeSend(new Date(), 'MM/DD/yyyy'));
  };

  // Watch value autocomplete user
  const watchValueEndDate = useWatch({
    control,
    name: 'end_date',
    defaultValue: convertFormatTimeSend(new Date(), 'MM/DD/yyyy'),
  });

  const viewPort = useResize();
  const checkShowDateHorizontal = viewPort.width >= 1250;

  return (
    <section className="list-request-assets">
      <Grid container spacing={2}>
        <Grid container className="nav-fixed pl-15">
          <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
            <Grid container>
              <Nav />
            </Grid>
          </Grid>
          <Grid item xs sm md lg xl className={stylesCommon.buttonGroupTop}>
            <a href={`${configs.DOMAIN_WORK_FOLLOW}/request`} className="request" target="blank">
              <ButtonComponent addClass="create-request" icon={<AddIcon />} text={t('request.createRequest')} />
            </a>
          </Grid>
        </Grid>
      </Grid>
      <div className={stylesCommon.assets}>
        <div className={stylesCommon.assets__list}>
          <div className="table-list table-border-show">
            <Paper>
              <TableContainer>
                <Table className="table-border-bottom-none">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 80 }}>{t('common.STT')}</TableCell>
                      <TableCell style={{ width: 140 }}>{t('assets.listRequestDay')}</TableCell>
                      <TableCell>{t('assets.content')}</TableCell>
                      <TableCell style={{ width: 130 }} className="table-last-th">{t('assets.status')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Loading addClass="mt-3" />
                        </TableCell>
                      </TableRow>
                    )}
                    {dataAsset?.data && dataAsset?.data?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div className="error-no-data">{t('common.notData')}</div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      dataAsset?.data?.map((item, index) => (
                        <TableRow hover key={index}>
                          <TableCell>
                            <a
                              href={`${configs.DOMAIN_WORK_FOLLOW}/request?request_id=${item?.request_id}`}
                              target="blank"
                              ref={(input) => {
                                item.handelClick = input;
                              }}
                              className="link-work-fl"
                            >
                              {index + 1 + (filter.page > 0 ? dataAsset?.to : 0) >= 10 ? (
                                index + 1 + (filter.page > 0 ? dataAsset?.to : 0)
                              ) : (
                                <>0{index + 1 + (filter.page > 0 ? dataAsset?.to : 0)}</>
                              )}
                            </a>
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              item.handelClick.click();
                            }}
                          >
                            {getTextNull(item?.date)}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              item.handelClick.click();
                            }}
                            className='text-ellipsis'
                            sx={{ whiteSpace: 'nowrap', maxWidth: 100 }}
                          >
                            {sanitizeHtml(getTextNull(item?.hr_content), { allowedTags: [] })}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              item.handelClick.click();
                            }}
                          >
                            {item?.status ? (
                              <span
                                className={classNames(
                                  transferStatusToColor(item?.status)
                                )}
                              >
                                {transferStatus(item?.status)}
                              </span>
                            ) : (
                              t('common.notDataContract')
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {dataAsset?.total > 0 && (
                <div className="table-pagination">
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTION}
                    component="div"
                    count={dataAsset?.total}
                    rowsPerPage={filter?.per_page}
                    page={filter?.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                  />
                </div>
              )}
            </Paper>
          </div>
        </div>

        <div className={stylesCommon.assets__condition}>
          <div className={classNames(`${stylesCommon.boxTotal} border-show`)}>
            <img src={totalIcon} alt={totalIcon} />
            <div className={stylesCommon.boxText}>
              <div className={stylesCommon.text}>{t('assets.totalRequest')}</div>
              <div className={stylesCommon.number}>{dataAsset?.total}</div>
            </div>
          </div>

          <div className={'bg-white pd-15 border-show '}>
            <div
              className="d-flex padding-element-filter justify-content-between align-items-center"
              style={{
                flexDirection: checkShowDateHorizontal ? 'row' : 'column',
              }}
            >
              <div>
                <CustomInputDate
                  control={control}
                  label={t('common.startDate')}
                  defaultValue={null}
                  name="start_date"
                  register={register}
                  useWatch={useWatch}
                  setValue={setValue}
                  errors={errors}
                  max={watchValueEndDate}
                  handleChange={handleFilterByStartTime}
                />
              </div>
              {checkShowDateHorizontal ? <span className="ml-1 mr-1">~</span> : <br />}
              <div>
                <CustomInputDate
                  control={control}
                  label={t('common.endDate')}
                  defaultValue={moment().toDate()}
                  name="end_date"
                  register={register}
                  useWatch={useWatch}
                  setValue={setValue}
                  errors={errors}
                  min={moment(filter?.start_date, 'DD/MM/yyyy').toDate()}
                  max={moment().toDate()}
                  handleChange={handleFilterByEndTime}
                />
              </div>
            </div>

            <div className="strike" />
            <div>
              <FormControl component="fieldset">
                <FormLabel className="title-body marginElementBottom text">{t('common.status')}</FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value={0}
                        onChange={(e) => {
                          setValue('request', e.target.value);
                          handleChangeRequest(e);
                        }}
                        control={
                          <Radio
                            sx={{
                              color: WHEAT,
                              '&.Mui-checked': {
                                color: BG_POSITION[0],
                              },
                            }}
                          />
                        }
                        label={t('employees.all')}
                        className="radioCustom"
                      />

                      <div key={1} className="division">
                        {STATUS_REQUEST?.map((item, index) => (
                          <div key={index} className="division d-flex justify-content-between">
                            <FormControlLabel
                              key={index}
                              value={item?.value}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  status: e.target.value,
                                  page: Constants.DEFAULT_PAGE_MUI,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              control={CONTROL_RADIO}
                              label={item?.labelName}
                            />
                            <div className={classNames(`dot-status-accept ${transferStatusToBackgroundColor(item?.value)}`)}></div>
                          </div>
                        ))}
                      </div>
                    </RadioGroup>
                  )}
                  name="request"
                  control={control}
                  defaultValue={filter?.status}
                />
              </FormControl>
            </div>

            <div className="strike" />

            <ButtonBase className="btn-reset" size="lg" onClick={handleResetFilter}>
              <img src={iconLoading} alt="Reset" width="20px" /> &emsp;
              {t('common.reset')}
            </ButtonBase>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListAssetRequest;
