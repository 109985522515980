import { useQuery } from 'react-query';

import { USE_COMPANY_EMPLOYEES } from 'constants/Company.js';
import { getCompanyEmployees } from 'api/company';
import {
  USE_COUNT_BRANCH_MEMBER,
  USE_COUNT_DIVISION_MEMBER,
  USE_DEPARTMENT_DIAGRAM,
  USE_SKILL_DIAGRAM,
} from '../constants/Company';
import { getCountBranchMember, getCountDivisionMember, getDepartmentDiagram, getSkillDiagram } from '../api/company';

export function useGetCompanyEmployees(params) {
  const { data, isLoading, error } = useQuery([USE_COMPANY_EMPLOYEES, params], async () => {
    const response = await getCompanyEmployees(params);

    return response?.data?.data;
  });

  return { ...data, isLoading, error };
}

export function useGetCountDivisionMember(params) {
  const { data, isLoading, error } = useQuery([USE_COUNT_DIVISION_MEMBER, params], async () => {
    const response = await getCountDivisionMember(params);

    return response?.data;
  });

  return { ...data, isLoading, error };
}

export function useGetCountBranchMember(params) {
  const { data, isLoading, error } = useQuery([USE_COUNT_BRANCH_MEMBER, params], async () => {
    const response = await getCountBranchMember(params);

    return response?.data;
  });

  return { ...data, isLoading, error };
}

export function useGetDepartmentDiagram(params) {
  const { data, isLoading, error } = useQuery([USE_DEPARTMENT_DIAGRAM, params], async () => {
    const response = await getDepartmentDiagram(params);

    return response;
  });

  return { ...data, isLoading, error };
}

export function useGetSkillDiagram(params) {
  const { data, isLoading, error } = useQuery([USE_SKILL_DIAGRAM, params], async () => {
    const response = await getSkillDiagram(params);

    return response?.data;
  });

  return { ...data, isLoading, error };
}
