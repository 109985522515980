import React from 'react';
import { useTranslation } from 'react-i18next';

import Nav from '../Nav';

import viIcon from 'assets/img/icons/common/vi-icon.svg';
import jpIcon from 'assets/img/icons/common/jp-icon.svg';
import addressIcon from 'assets/img/icons/common/company/address-icon.svg';
import phoneIcon from 'assets/img/icons/common/company/phone-icon.svg';
import gmailIcon from 'assets/img/icons/common/company/gmail-icon.svg';
import infoIcon from 'assets/img/icons/common/company/info-icon.svg';

import styles from './styles.module.scss';

const HandbookEmployee = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="nav-fixed">
        <Nav />
      </div>

      <div className={styles.blockInfo}>
        <div className={styles.title}>
          <img src={infoIcon} alt="" />
          <div>{t('company.handbook.companyInfo')}</div>
        </div>

        <div className={styles.body}>
          <div className={styles.companyInfo}>
            <div className={styles.companyBody}>
              <img className={styles.logo} src={viIcon} alt="" />

              <div>
                <div className={styles.companyName}>{t('company.handbook.vi.name')}</div>
                <div>
                  <div className={styles.description}>
                    <img src={addressIcon} alt="" />
                    <div>
                      <div className={styles.content}>{t('company.handbook.vi.address1')}</div>
                      <div className={styles.content}>{t('company.handbook.vi.address2')}</div>
                      <div className={styles.content}>{t('company.handbook.vi.address3')}</div>
                    </div>
                  </div>

                  <div className={styles.description}>
                    <img src={phoneIcon} alt="" />
                    <div>
                      <div className={styles.content}>{t('company.handbook.vi.phone')}</div>
                    </div>
                  </div>

                  <div className={styles.description}>
                    <img src={gmailIcon} alt="" className={styles.gmailIcon} />
                    <div>
                      <div className={styles.content}>{t('company.handbook.vi.gmail')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.companyInfo}>
            <div className={styles.companyBody}>
              <img className={styles.logo} src={jpIcon} alt="" />

              <div>
                <div className={styles.companyName}>{t('company.handbook.ja.name')}</div>
                <div>
                  <div className={styles.description}>
                    <img src={addressIcon} alt="" />
                    <div>
                      <div className={styles.content}>{t('company.handbook.ja.address1')}</div>
                    </div>
                  </div>

                  <div className={styles.description}>
                    <img src={phoneIcon} alt="" />
                    <div>
                      <div className={styles.content}>{t('company.handbook.ja.phone')}</div>
                    </div>
                  </div>

                  <div className={styles.description}>
                    <img src={gmailIcon} alt="" className={styles.gmailIcon} />
                    <div>
                      <div className={styles.content}>{t('company.handbook.ja.gmail')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={styles.blockInfo}>
        <div className={styles.title}>
          <img src={infoIcon} alt="" />
          <div>{t('company.handbook.regulationsOnSalaryBonusAndBenefits')}</div>
        </div>

        <div className={styles.body}>
          <a
            href="https://amelavn-my.sharepoint.com/:b:/g/personal/anh_nguyentu_amela_vn/EckRScKHL89PjgpIqnI6Z6MB_DcUvZmEBo_YBAf2QdyxVg?e=u6aMma"
            rel="noreferrer"
            target="_blank"
            className={styles.linkRule}
          >
            {t('company.handbook.regulationsOnSalaryBonusAndBenefits')}
          </a>
        </div>
      </div>

      <div className={styles.blockInfo}>
        <div className={styles.title}>
          <img src={infoIcon} alt="" />
          <div>{t('company.handbook.overtimeRegulations')}</div>
        </div>

        <div className={styles.body}>
          <a
            href="https://amelavn.sharepoint.com/sites/Bonne-AmelaCentralManagementSystem/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FBonne%2DAmelaCentralManagementSystem%2FShared%20Documents%2FGeneral%2F02%2E%20Design%2F02%2E%20Detail%20design%2FQuy%20%C4%91%E1%BB%8Bnh%20C%C3%B4ng%20ty%20%28%20ME%29%2FTP%2DAML%2D18%2D03%2D01%20Quy%20%C4%91%E1%BB%8Bnh%20l%C3%A0m%20th%C3%AAm%20gi%E1%BB%9D%2Epdf&parent=%2Fsites%2FBonne%2DAmelaCentralManagementSystem%2FShared%20Documents%2FGeneral%2F02%2E%20Design%2F02%2E%20Detail%20design%2FQuy%20%C4%91%E1%BB%8Bnh%20C%C3%B4ng%20ty%20%28%20ME%29&p=true&ga=1"
            rel="noreferrer"
            target="_blank"
            className={styles.linkRule}
          >
            {t('company.handbook.overtimeRegulations')}
          </a>
        </div>
      </div>

      <div className={styles.blockInfo}>
        <div className={styles.title}>
          <img src={infoIcon} alt="" />
          <div>{t('company.handbook.projectPerformanceForProjectRegulations')}</div>
        </div>

        <div className={styles.body}>
          <a
            href="https://amelavn.sharepoint.com/sites/Bonne-AmelaCentralManagementSystem/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FBonne%2DAmelaCentralManagementSystem%2FShared%20Documents%2FGeneral%2F02%2E%20Design%2F02%2E%20Detail%20design%2FQuy%20%C4%91%E1%BB%8Bnh%20C%C3%B4ng%20ty%20%28%20ME%29%2FAML%5FQuy%20ch%E1%BA%BF%20%E1%BB%A9ng%20th%C6%B0%E1%BB%9Fng%20Project%20Performance%2Epdf&parent=%2Fsites%2FBonne%2DAmelaCentralManagementSystem%2FShared%20Documents%2FGeneral%2F02%2E%20Design%2F02%2E%20Detail%20design%2FQuy%20%C4%91%E1%BB%8Bnh%20C%C3%B4ng%20ty%20%28%20ME%29&p=true&ga=1"
            rel="noreferrer"
            target="_blank"
            className={styles.linkRule}
          >
            {t('company.handbook.projectPerformanceForProjectRegulations')}
          </a>
        </div>
      </div> */}
    </>
  );
};

export default HandbookEmployee;
