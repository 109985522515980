import { useQuery } from 'react-query';
import {
  apiGetTimeSheet,
  apiGetTotalTimeSheet,
  apiGetTimeSheetList,
  apiGetTimeSheetTotal,
  apiGetRequestIndividual,
  apiGetListTypeRequestIndividual,
  apiGetOtIndividual,
  apiGetListTypeOtIndividual,
  apiGetTotalTimekeepingYear,
  apiGetTotalTimekeepingMonth,
  apiGetLateTimeAllCompany,
  apiListTypeOfCategory,
  apiGetListTableOT,
  apiGetScheduleRequestLeader,
  apiGetHolidayScheduleRequestLeader,
  apiGetHolidays,
  apiGetSchedule,
  apiGetListUserAccept,
  apiGetListUserNextPersonUpdate,
  apiGetListCheckbox,
  apiGetProject,
  apiGetNumberMinutesRegisteredLate,
  apiGetNumberMinutesRegisteredEarly,
  apiGetScheduleOfUser,
  apiGetDetailRequest,
  apiGetNumberMinutesRegistered
} from 'api/timesheet';
import { apiGetAllDivision } from 'api/masterData';
import { HTTP_OK } from 'constants/StatusCode';
import * as Constants from './../constants/TimeSheet';
import { ZERO } from 'constants/index';

export function useGetListTypeOfCategory(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_LIST_TYPE_REQUEST_BY_CATEGORY, async () => {
    const response = await apiListTypeOfCategory(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useTimeSheet(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_TIME_SHEET, params],
    async () => {
      const response = await apiGetTimeSheet(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.userId && params.category_id[0] > 0 && params.category_id[1] > 0 ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useTotalTimeSheet(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_TOTAL_TIME_SHEET, params],
    async () => {
      const response = await apiGetTotalTimeSheet(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetTimeSheetList(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_TIME_SHEET_LIST, params],
    async () => {
      const response = await apiGetTimeSheetList(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetTimeSheetTotal(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_TIME_SHEET_TOTAL, params],
    async () => {
      const response = await apiGetTimeSheetTotal(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useListAllDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_ALL_DIVISION, params], async () => {
    const response = await apiGetAllDivision(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetRequestIndividual(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_REQUEST_INDIVIDUAL, params],
    async () => {
      const response = await apiGetRequestIndividual(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetListTypeRequestIndividual(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_TYPE_REQUEST_INDIVIDUAL, params], async () => {
    const response = await apiGetListTypeRequestIndividual(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetOtIndividual(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_OT_INDIVIDUAL, params],
    async () => {
      const response = await apiGetOtIndividual(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetListTypeOtIndividual(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_TYPE_OT_INDIVIDUAL, params], async () => {
    const response = await apiGetListTypeOtIndividual(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useTotalTimekeepingYear(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_TIMEKEEPING_TOTAL_YEAR, params],
    async () => {
      if (params?.division_id === ZERO) delete params.division_id;

      const response = await apiGetTotalTimekeepingYear(params);
      return response.data;
    },
    { enabled: params.userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useTotalTimekeepingMonth(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_TIMEKEEPING_TOTAL_MONTH, params],
    async () => {
      const response = await apiGetTotalTimekeepingMonth(params);
      return response.data;
    },
    { enabled: params.userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useListLateTimeAllCompany(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_TIMEKEEPING_LATE_OT_COMPANY, params],
    async () => {
      const response = await apiGetLateTimeAllCompany(params);
      return response.data;
    },
    { enabled: params.userId ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useListTableOT(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_TABLE_OT, params], async () => {
    const response = await apiGetListTableOT(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetScheduleRequestLeader(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_SCHEDULE_REQUEST_LEADER, params], async () => {
    const response = await apiGetScheduleRequestLeader(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetHolidayScheduleRequestLeader(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_HOLIDAY_SCHEDULE_REQUEST_LEADER, params], async () => {
    const response = await apiGetHolidayScheduleRequestLeader(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetHolidays(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_HOLIDAYS, params], async () => {
    const response = await apiGetHolidays(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetSchedule(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_SCHEDULE, params],
    async () => {
      const response = await apiGetSchedule(params);
      return response;
    },
    { enabled: params?.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetListUserAccept(workFollowId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_USER_ACCEPT_WORK_FOLLOW, params], async () => {
    const response = await apiGetListUserAccept(workFollowId, params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetListUserNextPersonUpdate(workFollowId, step, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_LIST_USER_NEXT_PERSON_UPDATE, workFollowId, step, params],
    async () => {
      const response = await apiGetListUserNextPersonUpdate(workFollowId, step, params);
      return response;
    },
    { enabled: workFollowId && step ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetListCheckbox(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_CHECKBOX_WORK_FOLLOW, params], async () => {
    const response = await apiGetListCheckbox(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetProject(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_PROJECT, params], async () => {
    const response = await apiGetProject(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetNumberMinutesRegisteredLate(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_NUMBER_MINUTES_REGISTER_LATE, params], async () => {
    const response = await apiGetNumberMinutesRegisteredLate(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetNumberMinutesRegistered(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_NUMBER_MINUTES_REGISTER, params], async () => {
    const response = await apiGetNumberMinutesRegistered(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetNumberMinutesRegisteredEarly(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_NUMBER_MINUTES_REGISTER_EARLY, params], async () => {
    const response = await apiGetNumberMinutesRegisteredEarly(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetScheduleOfUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_SCHEDULE_OF_USER, params], async () => {
    const response = await apiGetScheduleOfUser(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetDetailRequest(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_DETAIL_REQUEST, params],
    async () => {
      const response = await apiGetDetailRequest(params);
      return response;
    },
    { enabled: params.id ? true : false },
  );

  return { ...response, error, isLoading };
}
