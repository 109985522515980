import $ from 'jquery';
import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import { Col, Row, Label } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import iconInfo from '../../../../assets/img/icons/info.png';
import radio from './../../../../assets/img/icons/radio.png';
import radioChecked from './../../../../assets/img/icons/radio-checked.png';

import * as Constants from '../../../../constants/ProfileEmployee.js';
import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import InputField from 'components/Input/InputField';
import ModalComponent from 'components/Modal';
import styles from './../style.module.scss';
import { DATA_TYPE_NUMBER } from 'constants/index.js';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from '../../../../constants/StatusCode.js';
import { apiUpdateInforUser } from 'api/profileEmployee';
import { isNotNullObject, isValidEmail } from 'helpers/index';
import {
  GENDER_MALE,
  STATUS_ACTIVE,
  MARRIE,
  UNMARRIE,
  MAX_LENGTH_15,
  MAX_LENGTH_100,
} from '../../../../constants/ProfileEmployee.js';

const Infomation = (props) => {
  const queryClient = useQueryClient();
  const { uProfileUser, contractNow } = props;
  const { t } = useTranslation();
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [textModal] = useState({
    titleEdit: t('infoEmployee.modalTitleInforEdit'),
  });

  const [formSubmit, setFormSubmit] = useState({});
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [stateButton, setStateButton] = useState(false);

  useEffect(() => {
    setFormSubmit({
      phone: uProfileUser?.user_information?.phone ? uProfileUser?.user_information?.phone : '',
      address: uProfileUser?.user_information?.address ? uProfileUser?.user_information?.address : '',
      temp_address: uProfileUser?.user_information?.address_temporary
        ? uProfileUser?.user_information?.address_temporary
        : '',
      marital: uProfileUser?.user_information?.marital ? uProfileUser?.user_information?.marital : UNMARRIE,
      personal_email: uProfileUser?.user_information?.personal_email || ''
    });
  }, [uProfileUser]);

  $('input[type=tel]').keydown(function () {
    return false;
  });

  const handleInput = (e, nameField) => {
    if (
      (e.target.name === 'phone' && Number(e.target.value) !== 'NaN' && e.target.value >= 0) ||
      e.target.name !== 'phone'
    ) {
      const error = {};
      if (e.target.name === 'phone') {
        error.error_phone = '';
      } else if (e.target.name === 'address') {
        error.error_permanent_address = '';
      } else if (e.target.name === 'temp_address') {
        error.error_temporary_address = '';
      } else if (e.target.name === 'personal_email') {
        error.error_personal_email = '';
      }

      setFormSubmit({
        ...formSubmit,
        ...error,
        [e.target.name]: e.target.value,
      });
    }
  };

  const nationalityLabel = (nationality) => {
    switch (nationality) {
      case Constants.NATIONALITY_VIETNAM:
        return (<>{t('infoEmployee.nationalityVietnam')}</>);
      case Constants.NATIONALITY_FOREIGN:
        return (<>{t('infoEmployee.nationalityForeign')}</>);  
      default:
        return (<></>);  
    }
  };

  const changeRadio = (nameField, value) => {
    setFormSubmit({
      ...formSubmit,
      [nameField]: value,
    });
  };

  function validate() {
    var error = {};
    var focus = true;
    if (!formSubmit.phone) {
      error.error_phone = t('messages.pleaseFillOutThisField');
      if (focus) {
        $('input[name=phone]').focus();
        focus = false;
      }
    }
    if (formSubmit.personal_email && !isValidEmail(formSubmit.personal_email)) {
      error.error_personal_email = t('messages.pleaseFillOutThisField');
      if (focus) {
        $('input[name=personal_email]').focus();
        focus = false;
      }
    }

    if (isNotNullObject(error)) {
      setFormSubmit({
        ...formSubmit,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (event) => {
    setFormSubmit({
      ...formSubmit,
      error_phone: '',
      error_permanent_address: '',
      error_temporary_address: '',
    });
    event.preventDefault();
    setStateButton(true);

    if (!validate()) {
      setStateButton(false);
      return;
    }

    const data = {
      ...formSubmit,
      address: formSubmit.address.trim(),
      temp_address: formSubmit.temp_address.trim(),
    };
    let response = {};
    response = await apiUpdateInforUser(data, uProfileUser?.user_information?.user_id);
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setFormSubmit({
        ...formSubmit,
        error_phone: msg?.phone
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_personal_email: msg?.personal_email
          ?.map((item) => {
            return item;
          })
          .join('\n'),  
        error_permanent_address: msg?.address
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_temporary_address: msg?.temp_address
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      if (msg?.phone) {
        $('input[name=phone]').focus();
      } else if (msg?.address) {
        $('input[name=address]').focus();
      } else if (msg?.temp_address) {
        $('input[name=temp_address]').focus();
      } else if (msg?.personal_email) {
        $('input[name=personal_email]').focus();
      }
      setStateButton(false);
      return;
    }

    if (response?.data?.status === HTTP_OK) {
      setShowModalEdit(false);
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      await queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
    }
  };

  const handleClickEdit = () => {
    setFormSubmit({
      phone: uProfileUser?.user_information?.phone ? uProfileUser?.user_information?.phone : '',
      address: uProfileUser?.user_information?.address ? uProfileUser?.user_information?.address : '',
      temp_address: uProfileUser?.user_information?.address_temporary
        ? uProfileUser?.user_information?.address_temporary
        : '',
      marital: uProfileUser?.user_information?.marital ? uProfileUser?.user_information?.marital : UNMARRIE,
      personal_email: uProfileUser?.user_information?.personal_email || '',
      error_phone: '',
      error_permanent_address: '',
      error_temporary_address: '',
      error_personal_email: ''
    });
    setShowModalEdit(true);
  };

  return (
    <>
      <div className={styles.profileBoxInfo}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className={styles.profileTitle}>
              <img alt={iconInfo} src={iconInfo} className="ml-13" />
              {t('infoEmployee.titleInfo')}
            </div>
          </Col>
          <Col xs="2">
            <div className={classNames(`${styles.editDeleteButton} mr--15`)}>
              <HiPencil onClick={handleClickEdit} />
            </div>
          </Col>
        </Row>
        <Grid container spacing={2} className={styles.styleInfoMui}>
          <Grid item xs={12} md={12} lg={7}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.dateOfBirth')}</div>
            <div>
              {uProfileUser?.user_information?.birthday === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser?.user_information?.birthday
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5}>
            <div className={styles.col2InfoLeft}>{t('infoEmployee.nationality')}</div>
            <div className={classNames(` auto-scroll-hidden`)}>
              {uProfileUser && uProfileUser?.user_information?.nationality === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                nationalityLabel(uProfileUser?.user_information?.nationality)
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={7} className={styles.styleBackground}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.gender')}</div>
            <div>
              {uProfileUser && uProfileUser?.user_information?.gender === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : uProfileUser && uProfileUser?.user_information?.gender === GENDER_MALE ? (
                <span>{t('infoEmployee.genderMale')}</span>
              ) : (
                <span>{t('infoEmployee.genderFemale')}</span>
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5} className={styles.styleBackground}>
            <div className={styles.col2InfoLeft}>{t('infoEmployee.status')}</div>
            <div>
              {uProfileUser && uProfileUser?.user_information?.status === STATUS_ACTIVE ? (
                <span>{t('infoEmployee.statusActive')}</span>
              ) : (
                <span>{t('infoEmployee.statusUnactive')}</span>
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={7}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.phone')}</div>
            <div>
              {uProfileUser && uProfileUser?.user_information?.phone === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser && uProfileUser?.user_information?.phone
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5}>
            <div className={styles.col2InfoLeft}>{t('infoEmployee.groupPersonal')}</div>
            <div>
              {uProfileUser && uProfileUser?.user_information?.group === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser?.user_information?.group
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={7} className={styles.styleBackground}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.married')}</div>
            <div>
              {uProfileUser && uProfileUser?.user_information?.marital === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : uProfileUser && uProfileUser?.user_information?.marital === MARRIE ? (
                <span>{t('infoEmployee.statusMarried')}</span>
              ) : (
                <span>{t('infoEmployee.statusMarrie')}</span>
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5} className={styles.styleBackground}>
            <div className={styles.col2InfoLeft}>{t('infoEmployee.divisionAndGroup')}</div>
            <div>
              {uProfileUser && uProfileUser?.division === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser?.division?.division_name
              )}
              
              {uProfileUser && uProfileUser?.division?.team_name ? 
                <span>({uProfileUser && uProfileUser?.division?.team_name})</span> 
                :
                <span></span>                
              }
            </div>
          </Grid>          

          <Grid item xs={12} md={12} lg={7}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.addressTemporary')}</div>
            <div className={styles.tableCell}>
              {uProfileUser && uProfileUser?.user_information?.address_temporary ? (
                uProfileUser.user_information.address_temporary
              ) : (
                <>{t('infoEmployee.notUpdate')}</>
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5}>
            <div className={styles.col2InfoLeft}>{t('employees.contract')}</div>
            <div>
              {contractNow ? (
                <span>{contractNow.contract_category_name}</span>
              ) : (<span>{t('infoEmployee.notUpdate')}</span>)
              }
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={7} className={styles.styleBackground}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.address')}</div>
            <div className={styles.tableCell}>
              {uProfileUser && uProfileUser?.user_information?.address ? (
                uProfileUser.user_information.address
              ) : (
                <span>{t('infoEmployee.notUpdate')}</span>
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5} className={styles.styleBackground}>
            <div className={styles.col2InfoLeft}>{t('employees.personalEmail')}</div>
            <div>
              {uProfileUser && !uProfileUser?.user_information?.personal_email ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser && uProfileUser?.user_information?.personal_email
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>

      <ModalComponent
        toggle={() => setShowModalEdit(!isShowModalEdit)}
        isShowModal={isShowModalEdit}
        classNameAdd="edit-infor-user"
        title={textModal.titleEdit}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className="mt-4">
            <InputField
              label={t('employees.personalEmail')}
              variant="outlined"
              name="personal_email"
              defaultValue={formSubmit?.personal_email}
              onHandleInput={handleInput}
              error={formSubmit.error_personal_email}
            />
          </div>
          <div className="mt-4">
            <InputField
              label={t('infoEmployee.phone')}
              variant="outlined"
              name="phone"
              maxLength={MAX_LENGTH_15}
              defaultValue={formSubmit?.phone}
              onHandleInput={handleInput}
              error={formSubmit.error_phone}
              dataType={DATA_TYPE_NUMBER}
            />
          </div>
          <div className="mt-4">
            <InputField
              label={t('infoEmployee.permanentAddress')}
              variant="outlined"
              name="address"
              maxLength={MAX_LENGTH_100}
              defaultValue={formSubmit?.address}
              onHandleInput={handleInput}
              error={formSubmit.error_permanent_address}
            />
          </div>
          <div className="mt-4">
            <InputField
              label={t('infoEmployee.temporaryAddress')}
              variant="outlined"
              name="temp_address"
              maxLength={MAX_LENGTH_100}
              defaultValue={formSubmit?.temp_address}
              onHandleInput={handleInput}
              error={formSubmit.error_temporary_address}
            />
          </div>
          <div className="fieldset-group mt-4">
            <Label className={styles.gender}> {t('infoEmployee.married')}</Label>
            <img
              className={styles.radio}
              onClick={() => changeRadio('marital', 1)}
              alt="radio"
              src={formSubmit?.marital === MARRIE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('marital', 1)}>
              {t('infoEmployee.statusMarried')}
            </Label>
            <img
              alt="radio"
              className={`${styles.radio} ${styles.right}`}
              onClick={() => changeRadio('marital', 0)}
              src={formSubmit?.marital === UNMARRIE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('marital', 0)}>
              {t('infoEmployee.statusMarrie')}
            </Label>
          </div>
          <Row className="mt-4">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalEdit(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={t('infoEmployee.saveEdit')}
                addClass="w-100"
                handleClick={() => setShowModalEdit(true)}
                type="submit"
                hasDisabled={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>
    </>
  );
};

export default Infomation;
