import { Modal } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import styles from './styles.module.scss';

const showConfirm = (props) => {
  return Modal.confirm({
    icon: null,
    cancelText: t('common.cancelEN'),
    okText: t('common.confirm'),
    centered: true,
    className: styles.dialog,
    okButtonProps: {
      type: 'ghost',
      className: classNames(styles.btn, styles.btn__ok),
    },
    cancelButtonProps: {
      type: 'ghost',
      className: styles.btn,
    },
    autoFocusButton: null,
    ...props,
  });
};

export default showConfirm;
