import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import moment from 'moment';
import $ from 'jquery';

import * as Constants from 'constants/ProfileEmployee.js';
import styles from './../style.module.scss';
import AlertComponent from 'components/Alert';
import ModalComponent from 'components/Modal';
import DateDayComponent from 'components/DateTime/DateDay.js';
import SelectComponent from 'components/Select/Select.js';
import { getTypeRelative, getGenderEmployee } from 'helpers/profileEmployee.js';
import ButtonComponent from 'components/Buttons';
import { isNotNullObject } from 'helpers/index';
import { customDate } from 'helpers/formatDate.js';
import { apiCreateRelative, apiUpdateRelative, apiDeleteRelative } from 'api/profileEmployee';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/StatusCode.js';
import InputField from 'components/Input/InputField';
import iconRelative from 'assets/img/icons/relative.png';
import { DATA_TYPE_NUMBER } from 'constants/index.js';

const Relative = (props) => {
  const { uProfileUser, userId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isShowModalAdd, setShowModalAdd] = useState(false);
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  const [formAddRelative, setformAddRelative] = useState(
    {
      name: '',
      type: '',
      gender: '',
      phone: '',
      birthday: moment(),
      user_id: userId,
      error_name: '',
      error_birthday: '',
      error_type: '',
      error_gender: '',
      error_phone: '',
    },
  );

  const relatives = [
    { id: Constants.TYPE_RELATIVE_FATHER, name: t('infoEmployee.father') },
    { id: Constants.TYPE_RELATIVE_MOTHER, name: t('infoEmployee.mother') },
    { id: Constants.HUSBAND, name: t('infoEmployee.hubsband') },
    { id: Constants.TYPE_RELATIVE_WIFE, name: t('infoEmployee.wife') },
    { id: Constants.TYPE_RELATIVE_CHILD, name: t('infoEmployee.childrent') },
    { id: Constants.TYPE_RELATIVE_SIBLINGS, name: t('infoEmployee.siblings') },
  ];

  const [stateButton, setStateButton] = useState(false);

  const [textModal] = useState({
    titleAdd: t('infoEmployee.modalTitleRelativeAdd'),
    titleEdit: t('infoEmployee.modalTitleRelativeEdit'),
  });

  function onclickEditRelative(relative) {
    setformAddRelative({
      id: relative.id,
      name: relative.name,
      type: relative.type ? relative.type : '',
      gender: relative.gender ? relative.gender : 0,
      phone: relative.phone || '',
      birthday: relative?.birthday ? moment(relative.birthday, 'DD/MM/YYYY') : '',
      user_id: userId,
      error_name: '',
      error_birthday: '',
      error_type: '',
      error_gender: '',
    });
    setShowModalEdit(true);
  }

  function resetError() {
    setformAddRelative({ ...formAddRelative, error_name: '', error_birthday: '', error_gender: '', error_type: '', error_phone: '' });
  }

  function validate() {
    var focus = true;
    
    var error = {};
    if (!formAddRelative.name) {
      error.error_name = t('messages.pleaseFillOutThisField');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (formAddRelative.phone && formAddRelative.phone.length < Constants.MAX_LENGTH_10) {
      error.error_phone = t('messages.pleaseEnterPhoneAgain');
      if (focus) {
        $('input[name=phone]').focus();
        focus = false;
      }
    }
    if (!formAddRelative.type) {
      error.error_type = t('messages.pleaseFillOutThisField');
      if (focus) {
        $('input[name=type]').focus();
        focus = false;
      }
    }
    if (formAddRelative.gender === '') {
      error.error_gender = t('messages.pleaseFillOutThisField');
    }
    if (!formAddRelative.birthday) {
      error.error_birthday = t('messages.pleaseFillOutThisField');
    }
    if (isNotNullObject(error)) {
      setformAddRelative({
        ...formAddRelative,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (event) => {
    resetError();
    event.preventDefault();
    setStateButton(true);
    if (!validate()) {
      setStateButton(false);
      return;
    }

    let response = {};
    
    const data = {
      name: formAddRelative.name.trim(),
      phone: formAddRelative.phone.trim(),
      gender: formAddRelative.gender,
      birthday: customDate(formAddRelative.birthday, 'DD/MM/YYYY'),
      type: formAddRelative.type,
      user_id: userId,
    };
    if (isShowModalAdd) {
      response = await apiCreateRelative(data);
    } else {
      response = await apiUpdateRelative(data, formAddRelative.id);
    }
    
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setformAddRelative({
        ...formAddRelative,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_phone: msg?.phone
        ?.map((item) => {
          return item;
        })
        .join('\n'),
        error_birthday: msg?.birthday
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_type: msg?.type
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_gender: msg?.gender
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      setStateButton(false);
      return;
    }
    
    if (response?.data?.status === HTTP_OK) {
      setShowModalAdd(false);
      setShowModalEdit(false);
      setStateButton(false);
      setMessage(response.data.messages);
      setShowAlert(true);
      setformAddRelative({});
      await queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
    }
    setStateButton(false);
    resetError();
  };

  const confirmDelete = (id, isDependent) => {
    let title = t('common.doYouWantToDeletedInfoData');
    let buttons = [
      {
        label: t('infoEmployee.cancel'),
        className: 'btn-alert-cancel',
      },
      {
        label: t('infoEmployee.ok'),
        className: 'btn-alert-ok',
        onClick: async () => {
          const response = await apiDeleteRelative({ user_id: userId }, id);
          if (response.data.status === HTTP_OK) {
            setMessage(response.data.messages);
            setShowAlert(true);
            queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
          }
        },
      },
    ];
    if (isDependent) {
      title = t('infoEmployee.alertOnlyBOCanDeleteDependentPerson');
      buttons = [
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
        }
      ];
    }
    
    confirmAlert({
      title: title,
      buttons: buttons,
    });
  };
  const handleInput = (e, item) => {
    const error = {};
    if (e.target.name === 'name') {
      error.error_name = '';
    } else if (e.target.name === 'type') {
      error.error_type = '';
    } else if (e.target.name === 'gender') {
      error.error_gender = '';
    } else if (e.target.phone === 'phone') {
      error.error_phone = '';
    }
    
    setformAddRelative({
      ...formAddRelative,
      ...error,
      [e.target.name]: e.target.value,
    });
  };

  const handleDate = (value, item) => {
    if (value) {
      setformAddRelative({
        ...formAddRelative,
        birthday: value,
        error_birthday: '',
      });
    } else {
      setformAddRelative({
        ...formAddRelative,
        birthday: '',
      });
    }
  };

  const onclickAddRelative = () => {
    setformAddRelative(
      {
        name: '',
        gender: '',
        type: '',
        phone: '',
        user_id: userId,
        birthday: '',
        error_name: '',
        error_birthday: '',
        error_type: '',
        error_gender: '',
      },
    );
    setShowModalAdd(true);
  };

  return (
    <>
      <div className={styles.profileBoxBaby}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className={styles.profileTitle}>
              <img alt={iconRelative} className={styles.imgTitle} src={iconRelative} />
              {t('infoEmployee.titleRelative')}
            </div>
          </Col>
          <Col xs="2">
            {uProfileUser?.relative && uProfileUser?.relative?.length !== 0 && (
              <div className={classNames(`${styles.editDeleteButton} mr--15`)} onClick={() => onclickAddRelative()}>
                <IoIosAdd />
              </div>
            )}
          </Col>
        </Row>
        <div className={classNames(styles.salaryBoxParent, styles.tableRelative)}>
          {(uProfileUser && uProfileUser?.relative?.length === 0) | (uProfileUser?.relative === null) ? (
            <div className={styles.achievementNull}>
              <div className={styles.editDeleteButton} onClick={() => onclickAddRelative()}>
                <IoIosAdd />
              </div>
              <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addRelative')}</div>
            </div>
          ) : (
            <div className="table-list">
              <table className="table align-items-center table-hover table-responsive">
                <thead>
                  <tr>
                    <th className={styles.profileContentInfo}>{t('common.fullName')}</th>
                    <th className={styles.profileContentInfo}>{t('infoEmployee.relationship')}</th>
                    <th className={styles.profileContentInfo}>{t('employees.gender')}</th>
                    <th className={styles.profileContentInfo}>{t('common.birthday')}</th>
                    <th className={styles.profileContentInfo}>{t('common.phone')}</th>
                    <th className={styles.profileContentInfo}>{t('infoEmployee.labelDependPerson')}</th>
                    <th className={styles.profileContentInfo}>{t('infoEmployee.labelDependPersonStartDate')}</th>
                    <th className="col-delete-edit"></th>
                  </tr>
                </thead>
                <tbody>
                  {uProfileUser &&
                    uProfileUser?.relative?.map((itemRelative, index) => (
                      <tr key={index}>
                        <td className={styles.profileContentInfo}>{itemRelative.name}</td>
                        <td className={styles.profileContentInfo}>{t(getTypeRelative(itemRelative.type))}</td>
                        <td className={classNames(`${styles.profileContentInfo} text-bold`)}>
                          {' '}
                          {t(getGenderEmployee(itemRelative.gender))}
                        </td>
                        <td className={styles.profileContentInfo}>{itemRelative.birthday}</td>
                        <td className={styles.profileContentInfo}>{itemRelative.phone}</td>
                        <td className={styles.profileContentInfo}>{itemRelative.is_dependent ? t('common.yes') : t('common.no')}</td>
                        <td className={styles.profileContentInfo}>{itemRelative.is_dependent && 
                          itemRelative.dependent_periods.length > 0 ? 
                          <DependentPeriodList periods = {itemRelative.dependent_periods} />
                           : t('common.notDataContract')}
                        </td>
                        <td>
                          <div className="d-flex">
                            <div
                              onClick={() => onclickEditRelative(itemRelative)}
                              className={styles.editDeleteButtonChild}
                            >
                              <HiPencil />
                            </div>
                            <div onClick={() => confirmDelete(itemRelative.id, itemRelative.is_dependent)} className={styles.editDeleteButtonChild}>
                              <MdDelete />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {uProfileUser?.relative && uProfileUser?.relative?.length === 0 && (
                <div className={styles.achievementNull}>
                  <div className={styles.editDeleteButton} onClick={() => setShowModalAdd(true)}>
                    <IoIosAdd />
                  </div>
                  <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addRelative')}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ModalComponent
        toggle={() => setShowModalEdit(!isShowModalEdit)}
        isShowModal={isShowModalEdit}
        title={textModal.titleEdit}
        classNameAdd="relative"
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className="record">
            <div className="mt-4">
              <InputField
                label={t('common.fullName')}
                variant="outlined"
                name="name"
                maxLength={Constants.MAX_LENGTH_40}
                defaultValue={formAddRelative.name}
                value={formAddRelative.name}
                onHandleInput={handleInput}
                isRequired={true}
                error={formAddRelative.error_name}
              />
            </div>
            <div className="fieldset-group mt-4 select-category">
              <SelectComponent
                label={t('infoEmployee.selectRelative')}
                name="type"
                addClass="relative-field"
                valueDefault={formAddRelative.type ? formAddRelative.type : 0}
                onHandleChangeSelect={handleInput}
                isRequired={true}
                error={formAddRelative.error_type}
              >
                {relatives &&
                  relatives.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </SelectComponent>
            </div>
            <div className="fieldset-group mt-4 select-category">
              <SelectComponent
                label={t('infoEmployee.gender')}
                name="gender"
                valueDefault={formAddRelative.gender ? formAddRelative.gender : 0}
                onHandleChangeSelect={handleInput}
                isRequired={true}
                error={formAddRelative.error_gender}
              >
                <MenuItem value={Constants.GENDER_MALE}>{t('common.male')}</MenuItem>
                <MenuItem value={Constants.GENDER_FEMALE}>{t('common.female')}</MenuItem>
              </SelectComponent>
            </div>
            <div className="fieldset-group mt-4">
              <DateDayComponent
                label={t('common.birthday')}
                valueDefault={formAddRelative.birthday}
                onChangeHandle={handleDate}
                isRequired={true}
                error={formAddRelative?.error_birthday}
              />
            </div>
            <div className="mt-4">
              <InputField
                label={t('common.phone')}
                variant="outlined"
                name="phone"
                maxLength={Constants.MAX_LENGTH_15}
                defaultValue={formAddRelative.phone}
                value={formAddRelative.phone}
                onHandleInput={handleInput}
                error={formAddRelative.error_phone}
                dataType={DATA_TYPE_NUMBER}
              />
            </div>
          </div>
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalEdit(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={t('infoEmployee.saveEdit')}
                addClass="w-100"
                type="submit"
                hasDisabled={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <ModalComponent
        toggle={() => setShowModalAdd(!isShowModalAdd)}
        isShowModal={isShowModalAdd}
        title={textModal.titleAdd}
        classNameAdd="relative scroll"
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>       
          <div className="record">
            <div className="mt-4">
              <InputField
                label={t('common.fullName')}
                variant="outlined"
                name="name"
                maxLength={Constants.MAX_LENGTH_30}
                onHandleInput={handleInput}
                defaultValue={formAddRelative.name}
                isRequired={true}
                error={formAddRelative.error_name}                   
              />
            </div>
            <div className={`fieldset-group mt-4 select-category`}>
              <SelectComponent
                label={t('infoEmployee.selectRelative')}
                name="type"
                addClass={`${formAddRelative.error_type && 'error-field'}`}
                valueDefault={formAddRelative.type}
                onHandleChangeSelect={handleInput}
                isRequired={true}
                error={formAddRelative.error_type}
              >
                {relatives &&
                  relatives.map((relative) => {
                    return (
                      <MenuItem value={relative.id} key={relative.id}>
                        {relative.name}
                      </MenuItem>
                    );
                  })}
              </SelectComponent>
            </div>
            <div className="fieldset-group mt-4 select-category">
              <SelectComponent
                label={t('infoEmployee.gender')}
                name="gender"
                addClass={`${formAddRelative.error_gender && 'error-field'}`}
                valueDefault={formAddRelative.gender}
                onHandleChangeSelect={handleInput}
                isRequired={true}
                error={formAddRelative.error_gender}
              >
                <MenuItem value={Constants.GENDER_MALE}>{t('common.male')}</MenuItem>
                <MenuItem value={Constants.GENDER_FEMALE}>{t('common.female')}</MenuItem>
              </SelectComponent>
            </div>
            <div className="fieldset-group mt-4">
              <DateDayComponent
                label={t('common.birthday')}
                valueDefault={formAddRelative.birthday}
                onChangeHandle={handleDate}
                isRequired={true}
                error={formAddRelative?.error_birthday}
              />
            </div>
            <div className="mt-4">
              <InputField
                label={t('common.phone')}
                variant="outlined"
                name="phone"
                maxLength={Constants.MAX_LENGTH_15}
                onHandleInput={handleInput}
                defaultValue={formAddRelative.phone}
                error={formAddRelative.error_phone}
                dataType={DATA_TYPE_NUMBER}
              />
            </div>
          </div>
             
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalAdd(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent text={t('common.save')} addClass="w-100" type="submit" hasDisabled={stateButton} />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

const DependentPeriodList = (props) => {
  const { t } = useTranslation();
  const { periods } = props;
  const [showAll, setShowAll] = useState(false);
  const displayPeriods = !showAll ? periods.slice(0, 2) : periods;
  return (
    <>
        {  
          displayPeriods.map((period) => {
            return <div>
              {customDate(moment(period.start_date, 'DD/MM/YYYY'), 'MM/YYYY')}
              &nbsp;~&nbsp;
              {period.end_date !== '' ? customDate(moment(period.end_date, 'DD/MM/YYYY'), 'MM/YYYY') : t('common.notDataContract')}
            </div>
          })       
        }
        {(periods.length > 2) ? <button className={styles.buttonAsLink} onClick = {() => setShowAll(!showAll)}>
          { showAll ? t('common.showLess') : t('common.showMore') }
        </button> : ''}

    </>
  )
};

export default Relative;
