import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import SideNavBar from 'components/SideNavBar';
import { TAB_0, TAB_1 } from 'constants/index';

const Assets = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState();

  const routes = [
    {
      path: '/assets/list-assets',
      name: t('assets.listAssets'),
    },
    {
      path: '/assets/list-request',
      name: t('assets.listRequest'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case TAB_0:
        history.push('/assets/list-assets');
        break;
      case TAB_1:
        history.push('/assets/list-request');
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/assets/list-assets':
        setValue(TAB_0);
        break;
      case '/assets/list-request':
        setValue(TAB_1);
        break;

      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar routes={routes} value={value} onChange={handleChange} />;
};

export default Assets;
