import { Button, Modal } from 'antd';
import React from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { REPORT_APPROVED } from 'constants/Projects';
import { REPORT_REJECTED } from 'constants/Projects';
import { isShowButtonEditDelete, renderStatusTag } from '../../util/ProjectUtil';
import { customDate } from 'helpers/formatDate';
import { MdDelete, MdEdit } from 'react-icons/md';
import showConfirm from '../create-update/showConfirm';
import classNames from 'classnames';
import { ALL_PROCESS_TYPE } from 'constants/Projects';

const DetailDailyReport = ({ isOpen, setIsOpen, setIsOpenEdit, selectedReport, deleteReport }) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    setIsOpen(!isOpen);
  };

  const renderSelectedReport = () => {
    return (
      <div className={styles.detailReport}>
        {selectedReport?.reject_reason && selectedReport?.status === REPORT_REJECTED && (
          <div className={styles.rejectReason}>
            <span>{t('project.daily.detail.reasonReject')}</span>
            <p>{selectedReport?.reject_reason}</p>
          </div>
        )}
        <div className={classNames('align-items-center', styles.detailRow)}>
          <div className={styles.title}>{t('project.daily.detail.status')}</div>
          <div className={styles.content}>{renderStatusTag(selectedReport?.status)}</div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.title}>{t('project.daily.detail.taskName')}</div>
          <div className={styles.content}>{selectedReport?.title}</div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.title}>{t('project.daily.listReport.processType')}</div>
          <div className={styles.content}>
            {t(ALL_PROCESS_TYPE.find((item) => item.key === selectedReport?.process_type)?.text)}
          </div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.title}>{t('project.daily.detail.projectName')}</div>
          <div className={styles.content}>{selectedReport?.project?.name}</div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.title}>{t('project.daily.detail.description')}</div>
          <div className={styles.content}>{selectedReport?.description}</div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.title}>{t('project.daily.detail.actualTime')}</div>
          <div className={styles.content}>{selectedReport?.actual_time}h</div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.title}>{t('project.daily.detail.workDate')}</div>
          <div className={styles.content}>{customDate(selectedReport?.work_date, 'DD/MM/YYYY')}</div>
        </div>

        <div className={styles.detailRow}>
          <div className={styles.title}>{t('project.daily.detail.backlog')}</div>
          <div className={`${styles.content} ${styles.textOverFlow}`}>
            <a href={selectedReport?.link_backlog} target="_blank" rel="noreferrer">
              {selectedReport?.link_backlog}
            </a>
          </div>
        </div>
      </div>
    );
  };

  const handleDelete = () => {
    selectedReport?.status !== REPORT_APPROVED &&
      showConfirm({
        content: (
          <p
            dangerouslySetInnerHTML={{
              __html: t('project.daily.listReport.deleteConfirm', { taskName: selectedReport?.title }),
            }}
          />
        ),
        width: 420,
        onOk() {
          if (deleteReport?.isLoading) return;
          deleteReport.mutate(selectedReport?.id);
          setIsOpen(!isOpen);
        },
      });
  };

  const handleEdit = () => {
    setIsOpenEdit(true);
    setIsOpen(!isOpen);
  };

  return (
    <Modal
      title={t('project.daily.detail.title')}
      centered
      destroyOnClose
      maskClosable={false}
      closeIcon={<VscChromeClose />}
      visible={isOpen}
      onCancel={handleCancel}
      className={`${styles.modal} ${styles.detailModal}`}
      footer={
        selectedReport?.status === REPORT_APPROVED
          ? null
          : !isShowButtonEditDelete(selectedReport)
          ? null
          : [
              <Button className={styles.deleteButton} onClick={handleDelete} icon={<MdDelete size={20} />}>
                {t('project.daily.detail.delete')}
              </Button>,
              <Button className={styles.editButton} onClick={handleEdit} icon={<MdEdit size={20} />}>
                {t('project.daily.detail.edit')}
              </Button>,
            ]
      }
    >
      {renderSelectedReport()}
    </Modal>
  );
};

export default DetailDailyReport;
