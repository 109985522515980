import SideNavBar from 'components/SideNavBar';
// eslint-disable-next-line no-unused-vars
import { TAB_0, TAB_1, TAB_2 } from 'constants/index';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

const Nav = () => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(TAB_0);

  const routes = [
    // {
    //   name: t('project.allProject'),
    // },
    {
      name: t('project.calendar'),
    },
    {
      name: t('project.dailyReport'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      // case TAB_0:
      //   history.push('/project/list-project');
      //   break;
      case TAB_0:
        history.push('/project/calendar-report');
        break;
      case TAB_1:
        history.push('/project/daily-report');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      // case '/project/list-project':
      //   setValue(TAB_0);
      //   break;
      case '/project/calendar-report':
        setValue(TAB_0);
        break;
      case '/project/daily-report':
        setValue(TAB_1);
        break;
      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar routes={routes} value={value} onChange={handleChange} hasMarginBottom />;
};

export default Nav;
