import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IoMdClose } from 'react-icons/io';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { TextFieldDate as TextFieldDateDay, TextFieldNoneDate as TextFieldNoneDateDay } from './style.js';
import styles from './styles.module.scss';

const FORMAT = 'DD/MM/YYYY';

const DateDay = (props) => {
  const { label, clearable = false, addClass, valueDefault, onChangeHandle, name, error, isRequired, placeholder, sizeDate } = props;
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(valueDefault);
  }, [valueDefault]);

  const handleChangeDate = (newValue) => {
    onChangeHandle(newValue);
  };

  return (
    <>
      <div className={`${addClass} ${open ? styles.openCalenderTime : ''}`}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            fullWidth
            name={name}
            open={open}
            inputFormat="dd/MM/yyyy"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={value}
            label={
              <React.Fragment>
                {placeholder && !value ? placeholder : label}
                {isRequired && label ? <sup>*</sup> : ''}
              </React.Fragment>
            }
            onChange={handleChangeDate}
            renderInput={(params) => {
              let input
              if (label) {
                input = (
                  <TextFieldDateDay
                    {...params}
                    error={error ? true : false}
                    inputProps={{
                      style: sizeDate ? { fontSize: '14px' } : { padding: '14px 15px 14px 15px', fontSize: '14px' },
                      readOnly: true,
                    }}
                    size={sizeDate ? sizeDate : ''}
                    value={value ? moment(value).format(FORMAT) : ''}
                    placeholder={placeholder}
                  />
                )
              } else {
                input = (
                  <TextFieldNoneDateDay
                    {...params}
                    error={error ? true : false}
                    inputProps={{
                      style: sizeDate ? { fontSize: '14px' } : { padding: '14px 15px 14px 15px', fontSize: '14px' },
                      readOnly: true,
                    }}
                    size={sizeDate ? sizeDate : ''}
                    value={value ? moment(value).format(FORMAT) : ''}
                    placeholder={placeholder}
                  />
                )
              }
              return (
                <>
                  {input}
                  {clearable && value ? (
                    <div
                      className={styles.iconClose}
                      onClick={() => {
                        setValue(null);
                        onChangeHandle(null);
                      }}
                    >
                      <IoMdClose />
                    </div>
                  ) : null}
                </>
              );
            }}
          />
        </LocalizationProvider>
      </div>
      <p className={`${styles.messageError} error`}>{error}</p>
    </>
  );
};

export default DateDay;
