import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { IoIosAdd } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import Camera from './../../assets/img/icons/camera.png';

import styles from './style.module.scss';
import { handleOnError } from 'helpers';

const UploadImages = (props) => {
  const { t } = useTranslation();
  const { numberMax, onChangeImage, name, dataImage, isModal } = props;

  const [listImage, setListImage] = useState(dataImage);
  const [editImage, setEditImage] = useState(false);
  const [listShowImage, setListShowImage] = useState(dataImage);

  useEffect(() => {
    if (isModal && !editImage) {
      setListShowImage(dataImage);
      setListImage(dataImage);
    }
    if (!isModal) {
      setEditImage(false);
    }
  }, [isModal, dataImage, editImage]);

  let _numberMax = 20;
  if (numberMax) _numberMax = numberMax;

  if (!listShowImage) {
    setListShowImage(['']);
    setListImage(['']);

    if (_numberMax === 1) {
      onChangeImage('');
    } else {
      onChangeImage([]);
    }
  }

  const addUpload = () => {
    setListShowImage(function (uploadImages) {
      return [...uploadImages, ''];
    });
  };

  const deleteImage = (index) => {
    let _listImage = [...listImage];
    _listImage = [..._listImage.slice(0, index), ..._listImage.slice(index + 1)];

    setListShowImage(function () {
      return [...listShowImage.slice(0, index), ...listShowImage.slice(index + 1)];
    });

    setListImage(function () {
      return [...listImage.slice(0, index), ...listImage.slice(index + 1)];
    });
    onChangeImage(_listImage);
  };

  const onUploadImg = (event, index) => {
    setEditImage(true);

    let _listImage = [...listImage];
    _listImage[index] = event.target.files[0];

    setListShowImage(function (uploadImages) {
      if (event.target.files && event.target.files[0]) {
        uploadImages[index] = URL.createObjectURL(event.target.files[0]);
        return [...uploadImages];
      }
    });

    setListImage(function (uploadImages) {
      if (event.target.files && event.target.files[0]) {
        uploadImages[index] = event.target.files[0];
        return [...uploadImages];
      }
    });

    if (_numberMax === 1) {
      if (listImage.length) {
        onChangeImage(_listImage[0]);
      }
    } else {
      onChangeImage(_listImage);
    }
  };

  return (
    <div className={styles.boxImage}>
      {listShowImage &&
        listShowImage.map((item, index) => (
          <div className={styles.boxItemImage} key={index}>
            <div className={styles.uploadImages}>
              {_numberMax !== 1 && (
                <p className={styles.deleteImage} aria-hidden="true" onClick={() => deleteImage(index)}>
                  ×
                </p>
              )}

              <input
                id={'image-' + index + name}
                type="file"
                name="images[]"
                style={{ display: 'none' }}
                accept=".jpg,.jpeg,.png,.gif,.svg"
                onClick={(event) => event.target.value = null}
                onChange={(event) => onUploadImg(event, index)}
              />

              <label htmlFor={'image-' + index + name} className={styles.buttonFile}>
                {item && <img onError={handleOnError} src={item} alt={item} className={styles.image} />}
                {!item && <img src={Camera} alt="camera" width={30} height="30px" />}
              </label>
            </div>
            {_numberMax !== 1 && (
              <p className={styles.sttImage}>
                {t('common._image')} {index + 1}
              </p>
            )}
          </div>
        ))}
      {_numberMax !== 1 && listShowImage.length < _numberMax && (
        <div className={styles.boxItemImage}>
          <div className={styles.uploadImages}>
            <label
              htmlFor={'add' + name}
              className={classNames(`${styles.buttonFile} ${styles.btnAdd}`)}
              onClick={() => addUpload()}
            >
              <IoIosAdd />
            </label>
          </div>
          <p className={styles.sttImage}>{t('common.addImage')}</p>
        </div>
      )}
    </div>
  );
};

export default UploadImages;
