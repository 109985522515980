export const USE_DIAGRAM_DEPARTMENT = 'diagram_department';
export const USE_DETAIL_DEPARTMENT = 'detail_department';
export const USE_DETAIL_MEMBER = 'USE_DETAIL_MEMBER';
export const USE_BRANCH_DEPARTMENT = 'branch_department';
export const USE_DIVISION_TEAM = 'division_team_department';
export const USE_MEMBER_TEAM = 'use_member_team';
export const USE_DETERMINE_ROLE_NAME = 'role_name_manager_division';
export const USE_PROJECTS_DIVISION = 'use_projects_division';
export const USE_INFO_DIVISION = 'info_detail-division';
export const USE_OTHER_MEMBER = 'USE_OTHER_MEMBER';

export const STATUS_OPEN_PROJECT = 1;
export const STATUS_CLOSE_PROJECT = 2;
export const STATUS_PROCESS_PROJECT = 3;
export const STATUS_CANCEL_PROJECT = 4;
export const STATUS_POSPONE_PROJECT = 5;
export const STATUS_OPP_PROJECT = 6;
export const STATUS_DAFT_PROJECT = 7;
export const STATUS_PENDING_PROJECT = 8;

export const TYPE_DEVELOPMENT_PROJECT = 1;
export const TYPE_MAINTENANCE_PROJECT = 2;
