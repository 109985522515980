import { Col, Row, Form } from 'reactstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_INTERNAL_SERVER_ERROR } from '../../../../../constants/StatusCode.js';

import ButtonComponent from 'components/Buttons';
import AlertComponent from 'components/Alert';
// import UploadImages from './UploadImages';

import { apiCreatePaper, apiUpdatePaper } from 'api/profileEmployee';
import * as Constants from '../../../../../constants/ProfileEmployee.js';

const DocumentCommon = (props) => {
  const queryClient = useQueryClient();
  // eslint-disable-next-line
  const { imgOld, dataDocumentCommon, type, isModal, setIsChange, isChange } = props;
  const [stateButton, setStateButton] = useState(false);

  const { t } = useTranslation();

  const DATA_POPUP_FORM = {
    type: type,
    img_deleted: '',
    error_image: '',
    error_max_size: '',
    error: '',
  };

  const [formPaper, setFormPaper] = useState(DATA_POPUP_FORM);

  // eslint-disable-next-line
  const handleImageChange = (fileImage) => {
    if (!isChange) {
      setIsChange(true);
    }
    setFormPaper({ ...formPaper, images: fileImage, error_image: '' });
  };

  const handleSubmitCCCD = async (event) => {
    if (!isChange) {
      setIsChange(true);
    }
    event.preventDefault();
    setStateButton(true);
    if (!formPaper?.images && !imgOld) {
      setFormPaper({ ...formPaper, error_image: t('messages.pleaseUploadImages') });
      setStateButton(false);
      return;
    }

    const fb = new FormData();
    fb.append('user_id', dataDocumentCommon.userId);
    fb.append('type', formPaper.type);
    for (var i = 0; i < formPaper?.images?.length; i++) {
      if (typeof formPaper?.images[i] === 'object') {
        fb.append('image[]', formPaper?.images[i]);
      }
    }
    var imgDeleted = [];

    if (imgOld && formPaper?.images) {
      for (let j = 0; j < imgOld.length; j++) {
        var exist = false;
        for (let x = 0; x < formPaper?.images.length; x++) {
          if (typeof formPaper?.images[x] !== 'object' && formPaper?.images[x] === imgOld[j]) {
            exist = true;
            break;
          }
        }
        if (!exist) {
          imgDeleted.push(imgOld[j]);
        }
      }
    }
    fb.append('img_deleted', imgDeleted.join(','));
    let response = {};

    if (!imgOld) {
      response = await apiCreatePaper(fb);
    } else {
      response = await apiUpdatePaper(fb);
    }

    if (response?.data?.status === HTTP_INTERNAL_SERVER_ERROR) {
      dataDocumentCommon.setMessage(response?.data?.messages);
      dataDocumentCommon.setShowAlert(true);
      setStateButton(false);
      return;
    }

    if (
      response?.data?.status_code === HTTP_UNPROCESSABLE_ENTITY ||
      response?.data?.status === HTTP_UNPROCESSABLE_ENTITY
    ) {
      const msg = response.data.messages;
      setFormPaper({
        ...DATA_POPUP_FORM,
        error_image: msg?.image
          ? msg?.image
              ?.map((item) => {
                return item;
              })
              .join('\n')
          : msg,
      });
      setStateButton(false);
      return;
    }

    if (response?.data?.status === HTTP_OK) {
      dataDocumentCommon.setMessage(response.data.messages);
      dataDocumentCommon.setShowAlert(true);
      setStateButton(false);
      setIsChange(false);
      await queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
    }
    setStateButton(false);
  };

  return (
    <>
      <AlertComponent
        toggle={() => dataDocumentCommon.setShowAlert(!dataDocumentCommon.isShowAlert)}
        isShowAlert={dataDocumentCommon.isShowAlert}
      >
        {dataDocumentCommon.message}
      </AlertComponent>
      <Form className="mt-3" onSubmit={handleSubmitCCCD}>
        <Row className="head-modal">
          <Col sm="10">
            <p className="notification">{t('document.notification')}</p>
          </Col>
          <Col sm="2" className="button-save">
            <ButtonComponent text={t('common.save')} addClass="w-100" type="submit" hasDisabled={stateButton} />
          </Col>
        </Row>
        <Row>
          <p className="title-img-cccd">
            {type === Constants.TYPE_CURRICULUM_VITAE ? t('document.imgCurriculumVitae') : ''}
            {type === Constants.TYPE_HEALTH_CERTIFICATE ? t('document.healthCertification') : ''}
            {type === Constants.TYPE_DEGREE ? t('document.degreePhoto') : ''}
            {type === Constants.TYPE_BIRTH_CERTIFICATE ? t('document.birthCertificate') : ''}
            {type === Constants.TYPE_REGISTRATION_BOOK ? t('document.registrationBook') : ''}
            {type === Constants.TYPE_PASSPORT ? t('document.passport') : ''}
          </p>
        </Row>
        <Row>
          <Col sm="12" className="img-cccd">
            {/*<UploadImages*/}
            {/*  name="document"*/}
            {/*  isModal={isModal}*/}
            {/*  dataImage={imgOld ? imgOld : []}*/}
            {/*  onChangeImage={handleImageChange}*/}
            {/*/>*/}
          </Col>
        </Row>
        <p className="error">{formPaper.error_image}</p>
        <p className="error">{formPaper.error_max_size}</p>
      </Form>
    </>
  );
};

export default DocumentCommon;
