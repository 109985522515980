export const STATUS_ASSET_USING = 1;
export const STATUS_ASSET_CONFISCATION = 2;

export const USE_USER_LIST_ASSETS = 'me_user_list_assets';
export const USE_USER_COUNT_ASSETS = 'me_user_count_assets';
export const USE_REQUEST_FIFTH = 'me_request_fifth';

export const ASSETS_LIST = 0;
export const REQUEST_LIST = 1;
export const MAX_LENGTH_CONTENT = 20;
export const START_STRING_SLICE = 0;
