import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import styles from './styles.module.scss';
import styleSelect from 'components/Select/style.module.scss';
import ModalComponent from 'components/Modal';
import { MAX_LENGTH_255 } from 'constants/index.js';
import CustomCheckboxYup from 'components/InputYupAndMui/CustomCheckboxYup';
import ButtonComponent from 'components/Buttons';
import { useGetListUserAccept, useGetListCheckbox } from 'hook/useTimeSheet';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import DateDay from 'components/ReactForm/DateDay';
import { apiCreateRequest } from 'api/timesheet';
import { HTTP_OK } from 'constants/StatusCode';
import { USE_TIME_SHEET, USE_TOTAL_TIME_SHEET } from 'constants/TimeSheet';
import AlertComponent from 'components/Alert';
import { YupValidate } from './yupValidate';
import configs from 'config/index.js';
import { formatDatePiker } from 'helpers/formatDate';
import { MAX_LENGTH_30 } from 'constants/index.js';
import { handleOnError } from 'helpers';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';

const OnLeave = ({ isShowModal, onSetShowModal, dataSendRequest }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(YupValidate()),
  });
  const queryClient = useQueryClient();
  const [isShowAlert, setShowAlert] = useState(false);
  const [isShowAlertError, setShowAlertError] = useState(false);
  const [message, setMessage] = useState();
  const [disable, setDisable] = useState(false);
  const [filter, setFiter] = useState({ key_word: '', page: 1 });
  const [dataUser, setDataUser] = useState([]);
  const [valueSelect, setValueSelect] = useState("");
  const { data: listUserAccept } = useGetListUserAccept(configs.ID_ON_LEAVE, filter);

  const { data: listCheckbox } = useGetListCheckbox({
    workflow_id: configs.ID_ON_LEAVE,
    param_name: 'hr_shift_log',
  });

  const onSubmit = async (data) => {
    setDisable(true);

    const fb = new FormData();
    fb.append('work_name', data.work_name.trim());
    fb.append('hr_reason', data.content.trim());
    data.next_person.forEach((item) => {
      fb.append('next_person[]', item);
    });
    fb.append('hr_date_log[]', formatDatePiker(data.hr_date_log, 'YYYY-MM-DD'));
    fb.append('hr_shift_log[0][]', data.hr_shift_log_one ? listCheckbox?.checkbox?.[0]?.id : null);
    fb.append('hr_shift_log[0][]', data.hr_shift_log_two ? listCheckbox?.checkbox?.[1]?.id : null);
    fb.append('hr_shift_log[0][]', data.hr_shift_log_three ? listCheckbox?.checkbox?.[2]?.id : null);
    fb.append('hr_shift_log[0][]', data.hr_shift_log_four ? listCheckbox?.checkbox?.[3]?.id : null);
    fb.append('submit', true);
    fb.append('workflow_id', configs.ID_ON_LEAVE);

    let response = await apiCreateRequest(fb, configs.ID_ON_LEAVE);
    if (response?.status === HTTP_OK) {
      setMessage(t('timekeeping.sendRequestSuccessfully'));
      setShowAlert(true);
      queryClient.invalidateQueries(USE_TIME_SHEET);
      queryClient.invalidateQueries(USE_TOTAL_TIME_SHEET);
      clearErrors();
      setDisable(false);
      return;
    }

    if (response?.status === 400 || !response?.success) {
      setMessage(response?.data?.message?.[0]);
      setShowAlertError(true);
      clearErrors();
      setDisable(false);
      return;
    }
    setDisable(false);
  };

  const watchShiftLogOne = useWatch({
    control,
    name: 'hr_shift_log_one',
  });

  const watchShiftLogTwo = useWatch({
    control,
    name: 'hr_shift_log_two',
  });

  const watchShiftLogThree = useWatch({
    control,
    name: 'hr_shift_log_three',
  });

  const watchShiftLogFour = useWatch({
    control,
    name: 'hr_shift_log_four',
  });

  useEffect(() => {
    if (!watchShiftLogOne && !watchShiftLogTwo && !watchShiftLogThree && !watchShiftLogFour) {
      setError('hr_shift_log', true);
      setValue('hr_shift_log', '');
    } else {
      setError('hr_shift_log', '');
      setValue('hr_shift_log', true);
    }
    // eslint-disable-next-line
  }, [watchShiftLogOne, watchShiftLogTwo, watchShiftLogThree, watchShiftLogFour]);

  useEffect(() => {
    if (Number(listUserAccept?.data?.last_page) > Number(filter.page)) {
      setFiter({ ...filter, page: filter.page + 1 });
    }
    if (listUserAccept?.data?.data) {
      if (filter.page !== 1) {
        setDataUser([...dataUser, ...listUserAccept?.data?.data]);
      } else {
        setDataUser([...listUserAccept?.data?.data]);
      }
    }
    // eslint-disable-next-line
  }, [listUserAccept]);

  return (
    <>
      <ModalComponent
        classNameAdd={styles.onLeave}
        toggle={onSetShowModal}
        isShowModal={isShowModal}
        title={t('timekeeping.applyForLeave')}
      >
        <p className={styles.remainingHours}>
          {t('timekeeping.remainingLeaveHours')}: {dataSendRequest?.hour_off ?? 0} {' ' + t('common.hour')}
        </p>
        <form className={styles.modalCreateContract} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={styles.inputText}>
              <CustomInputYup
                label={t('timekeeping.request.nameRequest')}
                name="work_name"
                errors={errors}
                control={control}
                register={register}
                useWatch={useWatch}
                setValue={setValue}
                maxLength={MAX_LENGTH_255}
              />
            </Grid>
            <Grid item xs={12} className={classNames(styleSelect.selectComponent, styleSelect.selectComponentAuto)}>
              {dataUser && (
                <Controller
                  control={control}
                  defaultValue={null}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <Autocomplete
                      multiple={true}
                      noOptionsText={t('common.notData')}
                      disableClearable
                      filterSelectedOptions
                      autoHighlight
                      getOptionDisabled={(option) => option.disabled}
                      getOptionLabel={(option) => {
                        const filterData = dataUser.filter(e => e.email.includes(valueSelect));
                        if(filterData.length > 0){
                          return option.email
                        }
                        return option.name
                      }}
                      onInputChange={(event, value) => {
                        setValueSelect(value);
                      }}
                      onChange={(event, value) => {
                        setValue(
                          'next_person',
                          value.map((item) => item.id),
                        );
                        field.onChange(value);
                        setError('next_person', '');
                      }}
                      options={dataUser}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                          <div>
                            <img
                              onError={handleOnError}
                              className="avatar"
                              src={option.avatar || defaultAvatar}
                              alt=""
                            />
                          </div>
                          <div>
                            <p>{option.name}</p>
                            <p>{option.email} - {option.position_name}</p>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          error={errors?.next_person?.message}
                          helperText={error?.next_person?.message}
                          label={t('timekeeping.request.selectUserAccept')}
                          name="next_person"
                          type="search"
                          inputRef={ref}
                          {...params}
                          maxLength={MAX_LENGTH_30}
                        />
                      )}
                    />
                  )}
                />
              )}
              {errors?.next_person?.message && <p className={styles.error}>{errors.next_person.message}</p>}
            </Grid>
            <Grid item xs={12}>
              <DateDay
                label={t('timekeeping.request.dateApply')}
                name="hr_date_log"
                error={errors?.hr_date_log?.message}
                control={control}
                setValue={setValue}
                register={register}
                defaultValue={dataSendRequest?.date}
                isReadOnly
              />
            </Grid>
            <Grid item xs={12}>
              <p className={styles.title}>{t('timekeeping.offShift')}</p>
            </Grid>
            <Grid item xs={4} className={styles.checkbox}>
              <CustomCheckboxYup
                control={control}
                register={register}
                setValue={setValue}
                name={'hr_shift_log_one'}
                label={'8:00 - 10:00'}
              />
            </Grid>
            <Grid item xs={8} className={styles.checkbox}>
              <CustomCheckboxYup
                control={control}
                register={register}
                setValue={setValue}
                name={'hr_shift_log_two'}
                label={'10:00 - 12:00'}
              />
            </Grid>
            <Grid item xs={4} className={styles.checkbox}>
              <CustomCheckboxYup
                control={control}
                register={register}
                setValue={setValue}
                name={'hr_shift_log_three'}
                label={'13:30 - 15:30'}
              />
            </Grid>
            <Grid item xs={8} className={styles.checkbox}>
              <CustomCheckboxYup
                control={control}
                register={register}
                setValue={setValue}
                name={'hr_shift_log_four'}
                label={'15:30 - 17:30'}
              />
            </Grid>
            {errors?.hr_shift_log?.message && (
              <Grid xs={4}>
                <p className="error">{t('messages.pleaseSelectLeaveShift')}</p>
              </Grid>
            )}
            <Grid item xs={12} className={styles.inputText}>
              <CustomInputYup
                label={t('timekeeping.request.reason')}
                name="content"
                errors={errors}
                control={control}
                register={register}
                useWatch={useWatch}
                setValue={setValue}
                maxLength={MAX_LENGTH_255}
              />
            </Grid>
            <div className={styles.footerModal}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={t('common.cancelEN')}
                    addClass="w-100 btn-bg-yellow2"
                    handleClick={onSetShowModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={t('common.add')}
                    addClass="w-100"
                    type="submit"
                    handleClick={handleSubmit(onSubmit)}
                    hasDisabled={disable}
                    isLoading={disable}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </form>
      </ModalComponent>
      <AlertComponent
        toggle={() => {
          setShowAlert(!isShowAlert);
          onSetShowModal();
        }}
        isShowAlert={isShowAlert}
      >
        {message}
      </AlertComponent>
      <AlertComponent
        toggle={() => {
          setShowAlertError(!isShowAlertError);
        }}
        isShowAlert={isShowAlertError}
      >
        {message}
      </AlertComponent>
    </>
  );
};

export default OnLeave;
