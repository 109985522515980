import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import styles from './styles.module.scss';
import styleSelect from 'components/Select/style.module.scss';
import ModalComponent from 'components/Modal';
import { MAX_LENGTH_255 } from 'constants/index.js';
import ButtonComponent from 'components/Buttons';
import { useGetListUserAccept, useGetProject } from 'hook/useTimeSheet';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import DateDay from 'components/ReactForm/DateDay';
import { customDate, formatDatePiker } from 'helpers/formatDate';
import { apiCreateRequest } from 'api/timesheet';
import { HTTP_OK } from 'constants/StatusCode';
import { USE_TIME_SHEET, USE_TOTAL_TIME_SHEET } from 'constants/TimeSheet';
import AlertComponent from 'components/Alert';
import { YupValidate } from './yupValidate';
import configs from 'config/index.js';
import { MAX_LENGTH_30 } from 'constants/index.js';
import { handleOnError } from 'helpers';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import TimePickerCustom from 'components/DateTime/TimePickerCustom';

const RegisterOtWeekdays = ({ isShowModal, onSetShowModal, dataSendRequest }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(YupValidate()),
  });
  const queryClient = useQueryClient();
  const [isShowAlert, setShowAlert] = useState(false);
  const [isShowAlertError, setShowAlertError] = useState(false);
  const [message, setMessage] = useState();
  const [disable, setDisable] = useState(false);
  const [filter, setFiter] = useState({ key_word: '', page: 1 });
  const [dataUser, setDataUser] = useState([]);
  const [valueSelect, setValueSelect] = useState("");
  const { data: listUserAccept } = useGetListUserAccept(configs.ID_REGISTER_OT_WEEKDAYS, filter);

  useEffect(() => {
    if (Number(listUserAccept?.data?.last_page) > Number(filter.page)) {
      setFiter({ ...filter, page: filter.page + 1 });
    }
    if (listUserAccept?.data?.data) {
      if (filter.page !== 1) {
        setDataUser([...dataUser, ...listUserAccept?.data?.data]);
      } else {
        setDataUser([...listUserAccept?.data?.data]);
      }
    }
    // eslint-disable-next-line
  }, [listUserAccept]);

  const { data: listProject } = useGetProject();

  const onSubmit = async (data) => {
    // eslint-disable-next-line
    if (!data.hr_date_log || data.hr_date_log == 'Invalid Date') {
      setError('hr_date_log', t('messages.pleaseEnterDateAgain'));
      return;
    } else {
      setError('hr_date_log', '');
    }
    setDisable(true);
    const fb = new FormData();
    fb.append('work_name', data.work_name.trim());
    data.next_person.forEach((item) => {
      fb.append('next_person[]', item);
    });
    fb.append('project_id', data.project_id);
    fb.append('hr_date_log[]', formatDatePiker(data.hr_date_log, 'YYYY-MM-DD'));
    fb.append('hr_start_time_log[]', customDate(data.hr_start_time_log, 'HH:mm:ss'));
    fb.append('hr_end_time_log[]', customDate(data.hr_end_time_log, 'HH:mm:ss'));
    fb.append('submit', true);
    fb.append('workflow_id', configs.ID_REGISTER_OT_WEEKDAYS);

    let response = await apiCreateRequest(fb, configs.ID_REGISTER_OT_WEEKDAYS);

    if (response?.status === HTTP_OK) {
      setMessage(t('timekeeping.sendRequestSuccessfully'));
      setShowAlert(true);
      queryClient.invalidateQueries(USE_TIME_SHEET);
      queryClient.invalidateQueries(USE_TOTAL_TIME_SHEET);
      clearErrors();
      setDisable(false);
      return;
    }

    if (response?.status === 400 || !response?.success) {
      setMessage(response?.data?.message?.[0]);
      setShowAlertError(true);
      clearErrors();
      setDisable(false);
      return;
    }
    setDisable(false);
  };

  const watchStartTime = useWatch({
    control,
    name: 'hr_start_time_log',
  });

  const watchEndTime = useWatch({
    control,
    name: 'hr_end_time_log',
  });

  useEffect(() => {
    if(watchStartTime && watchEndTime && moment(watchStartTime).isValid()){
      const month = watchStartTime.month();
      const date = watchStartTime.date();
      watchEndTime.set("month" , month);
      watchEndTime.set("date" , date);
      if (watchEndTime && watchStartTime && watchEndTime.isBefore(watchStartTime)) {
        setError('hr_end_time_log', {
          type: 'required',
          message: t('messages.pleaseChooseTheEndTimeBiggerThanTheStartTime'),
        });
      }else{
        setError('hr_end_time_log', "");
      }
    }else{
      if (watchEndTime && watchStartTime && watchEndTime.isBefore(watchStartTime)) {
        setError('hr_end_time_log', {
          type: 'required',
          message: t('messages.pleaseChooseTheEndTimeBiggerThanTheStartTime'),
        });
      }
    }
    // eslint-disable-next-line
  }, [watchStartTime, watchEndTime]);

  return (
    <>
      (
      <ModalComponent
        classNameAdd={styles.onLeave}
        toggle={onSetShowModal}
        isShowModal={isShowModal}
        title={t('timekeeping.signUpForOtOnWeekdaysModal')}
      >
        <form className={styles.modalCreateContract} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={styles.inputText}>
              <CustomInputYup
                label={t('timekeeping.request.nameRequest')}
                name="work_name"
                errors={errors}
                control={control}
                register={register}
                useWatch={useWatch}
                setValue={setValue}
                maxLength={MAX_LENGTH_255}
              />
            </Grid>
            <Grid item xs={12} className={classNames(styleSelect.selectComponent, styleSelect.selectComponentAuto)}>
              {listProject?.projects && (
                <Controller
                  control={control}
                  defaultValue={null}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <Autocomplete
                      noOptionsText={t('common.notData')}
                      disableClearable
                      filterSelectedOptions
                      autoHighlight
                      getOptionDisabled={(option) => option.disabled}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        setValue('project_id', value.id);
                        field.onChange(value);
                        setError('project_id', '');
                      }}
                      options={listProject?.projects}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                          <div className={styles.infoUserRequestInput}>
                            <p>{option.name}</p>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          error={errors?.project_id?.message}
                          helperText={error?.project_id?.message}
                          label={t('boxInfoEmployee.dashboard.project')}
                          name="project_id"
                          type="search"
                          inputRef={ref}
                          {...params}
                          maxLength={MAX_LENGTH_30}
                        />
                      )}
                    />
                  )}
                />
              )}
              {errors?.project_id?.message && <p className={styles.error}>{errors.project_id.message}</p>}
            </Grid>
            <Grid item xs={12} className={classNames(styleSelect.selectComponent, styleSelect.selectComponentAuto)}>
              {dataUser && (
                <Controller
                  control={control}
                  defaultValue={null}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <Autocomplete
                      multiple={true}
                      noOptionsText={t('common.notData')}
                      disableClearable
                      filterSelectedOptions
                      autoHighlight
                      getOptionDisabled={(option) => option.disabled}
                      getOptionLabel={(option) => {
                        const filterData = dataUser.filter(e => e.email.includes(valueSelect));
                        if(filterData.length > 0){
                          return option.email
                        }
                        return option.name
                      }}
                      onInputChange={(event, value) => {
                        setValueSelect(value);
                      }}
                      onChange={(event, value) => {
                        setValue(
                          'next_person',
                          value.map((item) => item.id),
                        );
                        field.onChange(value);
                        setError('next_person', '');
                      }}
                      options={dataUser}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                          <div>
                            <img
                              onError={handleOnError}
                              className="avatar"
                              src={option.avatar || defaultAvatar}
                              alt=""
                            />
                          </div>
                          <div>
                            <p>{option.name}</p>
                            <p>{option.email} - {option.position_name}</p>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          error={errors?.next_person?.message}
                          helperText={error?.next_person?.message}
                          label={t('timekeeping.request.selectUserAccept')}
                          name="next_person"
                          type="search"
                          inputRef={ref}
                          {...params}
                          maxLength={MAX_LENGTH_30}
                        />
                      )}
                    />
                  )}
                />
              )}
              {errors?.next_person?.message && <p className={styles.error}>{errors.next_person.message}</p>}
            </Grid>
            <Grid item xs={12}>
              <DateDay
                label={t('timekeeping.request.dateApply')}
                name="hr_date_log"
                error={errors?.hr_date_log?.message}
                control={control}
                setValue={setValue}
                register={register}
                defaultValue={dataSendRequest?.date}
                isReadOnly
              />
            </Grid>
            <Grid item xs={6}>
              <p className={styles.title}>{t('timekeeping.timeStart')}</p>
              <TimePickerCustom 
                placeholder="hh:mm"
                name={'hr_start_time_log'}
                value={watchStartTime}
                format="HH:mm"
                setValue={setValue}
                setError={setError}
                handleChangeTime={(time) => {
                  setValue('hr_start_time_log', moment(time, 'HH:mm').isValid() ?  moment(time, 'HH:mm') : null);
                }}
                error={errors?.hr_start_time_log?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <p className={styles.title}>{t('timekeeping.timeEnd')}</p>
              <TimePickerCustom 
                placeholder="hh:mm"
                name={'hr_end_time_log'}
                value={watchEndTime}
                format="HH:mm"
                setValue={setValue}
                setError={setError}
                handleChangeTime={(time) => {
                  setValue('hr_end_time_log', moment(time, 'HH:mm').isValid() ?  moment(time, 'HH:mm') : null)
                }}
                error={errors?.hr_end_time_log?.message}
              />
            </Grid>
            <div className={styles.footerModal}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={t('common.cancelEN')}
                    addClass="w-100 btn-bg-yellow2"
                    handleClick={onSetShowModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={t('common.add')}
                    addClass="w-100"
                    type="submit"
                    handleClick={handleSubmit(onSubmit)}
                    hasDisabled={disable}
                    isLoading={disable}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </form>
      </ModalComponent>
      <AlertComponent
        toggle={() => {
          setShowAlert(!isShowAlert);
          onSetShowModal();
        }}
        isShowAlert={isShowAlert}
      >
        {message}
      </AlertComponent>
      <AlertComponent
        toggle={() => {
          setShowAlertError(!isShowAlertError);
        }}
        isShowAlert={isShowAlertError}
      >
        {message}
      </AlertComponent>
    </>
  );
};

export default RegisterOtWeekdays;
