import { sendGet } from './../helpers/axios';

export const getCompanyEmployees = (params) => sendGet('/api/member-company', params);

export const getCountDivisionMember = (params) => sendGet('/api/division-count-member', params);

export const getCountBranchMember = (params) => sendGet('/api/branch-count-member', params);

export const getDepartmentDiagram = (params) => sendGet('/api/departmental', params);

export const getSkillDiagram = (params) => sendGet('/api/diagram-skill', params);
