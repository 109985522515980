import Axios from 'axios';
import config from './../config';
// import { history } from "../Root";
import storageFC from './storage';
import { HTTP_UNAUTHORIZED, HTTP_NOT_ACCESS } from 'constants/StatusCode';

const axiosInstance = Axios.create({
  baseURL: config.API_DOMAIN_SSO,
  // headers: {
  //   "X-Requested-With": "XMLHttpRequest",
  // },
});

// const logout = () => {
//   storageFC.clearToken();
//   history.push("/");
// };

axiosInstance.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${storageFC.getToken()}`;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === HTTP_UNAUTHORIZED) {
      storageFC.setToken('');
      window.location.href = config.API_DOMAIN_LOGIN;
    }
    if (error?.response?.status === HTTP_NOT_ACCESS && !error?.response?.data?.is_edit) {
      window.location.href = config.DOMAIN_APP_DOMAIN_AMS_ME + '/page-not-access';
    }
    return error.response;
  },
);

export const sendGet = (url = '', params) => axiosInstance.get(url, { params }).then((res) => res);
export const sendPost = (url = '', params, queryParams) =>
  axiosInstance.post(url, params, { params: queryParams }).then((res) => res);
export const sendPut = (url = '', params) => axiosInstance.put(url, params).then((res) => res);
export const sendPatch = (url = '', params) => axiosInstance.patch(url, params).then((res) => res);
export const sendDelete = (url = '', params) => axiosInstance.delete(url, { data: params }).then((res) => res);
export const sendCustom = (params = {}) => axiosInstance(params).then((res) => res);

const axiosInstanceHR = Axios.create({
  baseURL: config.API_DOMAIN_HR,
  timeout: 60000,
});

axiosInstanceHR.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${storageFC.getToken()}`;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstanceHR.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === HTTP_UNAUTHORIZED) {
      storageFC.setToken('');
      window.location.href = config.API_DOMAIN_LOGIN;
    }
    if (error?.response?.status === HTTP_NOT_ACCESS && !error?.response?.data?.is_edit) {
      window.location.href = config.DOMAIN_APP_DOMAIN_AMS_ME + '/page-not-access';
    }
    return error.response;
  },
);

export const sendGetHR = (url = '', params) => axiosInstanceHR.get(url, { params }).then((res) => res);
export const sendPostHR = (url = '', params, queryParams) =>
  axiosInstanceHR.post(url, params, { params: queryParams }).then((res) => res);
export const sendPutHR = (url = '', params) => axiosInstanceHR.put(url, params).then((res) => res);
export const sendPatchHR = (url = '', params) => axiosInstanceHR.patch(url, params).then((res) => res);
export const sendDeleteHR = (url = '', params) => axiosInstanceHR.delete(url, { data: params }).then((res) => res);
export const sendCustomHR = (params = {}) => axiosInstance(params).then((res) => res);
