import configs from 'config';
import { TYPE_LINK_ME } from 'constants/notification';
import { TYPE_LINK, TAB_GROUP, TYPE_LINK_RECRUIT } from 'constants/notification';

const getIdCommentParent = (item) => {
  if (!item?.comment) {
    return;
  }
  const postComment = JSON.parse(item.comment);
  return postComment?.grand_id || postComment?.parent_id || postComment?.id;
};

const getIdScrollTo = (item) => {
  if (!item?.comment) {
    return;
  }
  const postComment = JSON.parse(item.comment);
  return postComment?.id || postComment?.parent_id || postComment?.grand_id;
};

export const getUrlNotificationToMe = (type, notification) => {
  const detail = notification?.detail;

  const ME_URL = configs.DOMAIN_APP_DOMAIN_AMS_ME;
  if (type === TYPE_LINK_ME.TIME_SHEET) {
    return '/timekeeping/time-sheets';
  } else if (type === TYPE_LINK_ME.DAILY_REPORT) {
    let detailPath = '';
    if (detail?.length) {
      detailPath = JSON.parse(detail);
      if (detailPath.path.length) return detailPath.path;
    }
  }

  return ME_URL;
};

export const getUrlNotificationToWiki = (type, notification) => {
  const WIKI_URL = configs.DOMAIN_AMS_WIKI;
  if (type === TYPE_LINK.DETAIL_POST || type === TYPE_LINK.DETAIL_REPORT) {
    if (notification?.group_id) {
      return WIKI_URL + `/group/detail-group/${notification.group_id}/${notification.post_id}`;
    }
    return WIKI_URL + `/home/detail-post/${notification.post_id}`;
  }
  if (type === TYPE_LINK.DETAIL_COMMENT) {
    if (notification?.group_id) {
      return (
        WIKI_URL +
        `/group/detail-group/${notification.group_id}/${
          notification.post_id
        }?idNotice=${getIdCommentParent(notification)}&idScroll=${getIdScrollTo(notification)}`
      );
    }
    return WIKI_URL + `/home/detail-post/${notification.post_id}?idNotice=${getIdCommentParent(notification)}&idScroll=${getIdScrollTo(notification)}`;
  }
  if (type === TYPE_LINK.DETAIL_POST_GROUP) {
    return WIKI_URL + `/group/classification/${notification?.group_id}`;
  }
  if (type === TYPE_LINK.SERIES) {
    if (notification?.group_id) {
      return WIKI_URL + `/group/list-series/${notification?.group_id}/${notification?.series_id}`;
    }
    return WIKI_URL + `/home/list-series/${notification?.series_id}`;
  }
  if (type === TYPE_LINK.GROUP_APPROVE_MEMBER) {
    if (notification?.group_id) {
      return WIKI_URL + `/group/user-manage-group/${notification.group_id}?tab=${TAB_GROUP.REQUEST_JOIN}`;
    }
  }
  if (type === TYPE_LINK.GROUP_APPROVE_POST) {
    if (notification?.group_id) {
      return WIKI_URL + `/group/user-manage-group/${notification.group_id}?tab=${TAB_GROUP.LIST_WAITING}`;
    }
  }
  if (type === TYPE_LINK.GROUP_LIST_REPORT) {
    if (notification?.group_id) {
      return WIKI_URL + `/group/user-manage-group/${notification.group_id}?tab=${TAB_GROUP.LIST_REPORT}`;
    }
  }
};

export const getUrlNotificationToRecruit = (type, notification) => {
  let detail = JSON.parse(notification?.detail);
  if (!detail) detail = {};
  const { interview_date, interview_id, job_id, order_id, candidate_id, apply_id, kpi_id } = detail;
  switch (type) {
    case TYPE_LINK_RECRUIT.DETAIL_APPLY_INTERVIEW_LEADER:
      return `/admin/candidate-detail/${candidate_id}/apply-detail/${apply_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_INTERVIEW_SCHEDULE:
      return `/admin/interview-schedule/${interview_date}/${interview_id}`;
    case TYPE_LINK_RECRUIT.LIST_INTERVIEW_SCHEDULE:
      return `/admin/interview-schedule`;
    case TYPE_LINK_RECRUIT.DETAIL_ORDER_LEADER:
      return `/admin/manager-order/${order_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_JOB:
      return `/admin/manager-job/${job_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_ORDER_HR:
      return `/admin/manager-order/${order_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_APPLY_INTERVIEW_HR:
      return `/admin/candidate-detail/${candidate_id}/apply-detail/${apply_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_APPLY_TEST_HR:
      return `/admin/candidate-detail/${candidate_id}/apply-detail/${apply_id}/test`;
    case TYPE_LINK_RECRUIT.LIST_KPI:
      return `/admin/manager-kpi`;
    case TYPE_LINK_RECRUIT.DETAIL_KPI:
      return `/admin/manager-kpi/${kpi_id}`;
    case TYPE_LINK_RECRUIT.LIST_REF_BONUS:
      return `/admin/list-referral-bonus`;
    case TYPE_LINK_RECRUIT.LIST_COST_RECRUIT:
      return `/admin/list-cost-recruit`;
    case TYPE_LINK_RECRUIT.DETAIL_CANDIDATE_HR:
      return `/admin/candidate-detail/${candidate_id}`;
    default:
      return '';
  }
};

export const getUrlNotificationToProject = (type, notification) => {
  const detail = notification?.detail;
  if (detail.length) {
    const detailPath = JSON.parse(detail);
    if (detailPath.path.length) return detailPath.path;
  }

  return `/projects/detail/${notification?.project_id}`;
};
