import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import useResize from 'helpers/useResize';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { CssDateField } from './constant';

function CustomInputDate({
  label,
  defaultValue,
  name,
  placeholder,
  control,
  register,
  useWatch,
  errors,
  setValue,
  disabled,
  min,
  max,
  handleChange,
}) {
  const [value, setValueTime] = useState(defaultValue);

  // Watch value when typing --> change classnames
  useEffect(() => {
    if (disabled) {
      setValueTime(null);
      setValue(name, null);
    } else {
      setValueTime(defaultValue);
      setValue(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  // Watch value of input date
  const valueWatch = useWatch({
    control,
    name: name,
    defaultValue: defaultValue,
  });

  // Responsive
  const viewPort = useResize();
  const isMobile = viewPort.width <= 1364;

  return (
    <>
      <CssDateField fixplacetext={isMobile} labelspace={valueWatch ? 0 : '-1px'}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            render={({ field }) => (
              <DesktopDatePicker
                {...register(name)}
                label={<React.Fragment>{placeholder && !value ? placeholder : label}</React.Fragment>}
                value={valueWatch}
                onChange={(newValue) => {
                  setValueTime(newValue);
                  setValue(name, newValue);
                  if (handleChange) {
                    handleChange(newValue);
                  }
                }}
                renderInput={(params) => <TextField {...params} {...register(name)} />}
                name={name}
                inputFormat="dd/MM/yyyy"
                error={errors[`${name}`] ? true : false}
                disabled={disabled}
                minDate={
                  min?.toString() === 'Invalid Date' || min === null || min === undefined
                    ? moment('1/1/1900', 'DD/MM/yyyy').toDate()
                    : new Date(`${min}`)
                }
                maxDate={max ? new Date(`${max}`) : null}
                inputProps={{
                  readOnly: true,
                  disabled: true,
                }}
              />
            )}
            control={control}
            name={name}
          />
        </LocalizationProvider>
      </CssDateField>
      <p className="error-input error">{errors[`${name}`]?.message}</p>
    </>
  );
}

export default CustomInputDate;
