import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { NavLink as RouteLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Breadcrumbs, Grid, Typography } from '@mui/material';

import Loading from 'components/Loading';
import imgAvatar from 'assets/img/theme/avatar.jpg';
import detailIcon from 'assets/img/icons/detail-icon.svg';
import studyIcon from 'assets/img/icons/study-icon.svg';
import accessTime from 'assets/img/icons/access_time.svg';
import achievementsIcon from 'assets/img/icons/achievements-icon.svg';
import cupIcon from 'assets/img/icons/cup.svg';
import certificateIcon from 'assets/img/icons/certificate.svg';
import starIcon from 'assets/img/icons/star-icon.svg';

import iconNext from 'assets/img/icons/next.png';
import iconPrev from 'assets/img/icons/prev.png';

import styles from './styles.module.scss';
import { useDetailUser } from 'hook/useDepartment';
import { getGenderEmployee } from 'helpers/profileEmployee';
import { MARRIE } from 'constants/ProfileEmployee';
import classNames from 'classnames';
import { useInforAuth } from 'hook/useAuth';
import { NOT_VIEW } from 'constants/Company';
import { handleOnError } from 'helpers/index.js';
SwiperCore.use([Navigation]);

const MemberDetail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const userId = params.userId;
  const { data: uProfileUser, isLoading: isLoadingProfile } = useDetailUser(userId);
  const { data: dataInforUser } = useInforAuth();
  const checkRole = (userId) => {
    if ((dataInforUser?.hr_permission &&
      (dataInforUser?.hr_permission?.user_detail_permission !== NOT_VIEW ||
        dataInforUser?.user_id === userId)) ||
      (!dataInforUser?.hr_permission && dataInforUser?.user_id === userId)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div className={styles.containerRoot}>
        {isLoadingProfile && <Loading />}
        {!isLoadingProfile && <Breadcrumbs aria-label="breadcrumb" className="detail-breadcrumb">
          <RouteLink className="previous-name" to={{ pathname: '/companies/handbook' }}>
            {t('divisionDiagram.name')}
          </RouteLink>
          <RouteLink
            className="previous-name"
            to={{ pathname: `/department/detail/${uProfileUser?.data?.division?.division_id}` }}
          >
            {uProfileUser?.data?.division?.division_name}
          </RouteLink>

          <Typography className="active-name">{uProfileUser?.data?.user_information?.name}</Typography>
        </Breadcrumbs>}
        <div className={styles.rowInfo}>
          {uProfileUser && (
            <>
              <Grid container spacing={2}>
                <Grid item xs="3">
                  <div className={styles.leftComponent}>
                    <div className={styles.infoTop}>
                      <img onError={handleOnError} src={uProfileUser?.data?.user_information?.avatar || imgAvatar} alt="" />
                      <div className={styles.name}>{uProfileUser?.data?.user_information?.name}</div>
                      <div className={styles.position}>{uProfileUser?.data?.user_information?.position}</div>
                    </div>

                    <div className={styles.infoBottom}>
                      <div className={styles.label}>{t('infoEmployee.email')}</div>
                      <div>{uProfileUser?.data?.user_information?.email}</div>
                      <div className={styles.label}>{t('infoEmployee.manager')}</div>
                      <div>
                        {(!checkRole(uProfileUser?.data?.manager?.user_id) || uProfileUser?.data?.manager?.user_id !== dataInforUser?.user_id) &&
                          uProfileUser?.data?.manager?.user_name
                        }
                        {uProfileUser?.data?.manager?.user_id === dataInforUser?.user_id && checkRole(uProfileUser?.data?.manager?.user_id) &&
                          <RouteLink to={{ pathname: '/member/detail/' + uProfileUser?.data?.manager?.user_id }}>
                            {uProfileUser?.data?.manager?.user_name}
                          </RouteLink>
                        }
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs="9">
                  <div className={styles.rightComponent}>
                    <div className={styles.infoBlock}>
                      <div className={styles.header}>
                        <img src={detailIcon} alt="" />
                        <div className={styles.title}>{t('infoEmployee.titleInfo')}</div>
                      </div>

                      <div className={styles.body}>
                        <div className={styles.information}>
                          <div className={styles.item}>
                            <div className={styles.label}>{t('infoEmployee.dateOfBirth')}</div>
                            <div>{uProfileUser?.data?.user_information?.birthday || t('infoEmployee.notUpdate')}</div>
                          </div>

                          <div className={styles.item}>
                            <div className={styles.label}>{t('infoEmployee.level')}</div>
                            <div>{uProfileUser?.data?.user_information?.level || t('infoEmployee.notUpdate')}</div>
                          </div>

                          <div className={styles.item}>
                            <div className={styles.label}>{t('infoEmployee.gender')}</div>
                            <div>
                              {t(getGenderEmployee(uProfileUser?.data?.user_information?.gender)) ||
                                t('infoEmployee.notUpdate')}
                            </div>
                          </div>

                          <div className={styles.item}>
                            <div className={styles.label}>{t('infoEmployee.division')}</div>
                            <div>{uProfileUser?.data?.division?.division_name || t('infoEmployee.notUpdate')}</div>
                          </div>

                          <div className={styles.item}>
                            <div className={styles.label}>{t('infoEmployee.phone')}</div>
                            <div>{uProfileUser?.data?.user_information?.phone || t('infoEmployee.notUpdate')}</div>
                          </div>

                          <div className={styles.item}>
                            <div className={styles.label}>{t('infoEmployee.group')}</div>
                            <div>{uProfileUser?.data?.user_information?.group || t('infoEmployee.notUpdate')}</div>
                          </div>

                          <div className={styles.item}>
                            <div className={styles.label}>{t('infoEmployee.married')}</div>
                            <div>
                              {uProfileUser?.data?.user_information?.marital == null ? (
                                <span>{t('infoEmployee.notUpdate')}</span>
                              ) : uProfileUser?.data?.user_information?.marital === MARRIE ? (
                                <span>{t('infoEmployee.statusMarried')}</span>
                              ) : (
                                <span>{t('infoEmployee.statusMarrie')}</span>
                              )}
                            </div>
                          </div>

                          <div className={styles.item} />
                        </div>
                      </div>
                    </div>

                    <div className={styles.infoBlock}>
                      <div className={styles.header}>
                        <img src={studyIcon} alt="" />
                        <div className={styles.title}>{t('infoEmployee.titleEducation')}</div>
                      </div>

                      <div className={styles.body}>
                        <div className={classNames(styles.studyBlock, styles.swiperSlide)}>
                          <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={20}
                            slidesPerView={3}
                            navigation={{
                              prevEl: '.prevEdu',
                              nextEl: '.nextEdu',
                            }}
                            breakpoints={{
                              1366: {
                                slidesPerView: 3,
                              },
                              1024: {
                                slidesPerView: 2,
                              },
                              768: {
                                slidesPerView: 2,
                              },
                              320: {
                                slidesPerView: 1,
                              },
                            }}
                          >
                            {uProfileUser?.data?.education?.map((item) => (
                              <SwiperSlide key={item.id}>
                                <div className={styles.studyItem}>
                                  <div className={styles.title}>{item.name}</div>

                                  <div className={styles.item}>
                                    <img src={studyIcon} alt="" />
                                    <div>
                                      <strong>{t('infoEmployee._address')}:</strong> {item.major}
                                    </div>
                                  </div>

                                  <div className={styles.item}>
                                    <img src={accessTime} alt="" />
                                    <div>
                                      <strong>{t('infoEmployee.time')}:</strong> {item.start_date} - {item.end_date}
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                          {uProfileUser?.data?.education?.length <= 3 ? (
                            ''
                          ) : (
                            <div className={`${styles.prevButton} prevEdu`}>
                              <img alt={iconPrev} src={iconPrev} />
                            </div>
                          )}

                          {uProfileUser?.data?.education?.length <= 3 ? (
                            ''
                          ) : (
                            <div className={`${styles.nextButton} nextEdu`}>
                              <img alt={iconNext} src={iconNext} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.infoBlock}>
                      <div className={styles.header}>
                        <img src={achievementsIcon} alt="" />
                        <div className={styles.title}>{t('infoEmployee.titleAchievement')}</div>
                      </div>

                      <div className={styles.body}>
                        <div className={styles.achievements}>
                          {uProfileUser?.data?.user_achievements?.map((item) => (
                            <div className={styles.achievementItem} key={item.user_id}>
                              <img src={cupIcon} alt="" />
                              <div>
                                <div className={styles.name}>{item.user_achievement_name}</div>
                                <div className={styles.time}>
                                  {t('infoEmployee.timeToJoin')}: {item.start_date}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className={styles.infoBlock}>
                      <div className={styles.header}>
                        <img src={certificateIcon} alt="" />
                        <div className={styles.title}>{t('infoEmployee.achievementsAndAwards')}</div>
                      </div>

                      <div className={styles.body}>
                        <div className={styles.certificate}>
                          {uProfileUser?.data?.user_certificate?.map((item) => (
                            <div className={styles.certificateItem} key={item.name}>
                              <div className={styles.certificateName}>{item.name}</div>

                              <div className={styles.certificateData}>
                                {item?.data?.map((certificate) => (
                                  <div className={styles.item}>
                                    <img src={certificate.image} alt="" />

                                    <div>
                                      <div className={styles.name}>{certificate.certificate_name}</div>
                                      <div className={styles.time}>
                                        {t('infoEmployee.dateRefresh')}: {certificate.date}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className={styles.infoBlock}>
                      <div className={styles.header}>
                        <img src={starIcon} alt="" />
                        <div className={styles.title}>{t('infoEmployee.titleSkill')}</div>
                      </div>

                      <div className={styles.body}>
                        <div className={styles.stars}>
                          {uProfileUser?.data?.user_skill?.map((item) => (
                            <>
                              {item.skill_id && (
                                <div className={styles.item} key={item.skill_id}>
                                  {item.skill}
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
          {/* {!isLoadingProfileUser && !uProfileUser && (
            <p className={classNames(`${styles.loading} text-center m-0`)}>{t('common.notData')}</p>
          )} */}
        </div>
      </div>
    </>
  );
};

export default MemberDetail;
