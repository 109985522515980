import { Button, Form, Modal, Select, Spin, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscChromeClose } from 'react-icons/vsc';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { AiFillCaretDown } from 'react-icons/ai';
import { FIELD_KEY } from 'constants/Projects';
import { apiGetUserProject, apiCreateDailyReport } from 'api/projects';
import { useMutation, useQuery } from 'react-query';
import { USE_GET_USER_PROJECT } from 'constants/Projects';
import { formatDatePiker } from 'helpers/formatDate';
import moment from 'moment';
import { IoIosAddCircle } from 'react-icons/io';
import ReportForm from './ReportForm';

const CreateDailyReport = ({ isOpen, setIsOpen, refetchDaiLyReports }) => {
  const [disable, setDisable] = useState(false);
  const [deleteSubFrom, setDeleteSubFrom] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const GROUP_REPORT = 'group_report';

  const { data: projects, isLoading } = useQuery([USE_GET_USER_PROJECT], () => apiGetUserProject(), {
    select: (res) =>
      res?.data?.projects.map((project) => {
        return { value: project.id, label: project.name };
      }),
    onError: (error) => console.log(error),
  });

  const createDailyReport = useMutation((data) => apiCreateDailyReport(data), {
    onSuccess: (res) => {
      if (res?.data?.status === 422) message.error(res?.data?.message);
      else {
        message.success(t('project.daily.create.success'));
        refetchDaiLyReports();
        setIsOpen(!isOpen);
        form.resetFields();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleCancel = () => {
    form.resetFields();
    setIsOpen(!isOpen);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleSubmit = (values) => {
    setDisable(true);
    const payload = [];
    values.group_report.forEach((value) =>
      value.report_item.forEach((item) => {
        payload.push({
          [FIELD_KEY.PROJECT_ID]: value.project_id,
          [FIELD_KEY.TASK_ID]: item.title?.trim(),
          [FIELD_KEY.LINK_BACKLOG]: item.link_backlog?.trim(),
          [FIELD_KEY.WORK_DATE]: formatDatePiker(item.work_date, 'YYYY-MM-DD'),
          [FIELD_KEY.ACTUAL_TIME]: item.actual_time.diff(moment().startOf('day'), 'minutes') / 60,
          [FIELD_KEY.DESCRIPTION]: item.description?.trim(),
          [FIELD_KEY.PROCESS_TYPE]: item.process_type,
        });
      }),
    );

    createDailyReport.mutate({ daily_reports: payload });

    setTimeout(() => {
      setDisable(false);
    }, 2000);
  };

  return (
    <Modal
      title={t('project.daily.create.title')}
      centered
      destroyOnClose
      afterClose={form.resetFields}
      maskClosable={false}
      closeIcon={<VscChromeClose />}
      visible={isOpen}
      onCancel={handleCancel}
      className={`${styles.modal} ${styles.modalCreate}`}
      footer={[
        <Button className="cancelButton" onClick={handleCancel}>
          {t('project.daily.create.cancel')}
        </Button>,
        <Button className="okButton" onClick={handleOk} loading={createDailyReport.isLoading} disabled={disable}>
          {t('project.daily.create.save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        className={classNames(styles.formReport, 'materialStyle')}
        size="middle"
        onFinish={handleSubmit}
      >
        <Form.List name={GROUP_REPORT} initialValue={[{ work_date: moment() }]}>
          {(fields, { add, remove }) => {
            return (
              <div className="d-flex flex-column">
                {fields.map((key, name, ...restFiled) => (
                  <div key={key} className={styles.groupReport}>
                    <Form.Item
                      // {...restFiled}
                      label={t('project.daily.create.project')}
                      required
                      name={[name, FIELD_KEY.PROJECT_ID]}
                      rules={[{ required: true, message: t('project.daily.create.pleaseFillThisField') }]}
                    >
                      <Select
                        showSearch
                        showArrow="true"
                        showAction={['focus', 'click']}
                        allowClear
                        suffixIcon={<AiFillCaretDown />}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        dropdownRender={(menu) => menu}
                        options={projects}
                        notFoundContent={
                          isLoading && (
                            <div className="text-center">
                              <Spin />
                            </div>
                          )
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        className={styles.selectProject}
                      />
                    </Form.Item>
                    <ReportForm formName={name} form={form} key={key} deleteSubFrom={deleteSubFrom} />
                    {fields.length > 1 && (
                      <Button
                        type="link"
                        size="middle"
                        className={classNames(styles.btnDelete)}
                        onClick={() => {
                          remove(name);
                          setDeleteSubFrom(!deleteSubFrom);
                        }}
                      >
                        {t('project.daily.create.deleteReport')}
                      </Button>
                    )}
                  </div>
                ))}

                <Button
                  type="ghost"
                  className={styles.addReportButton}
                  icon={<IoIosAddCircle size={24} />}
                  onClick={() => {
                    add({ work_date: moment() });
                  }}
                >
                  {t('project.daily.create.addProject')}
                </Button>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default CreateDailyReport;
