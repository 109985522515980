/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useContext } from 'react';
import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputBase,
  Paper,
  RadioGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import AddIcon from '@mui/icons-material/Add';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Controller, useForm } from 'react-hook-form';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import classNames from 'classnames';
import Zoom from '@mui/material/Zoom';

import TimeKeeping from '../index';
import { customDate } from 'helpers/formatDate.js';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import DateMonthMonthComponent from 'components/DateTime/DateMonth.js';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import * as Constants from 'constants/index.js';
import { useGetRequestIndividual, useGetListTypeOfCategory } from 'hook/useTimeSheet';
import iconLoading from 'assets/img/icons/loading.png';
import './style.scss';
import '../../Projects/style.scss';
import TotalIcon from 'assets/img/icons/total-icon.svg';
import HourglassIcon from 'assets/img/icons/hourglass-icon.svg';
import CheckedNavyIcon from 'assets/img/icons/checked-navy.svg';
import ButtonComponent from 'components/Buttons';
import calendarIcon from 'assets/img/icons/calendar-icon.svg';
import { CATEGORY_ID_LIST_REQUEST } from 'constants/TimeSheet';
import { TooltipContent, StyledTooltip } from './TooltipInfo';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import IconCalendar from 'assets/img/icons/icon-calendar.svg';
import { ThemContext } from 'layouts/Member';
import { CONTROL_RADIO } from 'components/InputYupAndMui/constant';
import { transferStatus, transferStatusToColor, transferStatusToBackgroundColor } from './ultil';
import {
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS,
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED,
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY,
} from 'constants/TimeKeeping';
import configs from 'config';
import useResize from 'helpers/useResize';

function RequestTimeKeepingIndividual() {
  const { t } = useTranslation();
  const { control, setValue } = useForm();

  const [listTypeOfCategory, setListTypeOfCategory] = useState([]);

  const [isModals, setIsModals] = useState(false);
  const userId = useContext(ThemContext);

  const convertFormatTimeSend = (name, type) => {
    return moment(name).format(type);
  };

  const [filter, setFilter] = useState({
    key_word: null,
    type: null,
    month: moment().format('MM/YYYY'),
    start_date: null,
    end_date: null,
    status: null,
    user_id: userId,
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT_LARGE,
    category_id: CATEGORY_ID_LIST_REQUEST,
  });

  useEffect(() => {
    setFilter((prev) => {
      return {
        ...prev,
        user_id: userId,
      };
    });
  }, [userId]);

  const STATUS_REQUEST = [
    {
      value: STATUS_REQUEST_OPEN,
      labelName: t('timekeeping.request.statusOpen'),
    },
    {
      value: STATUS_REQUEST_IN_PROGRESS,
      labelName: t('timekeeping.request.statusInProgress'),
    },
    {
      value: STATUS_REQUEST_COMPLETED,
      labelName: t('timekeeping.request.statusCompleted'),
    },
    {
      value: STATUS_REQUEST_DENY,
      labelName: t('timekeeping.request.statusDeny'),
    },
    {
      value: STATUS_REQUEST_REWORK,
      labelName: t('timekeeping.request.statusRework'),
    },
    {
      value: STATUS_REQUEST_RECALL,
      labelName: t('timekeeping.request.statusRecall'),
    },
  ];

  const { data: uListRequestIndividual, isLoading: isLoadingListRequestIndividual } = useGetRequestIndividual({
    ...filter,
    page: filter?.page + 1,
  });

  const { data: uListTypeOfCategory } = useGetListTypeOfCategory({
    category_id: CATEGORY_ID_LIST_REQUEST,
  });

  function handleCheck(items, index) {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0].status;
      items.forEach((item, ind) => (ind === 0 ? (item.status = isCheckAll) : (item.status = false)));
    } else {
      items.forEach(function (item, i) {
        if (((i !== index && item.status === false) || (i === index && item.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items.forEach((item, ind) => (ind === 0 ? (item.status = true) : (item.status = false)));
      } else {
        items.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  const changeSelectListTypeOfCategory = (item) => {
    setListTypeOfCategory(function (listTypeOfCategory) {
      const index = listTypeOfCategory.indexOf(item);
      return handleCheck(listTypeOfCategory, index);
    });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Filter by who send request
  const handleSearchNameWhoSend = (newValue) => {
    setFilter({
      ...filter,
      key_word: newValue,
    });
  };

  useEffect(() => {
    if (uListTypeOfCategory) {
      const listTypeOfCategory = uListTypeOfCategory?.data?.data?.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setListTypeOfCategory([
        {
          id: undefined,
          name: t('employees.all'),
          status: false,
          value: Constants.ALL,
        },
        ...listTypeOfCategory,
      ]);
    }
  }, [uListTypeOfCategory, t]);

  // Reset
  const handleReset = () => {
    setFilter({
      key_word: null,
      category_id: CATEGORY_ID_LIST_REQUEST,
      type: null,
      month: moment().format('MM/YYYY'),
      start_date: null,
      end_date: null,
      status: null,
      user_id: userId,
      page: Constants.DEFAULT_PAGE_MUI,
      per_page: Constants.DEFAULT_LIMIT_LARGE,
    });
    setValue('start_date', null);
    setValue('end_date', convertFormatTimeSend(new Date(), 'MM/DD/yyyy'));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const viewPort = useResize();
  const checkShowDateHorizontal = viewPort.width >= 1250;

  return (
    <>
      <Grid container className={`minWidth1200 d-flex nav-fixed justify-content-between`}>
        <Grid className="menu-slider-around">
          <TimeKeeping />
        </Grid>

        <Grid item>
          <a href={`${configs.DOMAIN_WORK_FOLLOW}/request`} target="blank">
            <ButtonComponent addClass="export" icon={<AddIcon />} text={t('request.createRequest')} />
          </a>
        </Grid>
      </Grid>
      <div className="list-assets request-timekeeping-individual">
        <div>
          <div className="profile-box table-list table-border-show">
            <Paper>
              <TableContainer className="scroll-custom">
                <Table aria-label="sticky table" className="table-scroll">
                  <TableHead className="asset-header">
                    <TableRow className="asset-header-row">
                      <TableCell className="short">{t('timekeeping.request.STT')}</TableCell>
                      <TableCell>{t('timekeeping.request.title')}</TableCell>
                      <TableCell>{t('timekeeping.request.dayRequest')}</TableCell>
                      <TableCell>{t('timekeeping.request.status')}</TableCell>
                      <TableCell>{t('timekeeping.request.createdDay')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingListRequestIndividual && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Loading addClass="mt-3 mb-3" />
                        </TableCell>
                      </TableRow>
                    )}
                    {uListRequestIndividual &&
                      uListRequestIndividual?.data?.data?.map((item, index) => {
                        let stt = filter.page * filter.per_page + index + 1;
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell>
                              <a
                                href={`${configs.DOMAIN_WORK_FOLLOW}/request?request_id=${item?.id}`}
                                target="_blank"
                                ref={(input) => {
                                  item.handelClick = input;
                                }}
                                className="link-work-fl"
                              >
                                {stt >= 10 ? stt : <>0{stt}</>}
                              </a>
                            </TableCell>
                            {/* Title */}
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              <div>
                                <span className="d-block bolder-500">
                                  {item.name ? item.name : t('common.notDataContract')}
                                </span>
                                <span className="added-info">
                                  {item.type_name ? item.type_name : t('common.notDataContract')}
                                </span>
                              </div>
                            </TableCell>
                            {/* Day request */}
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              <StyledTooltip
                                TransitionComponent={Zoom}
                                title={item?.detail && <TooltipContent item={item?.detail} />}
                                className={item?.detail && 'border-bottom-tooltip-none'}
                              >
                                <section className="d-flex">
                                  <div className="number-request-day">
                                    {item?.detail?.length > 1 && <span className="number">{item?.detail?.length}</span>}
                                    <img src={IconCalendar} alt="" />
                                  </div>
                                  <div>
                                    <span className="d-block bolder-500">
                                      {item?.detail[0] ? item?.detail[0]?.hr_date_log : t('common.notDataContract')}
                                    </span>
                                    <div className="added-info straight">
                                      {((item?.detail[0]?.hr_time_morning_log === 'Ca sáng' &&
                                        item?.detail[0]?.hr_time_afternoon_log === 'Ca chiều') ||
                                        (item?.detail[0]?.hr_time_morning_log?.length <= 8 &&
                                          item?.detail[0]?.hr_time_afternoon_log?.length <= 8)) && (
                                        <>
                                          {item?.detail[0]?.hr_time_morning_log &&
                                            (item?.detail[0]?.hr_time_morning_log === 'Ca sáng'
                                              ? item?.detail[0]?.hr_time_morning_log
                                              : item?.detail[0]?.hr_time_morning_log)}

                                          {item?.detail[0]?.hr_time_morning_log &&
                                            item?.detail[0]?.hr_time_afternoon_log && (
                                              <span className="seperate">{'-'}</span>
                                            )}
                                          <span>
                                            {item?.detail[0]?.hr_time_afternoon_log &&
                                              (item?.detail[0]?.hr_time_afternoon_log === 'Ca chiều'
                                                ? item?.detail[0]?.hr_time_afternoon_log
                                                : item?.detail[0]?.hr_time_afternoon_log)}
                                          </span>
                                        </>
                                      )}
                                      {item?.detail[0]?.hr_time_morning_log !== 'Ca sáng' &&
                                        item?.detail[0]?.hr_time_afternoon_log !== 'Ca chiều' &&
                                        (item?.detail[0]?.hr_time_morning_log?.length > 8 ||
                                          item?.detail[0]?.hr_time_afternoon_log?.length > 8) && (
                                          <p>
                                            {item?.detail[0]?.hr_time_morning_log ||
                                              item?.detail[0]?.hr_time_afternoon_log}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </section>
                              </StyledTooltip>
                            </TableCell>
                            {/* Status */}
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item?.status ? (
                                <span className={classNames(transferStatusToColor(item?.status))}>
                                  {transferStatus(item?.status)}
                                </span>
                              ) : (
                                t('common.notDataContract')
                              )}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item.created_at ? item.created_at : t('common.notDataContract')}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {!uListRequestIndividual?.total_request && !isLoadingListRequestIndividual && (
                      <tr>
                        <td colSpan={5} className="error-no-data">
                          {t('common.notData')}
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {uListRequestIndividual?.total_request > 0 && (
                <TablePagination
                  rowsPerPageOptions={Constants.PER_PAGE_OPTION_V2}
                  component="div"
                  count={uListRequestIndividual?.total_request}
                  rowsPerPage={filter?.per_page}
                  page={filter?.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                />
              )}
            </Paper>
          </div>
        </div>
        <div className="list-assets__condition">
          <div className="dayOff m-0">
            <img src={calendarIcon} alt="" />
            <div>
              <div className="label">{t('timekeeping.offHour')}</div>
              <div className="value">{uListRequestIndividual?.day_off}</div>
            </div>
          </div>
          <div className="box-filter">
            <div className="filter-by-date">
              <DateMonthMonthComponent
                label=""
                name="month"
                valueDefault={typeof filter.month === 'string' ? moment(filter.month, 'MM/YYYY') : filter.month}
                onChangeHandle={(value) => {
                  setFilter({
                    ...filter,
                    month: customDate(value, 'MM/YYYY'),
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                placeholder={t('contract.contractRetire.monthOut')}
                sizeDate="small"
              />
            </div>
            <div className="statistical">
              <div className="statistical-item special-item">
                <div className="d-flex align-items-center">
                  <img src={TotalIcon} alt="" />
                  <span className="statistical-item-title">{t('timekeeping.request.totalRequest')}</span>
                </div>
                <b className="statistical-item-title">{uListRequestIndividual?.total_request}</b>
              </div>
              <div className="statistical-item hourglass-padding">
                <div className="d-flex align-items-center">
                  <img src={HourglassIcon} alt="" />
                  <span className="statistical-item-title">{t('timekeeping.OT.pendingProposals')}</span>
                </div>
                <b className="statistical-item-title">
                  {uListRequestIndividual?.total_processing +
                    uListRequestIndividual?.total_open +
                    uListRequestIndividual?.total_rework}
                </b>
              </div>
              <div className="statistical-item">
                <div className="d-flex align-items-center">
                  <img src={CheckedNavyIcon} alt="" />
                  <span className="statistical-item-title">{t('timekeeping.OT.numberProposalsProcessed')}</span>
                </div>
                <b className="statistical-item-title">
                  {uListRequestIndividual?.total_completed +
                    uListRequestIndividual?.total_rejected +
                    uListRequestIndividual?.total_recall}
                </b>
              </div>
            </div>
            <div className="filter-by-input">
              <div className="padding-element-filter">
                <SearchComponent
                  txtPlaceholder={t('employees.searchName')}
                  addClass="input-search-box"
                  defaultValue={''}
                  handleSearch={handleSearchNameWhoSend}
                />
              </div>
              <div className={classNames(`text padding-element-filter`)}>
                <InputBase
                  disabled
                  type="text"
                  name="type"
                  className={classNames('exampleSelectMulti', listTypeOfCategory && 'text-input-dark')}
                  onClick={() => {
                    setIsModals((prev) => {
                      return { ...prev, ware_hourse: true };
                    });
                  }}
                  value={
                    listTypeOfCategory &&
                    (!listTypeOfCategory[0]?.status
                      ? listTypeOfCategory
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  placeholder={t('timekeeping.request.type')}
                />
                <ModalCommon
                  items={listTypeOfCategory}
                  onSubmit={() => {
                    let datas = [];
                    listTypeOfCategory.forEach((it) => {
                      it.status && it.id && datas.push(it.id);
                    });
                    setFilter({
                      ...filter,
                      type: datas,
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    setIsModals(false);
                  }}
                  onClick={changeSelectListTypeOfCategory}
                  toggle={() => {
                    setIsModals(!isModals);
                    listTypeOfCategory.forEach((item) => {
                      filter?.type &&
                      (filter?.type.indexOf(item.id) >= 0 || filter?.type.length === listTypeOfCategory.length - 1)
                        ? (item.status = true)
                        : (item.status = false);
                    });
                    setListTypeOfCategory([...listTypeOfCategory]);
                  }}
                  modal={isModals}
                  title={t('timekeeping.request.type')}
                  footer={t('common.search')}
                />
              </div>
              <div
                className={classNames(`text from-to`)}
                id="xxxx"
                style={{
                  flexDirection: checkShowDateHorizontal ? 'row' : 'column',
                }}
              >
                <DateDayMonthComponent
                  label=""
                  name="start_date"
                  valueDefault={
                    typeof filter.start_date === 'string' ? moment(filter.start_date, 'DD/MM/YYYY') : filter.start_date
                  }
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      start_date: moment(value).format('DD/MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('timekeeping.request.dayStartRequest')}
                  sizeDate="small"
                />
                {checkShowDateHorizontal ? (
                  <div className="between">~</div>
                ) : (
                  <div
                    style={{
                      height: 15,
                    }}
                  />
                )}
                <DateDayMonthComponent
                  label=""
                  name="end_date"
                  valueDefault={
                    typeof filter.end_date === 'string' ? moment(filter.end_date, 'DD/MM/YYYY') : filter.end_date
                  }
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      end_date: moment(value).format('DD/MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('timekeeping.request.dayEndRequest')}
                  sizeDate="small"
                />
              </div>
            </div>

            <div>
              <FormControl component="fieldset">
                <FormLabel className={classNames(`text padding-element-filter padding-element-top title`)}>
                  {t('timekeeping.request.status')}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field} value={filter?.status ? filter?.status : 0}>
                      <FormControlLabel
                        value={0}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            status: null,
                            page: Constants.DEFAULT_PAGE_MUI,
                          });
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        control={CONTROL_RADIO}
                        label={t('employees.all')}
                      />
                      {STATUS_REQUEST?.map((item, index) => (
                        <div key={index} className="division d-flex justify-content-between">
                          <FormControlLabel
                            key={index}
                            value={item?.value}
                            onChange={(e) => {
                              setFilter({
                                ...filter,
                                status: e.target.value,
                                page: Constants.DEFAULT_PAGE_MUI,
                              });
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            control={CONTROL_RADIO}
                            label={item?.labelName}
                          />
                          <div
                            className={classNames(`dot-status-accept ${transferStatusToBackgroundColor(item?.value)}`)}
                          ></div>
                        </div>
                      ))}
                    </RadioGroup>
                  )}
                  name="status"
                  control={control}
                  defaultValue={filter?.status ? filter?.status : STATUS_REQUEST_IN_PROGRESS}
                />
              </FormControl>
            </div>
            <div className="border-top">
              <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                {t('common.reset')}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestTimeKeepingIndividual;
