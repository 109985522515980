export const mergeArraysWithoutDuplicates = (arrayOne, arrayTwo) => {
  if (!arrayOne && !arrayTwo) {
    return [];
  }
  const arrayAfter = arrayTwo?.split(',');
  if (!arrayOne) {
    return arrayAfter;
  }
  return arrayOne.split(',').map((item, key) => {
    if (item !== '0') {
      return item;
    }
    if (!arrayTwo) {
      return '';
    }
    return arrayAfter[key];
  });
};

export const afterLateTime = (time, defaultTime) => {
  if (!time) {
    return defaultTime
  }
  return time.substr(time.indexOf('-') + 1).trim();
}
