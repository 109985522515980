import { sendGet } from '../helpers/axios';
import { sendGet as sendGetBwf } from '../helpers/axiosBwf';

export const apiGetProfileEmployee = (userId, params) => sendGet(`/api/dashboard/info/${userId}`, params);
export const apiGetProjectAllocations = (userId, params) => sendGet(`api/dashboard/projects/allocations`, params);
export const apiGetProjectAllocationsByMonth = (userId, params) =>
  sendGet(`api/dashboard/projects/month-allocations`, params);
export const apiGetProjectStats = (userId, params) => sendGet(`api/dashboard/projects/stats`, params);
export const apiGetInfoAssets = (userId, params) => sendGet(`api/dashboard/asset/${userId}`, params);
export const apiGetInfoTimekeeping = (userId, params) => sendGet(`/api/dashboard/time-sheet/member/${userId}`, params);
export const apiGetInfoTimekeepingDay = (userId, params) => sendGet(`/api/dashboard/timesheet/${userId}`, params);
export const apiGetRequests = (params) => sendGetBwf(`/api/v1/hr/list_requests_priority`, params);
export const apiGetUnReportDays = (params) => sendGet(`/api/unreported-days`);
