import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Loading from 'components/Loading';
import styles from './style.module.scss';
import IconMyRequest from 'assets/img/icons/dashboard/my_request.svg';
import IconUrgent from 'assets/img/icons/dashboard/urgent.svg';
import IconInvolve from 'assets/img/icons/dashboard/involve.svg';
import { useGetRequests } from 'hook/useDashboard.js';
import { ThemContext } from 'layouts/Member';
import { NO_DATA } from 'constants/index.js';
import {
  REQUEST_NEW,
  REQUEST_URGENT,
  REQUEST_INFOR,
  USE_REQUEST_NEW,
  USE_REQUEST_URGENT,
  USE_REQUEST_INFOR,
} from 'constants/Request.js';
import configs from 'config';
import { customDate } from 'helpers/formatDate';

const ItemRequest = ({ icon, data }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.itemRequest}>
      <div className="d-flex align-items-center">
        {icon ? (
          <div className={styles.icon}>
            <img src={icon} alt={icon} />
          </div>
        ) : (
          <div className={styles.type}>{t('boxInfoEmployee.dashboard.new')}</div>
        )}

        <div className={styles.title}>{data?.request_name}</div>
      </div>
      <div className={classNames(`d-flex align-items-center ${styles.time}`)}>
        <div className={styles.account}>{data?.category_name}</div>
        <div className={classNames(`d-flex align-items-center`)}>
          <div>{data?.hr_start_date_log && data?.hr_due_date_log && t('boxInfoEmployee.dashboard.time')} &nbsp; </div>
          <div>{customDate(data?.hr_start_date_log, 'DD/MM/YYYY')} </div>
          <div>{data?.hr_start_date_log && data?.hr_due_date_log && '-'}</div>
          <div>{customDate(data?.hr_due_date_log, 'DD/MM/YYYY')}</div>
        </div>
      </div>
    </div>
  );
};

const Request = () => {
  const { t } = useTranslation();
  const userId = useContext(ThemContext);

  const { data: dataRequestNew, isLoading: isLoadingRequestNew } = useGetRequests({
    type_request: REQUEST_NEW,
    user_id: userId,
    constant_api: USE_REQUEST_NEW,
  });

  const { data: dataRequestUrgent, isLoading: isLoadingRequestUrgent } = useGetRequests({
    type_request: REQUEST_URGENT,
    user_id: userId,
    constant_api: USE_REQUEST_URGENT,
  });

  const { data: dataRequestInfor, isLoading: isLoadingRequestInfor } = useGetRequests({
    type_request: REQUEST_INFOR,
    user_id: userId,
    constant_api: USE_REQUEST_INFOR,
  });

  return (
    <Box className={classNames(`border-show pd-15 h-100 ${styles.boxNoTiMyRequest}`)}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <div className="body-top">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <img src={IconMyRequest} alt={IconMyRequest} className="icon" />
                <h4 className="title-body">{t('boxInfoEmployee.dashboard.myRequest')}</h4>
              </div>
              <div>
                <a href={`${configs.DOMAIN_WORK_FOLLOW}/my-request`} target="blank" className={styles.linkDetailTop}>
                  {t('boxInfoEmployee.showDetail')}
                </a>
              </div>
            </div>
            <div className={classNames(`d-flex justify-content-between ${styles.requestInfoTop}`)}>
              <div>
                <span>
                  {dataRequestNew?.total_request ? dataRequestNew?.total_request : NO_DATA}
                </span>{' '}
                <br /> {t('boxInfoEmployee.dashboard.totalRequest')}
              </div>
              <div>
                <span>
                  {dataRequestNew?.request_processing ? dataRequestNew?.request_processing : NO_DATA}
                </span>{' '}
                <br /> {t('boxInfoEmployee.dashboard.processing')}
              </div>
              <div>
                <span>
                  {dataRequestNew?.request_completed ? dataRequestNew?.request_completed : NO_DATA}
                </span>{' '}
                <br />
                {t('boxInfoEmployee.dashboard.approved')}
              </div>
            </div>
            <hr />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.typeRequest}>{t('common.new')}</div>
          <Box className={styles.requestInfo}>
            {dataRequestNew?.data?.map((item, index) => (
              <a
                href={`${configs.DOMAIN_WORK_FOLLOW}/my-request?request_id=${item?.id}`}
                target="blank"
                className={styles.linkToWorkFlDetail}
              >
                <ItemRequest data={item} key={index} />
              </a>
            ))}
            {isLoadingRequestNew && (
              <p className="error-no-data">
                <Loading addClass="mt-3" />
              </p>
            )}
            {dataRequestNew?.data?.length === NO_DATA && <p className="error-no-data">{t('common.notData')}</p>}
          </Box>
          <hr />
          <div className={styles.typeRequest}>{t('common.urgentHandling')}</div>
          <Box className={styles.requestInfo}>
            {dataRequestUrgent?.data?.map((item, index) => (
              <a
                href={`${configs.DOMAIN_WORK_FOLLOW}/my-request?request_id=${item?.id}`}
                target="blank"
                className={styles.linkToWorkFlDetail}
              >
                <ItemRequest data={item} icon={IconUrgent} key={index} />
              </a>
            ))}
            {isLoadingRequestUrgent && (
              <p className="error-no-data">
                <Loading addClass="mt-3" />
              </p>
            )}
            {dataRequestUrgent?.data?.length === NO_DATA && <p className="error-no-data">{t('common.notData')}</p>}
          </Box>
          <hr />
          <div className={styles.typeRequest}>{t('common.following')}</div>
          <Box className={styles.requestInfo}>
            {dataRequestInfor?.data?.map((item, index) => (
              <a
                href={`${configs.DOMAIN_WORK_FOLLOW}/my-request?request_id=${item?.id}`}
                target="blank"
                className={styles.linkToWorkFlDetail}
              >
                <ItemRequest data={item} icon={IconInvolve} key={index} />
              </a>
            ))}
            {isLoadingRequestInfor && (
              <p className="error-no-data">
                <Loading addClass="mt-3" />
              </p>
            )}
            {dataRequestInfor?.data?.length === NO_DATA && <p className="error-no-data">{t('common.notData')}</p>}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Request;
