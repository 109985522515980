import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import styles from './style.module.scss';
import thankIconChil from 'assets/img/icons/thankYouChil.svg';
import { useGetProfileEmployee } from 'hook/useDashboard.js';
import { ThemContext } from 'layouts/Member';
import { customDate } from 'helpers/formatDate';

export const ThankYouDashboard = () => {
  const { t } = useTranslation();
  const { data: dataInfoEmployee } = useGetProfileEmployee(useContext(ThemContext));

  return (
    <Box className={classNames(`border-show ${styles.thankYouDashboard}`)}>
      <Grid className={styles.thankYou}>
        <img src={thankIconChil} alt="thankYou" />
        <div className={styles.info}>
          <p className={styles.thankYou}>{t('common.thankYou')}</p>
          <p>{t('common.yourCompanionshipWithAmela')}</p>
          <p className={styles.dateOnboard}>
            {t('boxInfoEmployee.onboard')} : {customDate(dataInfoEmployee?.contract, 'DD/MM/YYYY')}(
            {dataInfoEmployee?.total_date ? dataInfoEmployee?.total_date : 0} {t('common.day')})
          </p>
        </div>
      </Grid>
    </Box>
  );
};
