import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import HomeMiniIcon from 'assets/img/icons/home-mini.svg';
import IconBo from 'assets/img/icons/acms-bo.svg';
import IconMe from 'assets/img/icons/acms-me.svg';
//eslint-disable-next-line
import IconRecruit from 'assets/img/icons/acms-recruit.svg';
import IconWorkFlow from 'assets/img/icons/acms-work-flow.svg';
import IconProject from 'assets/img/icons/acms-project.svg';
import IconDivision from 'assets/img/icons/acms-division.svg';
//eslint-disable-next-line
import IconAccounting from 'assets/img/icons/acms-accounting.svg';
import IconWiki from 'assets/img/icons/acms-wiki.svg';
import configs from 'config';
import styles from './style.module.scss';
import { useInforAuth } from 'hook/useAuth';
import avatarDefault from 'assets/img/icons/default-avatar.svg';
import { handleOnError } from 'helpers';

export default function CustomizedTooltips() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { data: dataInforUser } = useInforAuth();
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={open}
      arrow
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#f5f5f9',
      marginLeft: 10,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      width: 600,
      height: 800,
      maxWidth: 600,
      marginRight: 20,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <BootstrapTooltip
          title={
            <Grid item={true} container>
              <Grid className={styles.tooltip} item={true} xs={12}>
                <img
                  onError={handleOnError}
                  alt={dataInforUser?.avatar ? dataInforUser?.avatar : avatarDefault}
                  src={dataInforUser?.avatar ? dataInforUser?.avatar : avatarDefault}
                  className={styles.avatar}
                />
                <div>
                  <p className={styles.name}>{dataInforUser?.name}</p>
                  <p className={styles.inforOther}>{dataInforUser?.email}</p>
                  <p className={styles.inforOther}>
                    {dataInforUser?.position_name} {dataInforUser?.position_name && dataInforUser?.division_name && '-'}{' '}
                    {dataInforUser?.division_name}
                  </p>
                </div>
              </Grid>
              <Grid item={true} xs={12}>
                <div className={styles.box}>
                  <a
                    href={configs.DOMAIN_APP_DOMAIN_AMS_BO}
                    className={styles.redirect}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img className={styles.logoAcms} src={IconBo} alt={IconBo} />
                    <p>{t('home.acmsBo')}</p>
                    <p className={styles.description}>{t('home.employee')}</p>
                  </a>
                </div>
                <div className={styles.box}>
                  <a
                    href={configs.DOMAIN_APP_DOMAIN_AMS_ME}
                    className={styles.redirect}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img className={styles.logoAcms} src={IconMe} alt={IconMe} />
                    <p>{t('home.acmsMe')}</p>
                    <p className={styles.description}>{t('home.employeeGate')}</p>
                  </a>
                </div>
                <div className={styles.box}>
                  <a href={configs.DOMAIN_AMS_WORKFLOW} className={styles.redirect} rel="noreferrer" target="_blank">
                    <img className={styles.logoAcms} src={IconWorkFlow} alt={IconWorkFlow} />
                    <p>{t('home.acmsWorkFlow')}</p>
                    <p className={styles.description}>{t('home.createRequest')}</p>
                  </a>
                </div>
                <div className={styles.box}>
                  <a href={configs.DOMAIN_AMS_WIKI} className={styles.redirect} rel="noreferrer" target="_blank">
                    <img className={styles.logoAcms} src={IconWiki} alt={IconWiki} />
                    <p>{t('home.acmsWiki')}</p>
                    <p className={styles.description}>{t('home.wiki')}</p>
                  </a>
                </div>
                <div className={styles.box}>
                  <a href={configs.DOMAIN_AMS_RECRUIT} className={styles.redirect} rel="noreferrer" target="_blank">
                    <img className={styles.logoAcms} src={IconRecruit} alt={IconRecruit} />
                    <p>{t('home.acmsRecruit')}</p>
                    <p className={styles.description}>{t('home.recruitment')}</p>
                  </a>
                </div>
                <div className={styles.box}>
                  <a href={configs.DOMAIN_AMS_PROJECT} className={styles.redirect} rel="noreferrer" target="_blank">
                    <img className={styles.logoAcms} src={IconProject} alt={IconProject} />
                    <p>{t('home.acmsProject')}</p>
                    <p className={styles.description}>{t('home.project')}</p>
                  </a>
                </div>
                <div className={styles.box}>
                  <a href={configs.DOMAIN_AMS_DIVISION} className={styles.redirect} rel="noreferrer" target="_blank">
                    <img className={styles.logoAcms} src={IconDivision} alt={IconDivision} />
                    <p>{t('home.acmsDivision')}</p>
                    <p className={styles.description}>{t('home.division')}</p>
                  </a>
                </div>
                {/* <div className={styles.box}>
                  <a href={configs.DOMAIN_AMS_ACCOUNTING} className={styles.redirect} rel="noreferrer" target="_blank">
                    <img className={styles.logoAcms} src={IconAccounting} alt={IconAccounting} />
                    <p>{t('home.acmsAccounting')}</p>
                    <p className={styles.description}>{t('home.account')}</p>
                  </a>
                </div> */}
              </Grid>
            </Grid>
          }
        >
          <Button className={styles.buttonHome} onClick={open ? handleTooltipClose : handleTooltipOpen}>
            <img alt={HomeMiniIcon} src={HomeMiniIcon} />
          </Button>
        </BootstrapTooltip>
      </div>
    </ClickAwayListener>
  );
}
