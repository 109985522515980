import { Modal, Table, Tooltip } from 'antd';
import React from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { FIELD_KEY } from 'constants/Projects';
import { MAX_LENGTH_30 } from 'constants/index';
import { renderStatusTag } from '../util/ProjectUtil';
import { customDate } from 'helpers/formatDate';
import { useQuery } from 'react-query';
import { USE_GET_CALENDAR_REPORT_DETAIL } from 'constants/Projects';
import { apiGetDetailCalendarReport } from 'api/projects';
import { REPORT_APPROVED } from 'constants/Projects';
import Loading from 'components/Loading';
import { ALL_PROCESS_TYPE } from 'constants/Projects';

const ModalDetailDailyReports = ({ isOpen, setIsOpen, selectReport, selectDate }) => {
  const { t } = useTranslation();
  const { data: detailReport, isLoading } = useQuery(
    [USE_GET_CALENDAR_REPORT_DETAIL, selectDate, selectReport?.task],
    () => apiGetDetailCalendarReport({ work_date: customDate(selectDate, 'YYYY-MM-DD') }),
    { enabled: !!selectDate, select: (res) => res?.data?.data },
  );

  const renderSelectReport = () => {
    const columns = [
      {
        id: FIELD_KEY.INDEX,
        dataIndex: FIELD_KEY.INDEX,
        title: t('project.calendarReport.modalDetail.index'),
        width: 60,
        align: 'center',
        fixed: 'left',
        render: (_, record, index) => index + 1,
      },
      {
        id: FIELD_KEY.TASK_ID,
        dataIndex: FIELD_KEY.TASK_ID,
        title: t('project.calendarReport.modalDetail.taskName'),
        fixed: 'left',
        render: (_, record) => (
          <Tooltip title={record?.title} overlayClassName="custom-tooltip" color="white">
            <span className={`${styles.textOver1Lines}`}>{`${record?.title?.substring(0, MAX_LENGTH_30)} ${
              record?.title?.length >= MAX_LENGTH_30 ? '...' : ''
            }`}</span>
          </Tooltip>
        ),
      },
      {
        id: FIELD_KEY.PROJECT_ID,
        dataIndex: FIELD_KEY.PROJECT_ID,
        width: 250,
        title: t('project.calendarReport.modalDetail.projectName'),
        render: (_, record) => (
          <Tooltip
            title={record?.project?.name}
            overlayClassName="custom-tooltip"
            color="white"
            className={styles.minVw}
          >
            <span className={`${styles.textOver1Lines}`}>{`${record?.project?.name?.substring(0, MAX_LENGTH_30)} ${
              record?.project?.name?.length >= MAX_LENGTH_30 ? '...' : ''
            }`}</span>
          </Tooltip>
        ),
      },
      {
        id: FIELD_KEY.PROCESS_TYPE,
        dataIndex: FIELD_KEY.PROCESS_TYPE,
        width: 165,
        align: 'center',
        title: t('project.daily.listReport.processType'),
        render: (value, record) => t(ALL_PROCESS_TYPE.find((item) => item.key === value)?.text),
      },
      {
        id: FIELD_KEY.ACTUAL_TIME,
        dataIndex: FIELD_KEY.ACTUAL_TIME,
        width: 125,
        align: 'center',
        title: t('project.calendarReport.modalDetail.actualTime'),
        render: (value, record) => `${value}h`,
      },
      {
        id: FIELD_KEY.LINK_BACKLOG,
        dataIndex: FIELD_KEY.LINK_BACKLOG,
        width: 130,
        align: 'center',
        title: t('project.calendarReport.modalDetail.backlogIssue'),
        render: (_, record) => (
          <a href={record.link_backlog} target="_blank" rel="noreferrer">
            {record.issue_key || (record.link_backlog && `${record.link_backlog?.substring(0, 10)}...`)}
          </a>
        ),
      },
      {
        id: FIELD_KEY.STATUS,
        dataIndex: FIELD_KEY.STATUS,
        width: 135,
        title: t('project.calendarReport.modalDetail.status'),
        align: 'center',
        render: (text) => renderStatusTag(text),
      },
    ];

    const contentHeight = detailReport?.daily_reports?.length * 50;

    return (
      <>
        <div className={styles.reportDate}>
          {t('project.calendarReport.modalDetail.reportDate', {
            date: customDate(selectDate, 'DD/MM/YYYY'),
          })}
        </div>
        <Table
          columns={columns}
          dataSource={detailReport?.daily_reports}
          pagination={false}
          className={styles.detailTable}
          scroll={{ x: 'max-content', y: contentHeight > 250 ? 250 : undefined }}
        />
      </>
    );
  };
  const renderOverTime = () => {
    const columns = [
      {
        id: FIELD_KEY.INDEX,
        dataIndex: FIELD_KEY.INDEX,
        title: t('project.calendarReport.modalDetail.index'),
        width: 60,
        align: 'center',
        fixed: 'left',
        render: (_, record, index) => index + 1,
      },
      {
        id: FIELD_KEY.PROJECT_ID,
        dataIndex: FIELD_KEY.PROJECT_ID,
        // width: 250,
        title: t('project.calendarReport.modalDetail.projectName'),
        render: (_, record) => (
          <Tooltip
            title={record?.project_name}
            overlayClassName="custom-tooltip"
            color="white"
            className={styles.minVw}
          >
            <span className={`${styles.textOver1Lines}`}>{`${record?.project_name?.substring(0, MAX_LENGTH_30)} ${
              record?.project_name?.length >= MAX_LENGTH_30 ? '...' : ''
            }`}</span>
          </Tooltip>
        ),
      },
      {
        id: FIELD_KEY.START_TIME,
        dataIndex: FIELD_KEY.START_TIME,
        width: 100,
        align: 'center',
        title: t('project.calendarReport.modalDetail.start'),
        render: (text) => text.substring(0, 5),
      },
      {
        id: FIELD_KEY.END_TIME,
        dataIndex: FIELD_KEY.END_TIME,
        width: 100,
        align: 'center',
        title: t('project.calendarReport.modalDetail.end'),
        render: (text) => text.substring(0, 5),
      },
      {
        id: FIELD_KEY.OT,
        dataIndex: FIELD_KEY.OT,
        width: 120,
        align: 'center',
        title: t('project.calendarReport.modalDetail.overTimeHour'),
        render: (text) => `${text}h`,
      },
      {
        id: FIELD_KEY.OT_WITH_COEFFICIENT,
        dataIndex: FIELD_KEY.OT_WITH_COEFFICIENT,
        width: 180,
        align: 'center',
        title: t('project.calendarReport.modalDetail.overTimeWithCoefficient'),
        render: (text) => `${text}h`,
      },
      {
        id: FIELD_KEY.STATUS,
        dataIndex: FIELD_KEY.STATUS,
        width: 135,
        title: t('project.calendarReport.modalDetail.status'),
        align: 'center',
        render: (text) => renderStatusTag(REPORT_APPROVED),
      },
    ];

    const contentHeight = detailReport?.overtimes?.length * 50;

    return (
      <>
        <div className={styles.overTimeDate}>
          {t('project.calendarReport.modalDetail.overTime', {
            date: customDate(selectDate, 'DD/MM/YYYY'),
          })}
        </div>
        <Table
          columns={columns}
          dataSource={detailReport?.overtimes}
          pagination={false}
          scroll={{ x: 'max-content', y: contentHeight > 150 ? 150 : undefined }}
          className={styles.detailTable}
        />
      </>
    );
  };

  return (
    <Modal
      closeIcon={<VscChromeClose />}
      centered
      destroyOnClose
      className={`${styles.modal} ${styles.detailReport}`}
      visible={isOpen}
      onCancel={() => setIsOpen(!isOpen)}
      footer={null}
      title={t('project.calendarReport.modalDetail.title')}
    >
      {isLoading ? (
        <Loading addClass={'m-5'} />
      ) : (
        <>
          {detailReport?.daily_reports?.length > 0 && (
            <>
              {renderSelectReport()}
              <div className="p-3"></div>
            </>
          )}
          {detailReport?.overtimes?.length > 0 && renderOverTime()}
          {detailReport?.daily_reports?.length === 0 && detailReport?.overtimes?.length === 0 && (
            <div className={styles.noData}>
              <span>{t('common.noData')}</span>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default ModalDetailDailyReports;
