const configs = {
  API_DOMAIN: process.env.REACT_APP_ME_API_DOMAIN,
  API_DOMAIN_HR: process.env.REACT_APP_API_DOMAIN,
  API_DOMAIN_AMS: process.env.REACT_APP_API_DOMAIN_AMS,
  API_TOKEN_AMS: process.env.REACT_APP_API_TOKEN_AMS,
  API_DOMAIN_LOGIN: process.env.REACT_APP_DOMAIN_LOGIN,
  API_DOMAIN_SSO: process.env.REACT_APP_SSO_API_DOMAIN,
  API_DOMAIN_BWF: process.env.REACT_APP_API_DOMAIN_BWF,
  API_DOMAIN_NOTIFICATIONS: process.env.REACT_APP_API_NOTIFICATIONS,
  API_DOMAIN_PROJECT: process.env.REACT_APP_API_DOMAIN_PROJECT,
  SUBDOMAIN_TOKEN: process.env.REACT_APP_CLIENT_SUBDOMAIN_TOKEN,
  DOMAIN_WORK_FOLLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
  DOMAIN_APP_DOMAIN_AMS_ME: process.env.REACT_APP_DOMAIN_AMS_ME,
  DOMAIN_APP_DOMAIN_AMS_BO: process.env.REACT_APP_DOMAIN_AMS_BO,
  DOMAIN_AMS_RECRUIT: process.env.REACT_APP_DOMAIN_AMS_RECRUIT,
  DOMAIN_AMS_WORKFLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
  DOMAIN_AMS_ACCOUNTING: process.env.REACT_APP_DOMAIN_AMS_ACCOUNTIN,
  DOMAIN_AMS_PROJECT: process.env.REACT_APP_DOMAIN_AMS_PROJECT,
  DOMAIN_AMS_DIVISION: process.env.REACT_APP_DOMAIN_AMS_DIVISION,
  ID_ASSET_REQUEST: process.env.REACT_APP_ID_ASSET_REQUEST,
  ID_OT_REQUEST: process.env.REACT_APP_ID_OT_REQUEST,
  ID_TIMESHEET_REQUEST: process.env.REACT_APP_ID_TIMESHEET_REQUEST,
  ID_ONSINAL: process.env.REACT_APP_ONSIGNAL_ME_ID,
  ID_SAFARI_ID: process.env.REACT_APP_ONSIGNAL_SAFARI_WEB_ME_ID,

  TYPE_OT: process.env.REACT_APP_TYPE_OT?.split(','),
  TYPE_GO_EARLY_COME_LATE: process.env.REACT_APP_TYPE_GO_EARLY_COME_LATE?.split(','),
  TYPE_ADDITIONAL_WORK: process.env.REACT_APP_TYPE_ID_ADDITIONAL_WORK?.split(','),
  TYPE_REMOTE: process.env.REACT_APP_TYPE_REMOTE?.split(','),
  TYPE_PAID_LEAVE: process.env.REACT_APP_TYPE_PAID_LEAVE?.split(','),
  TYPE_UNPAID_LEAVE: process.env.REACT_APP_UNPAID_LEAVE?.split(','),

  ID_ADDITIONAL_WORK: process.env.REACT_APP_ID_ADDITIONAL_WORK,
  ID_APPLY_FOR_UNPAID_LEAVE: process.env.REACT_APP_ID_UNPAID_LEAVE,
  ID_ON_LEAVE: process.env.REACT_APP_ID_ON_LEAVE,
  ID_REGISTER_COMPENSATION: process.env.REACT_APP_ID_REGISTER_COMPENSATION,
  ID_REGISTER_EARLY: process.env.REACT_APP_ID_REGISTER_EARLY,
  ID_REGISTER_FOR_REMOTE_WORK: process.env.REACT_APP_ID_REGISTER_FOR_REMOTE_WORK,
  ID_REGISTER_LATE: process.env.REACT_APP_ID_REGISTER_LATE,
  ID_REGISTER_OT_HOLIDAY: process.env.REACT_APP_ID_REGISTER_OT_HOLIDAY,
  ID_REGISTER_OT_SATURDAY: process.env.REACT_APP_ID_REGISTER_OT_SATURDAY,
  ID_REGISTER_OT_SUNDAY: process.env.REACT_APP_ID_REGISTER_OT_SUNDAY,
  ID_REGISTER_OT_WEEKDAYS: process.env.REACT_APP_ID_REGISTER_OT_WEEKDAYS,
  REACT_APP_ID_REGISTER_OT_EXPECT: process.env.REACT_APP_ID_REGISTER_OT_EXPECT,
  ID_LIST_CHECKBOX: process.env.REACT_APP_ID_LIST_CHECKBOX,
  DOMAIN_AMS_WIKI: process.env.REACT_APP_WIKI,
  EXCHANGE_WORK_DAYS: process.env.REACT_APP_EXCHANGE_WORK_DAYS?.split(','),
  NUMBER_LOG_WORK_DAYS_IN_PAST: process.env.REACT_APP_NUMBER_LOG_WORK_DAYS_IN_PAST
};

export default configs;
