import React, { useState, useContext, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import IconBo from 'assets/img/icons/acms-bo.svg';
import IconMe from 'assets/img/icons/acms-me.svg';
//eslint-disable-next-line
import IconRecruit from 'assets/img/icons/acms-recruit.svg';
import IconWorkFlow from 'assets/img/icons/acms-work-flow.svg';
import IconProject from 'assets/img/icons/acms-project.svg';
//eslint-disable-next-line
import IconWiki from 'assets/img/icons/wiki.svg';
import imgAvatar from 'assets/img/theme/avatar_default.png';
import NotificationIcon from 'assets/img/icons/notitification.svg';
import styles from './style.module.scss';
import { useGetNotifications, useGetTotalNotifications } from 'hook/useNotifications';
import { DEFAULT_LIMIT_LARGE } from 'constants/index.js';
import { ThemContext } from 'layouts/Member';
//eslint-disable-next-line
import {
  DEFAULT_PAGE,
  SYSTEM_ME,
  SYSTEM_BO,
  SYSTEM_WORKFLOW,
  SYSTEM_WIKI,
  SYSTEM_RECRUIT,
  SYSTEM_PROJECT,
} from 'constants/index.js';
import { customDate } from 'helpers/formatDate';
import Loading from 'components/Loading';
import { updateNotificationRead } from 'api/notification';
import { USE_TOTAL_GET_NOTIFICATIONS } from 'constants/index.js';
import { updateNotificationView } from 'api/notification';
import { USE_GET_NOTIFICATIONS } from 'constants/index.js';
import configs from 'config';
import { useInforAuth } from 'hook/useAuth';
import { toasts } from 'index';
import { updateNotificationViewRow } from 'api/notification';
import {
  getUrlNotificationToWiki,
  getUrlNotificationToRecruit,
  getUrlNotificationToMe,
  getUrlNotificationToProject,
} from 'helpers/notification';

const NotificationAgain = (prop) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const userId = useContext(ThemContext);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [systemId, setSystemId] = useState(SYSTEM_ME);
  const [total, setTotal] = useState(0);
  const [listNotification, setListNotification] = useState({ today: [], orther: [] });
  const notificationUpdate = useRef(null);

  const { data: dataNotifications, isLoading } = useGetNotifications({
    user_id: userId,
    system_id: systemId,
    page: page,
    size: DEFAULT_LIMIT_LARGE,
  });

  const { data: dataTotalNotifications } = useGetTotalNotifications({
    user_id: userId,
  });

  const { data: dataInforUser } = useInforAuth();
  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  React.useEffect(() => {
    if (dataInforUser?.user_id) {
      OneSignal.push(function () {
        OneSignal.init({
          appId: configs.ID_ONSINAL,
          safari_web_id: configs.ID_SAFARI_ID,
          notifyButton: {
            enable: false,
          },
          subdomainName: 'acm-test4',
        });
        OneSignal.setExternalUserId(String(dataInforUser?.user_id));
        OneSignal.getExternalUserId().then();
        OneSignal.on('notificationDisplay', function (event) {
          queryClient.invalidateQueries(USE_GET_NOTIFICATIONS);
          queryClient.invalidateQueries(USE_TOTAL_GET_NOTIFICATIONS);
          toasts.info(
            <div className="onsignal" onMouseOver={() => updateNotification(event?.data?.id)}>
              <img
                className="avatar-noti-onsignal"
                alt={event?.data?.sender_avatar ? event?.data?.sender_avatar : imgAvatar}
                src={event?.data?.sender_avatar ? event?.data?.sender_avatar : imgAvatar}
              />
              <span>{event.content}</span>
            </div>,
            {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
              display: 'none',
            },
          );
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInforUser]);

  const updateNotification = async (id) => {
    if (notificationUpdate.current !== id) {
      notificationUpdate.current = id;
      await updateNotificationViewRow({ notification_id: id, user_id: dataInforUser?.user_id });
      queryClient.invalidateQueries(USE_GET_NOTIFICATIONS);
    }
  };

  useEffect(() => {
    if (dataTotalNotifications) {
      let total = 0;
      dataTotalNotifications.forEach((item) => {
        if (item?.system_id > 0) {
          total += item?.total;
        }
      });
      setTotal(total);
      if (total) {
        document.title = `(${total}) ME`;
      } else {
        document.title = `ME`;
      }
    }
  }, [dataTotalNotifications]);

  const updateAllNotification = async (systemIdChange) => {
    if (total > 0) {
      // eslint-disable-next-line
      await updateNotificationView({ user_id: userId, system_id: systemIdChange ?? systemId });
      await queryClient.invalidateQueries(USE_TOTAL_GET_NOTIFICATIONS);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (total > 0) {
      updateAllNotification();
    }
    setOpen(!open);
  };

  const handleOnError = (e) => {
    e.target.src = imgAvatar;
  };

  useEffect(() => {
    if (dataNotifications?.list_noti?.length > 0) {
      let today = [];
      let orther = [];
      setListNotification((prev) => {
        dataNotifications?.list_noti?.forEach((element) => {
          if (customDate(element?.created_at, 'DD/MM/YYYY') === customDate(moment(), 'DD/MM/YYYY')) {
            today.push(element);
          } else {
            orther.push(element);
          }
        });

        return {
          today: [...prev?.today, ...today],
          orther: [...prev?.orther, ...orther],
        };
      });
    }
  }, [dataNotifications]);

  const fetchMore = () => {
    if (!isLoading) setPage((value) => value + 1);
  };

  const handleUpdateNotificationRead = async (idNotification) => {
    await updateNotificationRead({ id: idNotification, user_id: userId });
    setListNotification((prev) => {
      prev?.today?.forEach((element) => {
        if (element?.id === idNotification) {
          element.is_read = 1;
        }
      });
      prev?.orther?.forEach((element) => {
        if (element?.id === idNotification) {
          element.is_read = 1;
        }
      });
      return {
        today: [...prev?.today],
        orther: [...prev?.orther],
      };
    });
    await queryClient.removeQueries(USE_TOTAL_GET_NOTIFICATIONS);
  };

  const changeTab = async (systemIdChange) => {
    if (Number(systemIdChange) !== Number(systemId)) {
      setSystemId(systemIdChange);
      setPage(DEFAULT_PAGE);
      setListNotification({ today: [], orther: [] });
      await updateAllNotification(systemIdChange);
    }
  };

  const seenAll = async () => {
    await updateNotificationView({ user_id: userId });
    await queryClient.removeQueries(USE_GET_NOTIFICATIONS);
    await queryClient.invalidateQueries(USE_TOTAL_GET_NOTIFICATIONS);
    setPage(DEFAULT_PAGE);
    setListNotification({ today: [], orther: [] });
  };

  const getUrl = (type, requestID, item) => {
    if (!type && !requestID) return null;
    switch (systemId) {
      case SYSTEM_ME:
        return getUrlNotificationToMe(type, item);
      case SYSTEM_WIKI:
        return getUrlNotificationToWiki(type, item);
      case SYSTEM_WORKFLOW:
        return configs.DOMAIN_AMS_WORKFLOW + '/request?request_id=' + requestID;
      case SYSTEM_RECRUIT:
        const linkToRecruit = getUrlNotificationToRecruit(type, item);
        if (linkToRecruit) {
          return configs.DOMAIN_AMS_RECRUIT + linkToRecruit;
        }
        return null;
      case SYSTEM_PROJECT:
        const linkToProject = getUrlNotificationToProject(type, item);
        return configs.DOMAIN_AMS_PROJECT + linkToProject;
      default:
        return null;
    }
  };

  return (
    <div className={styles.menuNotification}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <div className={styles.layoutTooltip}>
                <div className={styles.head}>
                  <p className={styles.title}>{t('common.notification')}</p>
                  <p onClick={() => seenAll()} className={styles.seen}>
                    {t('notifications.seeAll')}
                  </p>
                </div>
                <div className={styles.notificationLayout}>
                  <div className={styles.tab}>
                    <p className={styles.titleTab}>{t('home.system')}</p>
                    <div className={styles.items}>
                      <div
                        className={`${styles.item} ${systemId === SYSTEM_ME && styles.active}`}
                        onClick={() => changeTab(SYSTEM_ME)}
                      >
                        <img src={IconMe} alt={IconMe} />
                        <div className={styles.itemContent}>
                          <p>{t('home.acmsMe')}</p>
                          <p>{t('home.employeeGate')}</p>
                        </div>
                        {dataTotalNotifications?.find((it) => it.system_id === SYSTEM_ME)?.total > 0 && (
                          <p className={styles?.total}>
                            {dataTotalNotifications &&
                            dataTotalNotifications?.find((it) => it.system_id === SYSTEM_ME)?.total > 99
                              ? '99+'
                              : dataTotalNotifications?.find((it) => it.system_id === SYSTEM_ME)?.total ?? '0'}
                          </p>
                        )}
                      </div>
                      <div
                        className={`${styles.item} ${systemId === SYSTEM_BO && styles.active}`}
                        onClick={() => changeTab(SYSTEM_BO)}
                      >
                        <img src={IconBo} alt={IconBo} />
                        <div className={styles.itemContent}>
                          <p>{t('home.acmsBo')}</p>
                          <p>{t('home.employee')}</p>
                        </div>
                        {dataTotalNotifications?.find((it) => it.system_id === SYSTEM_BO)?.total > 0 && (
                          <p className={styles?.total}>
                            {dataTotalNotifications &&
                            dataTotalNotifications?.find((it) => it.system_id === SYSTEM_BO)?.total > 99
                              ? '99+'
                              : dataTotalNotifications?.find((it) => it.system_id === SYSTEM_BO)?.total ?? '0'}
                          </p>
                        )}
                      </div>
                      <div
                        className={`${styles.item} ${systemId === SYSTEM_WORKFLOW && styles.active}`}
                        onClick={() => changeTab(SYSTEM_WORKFLOW)}
                      >
                        <img src={IconWorkFlow} alt={IconWorkFlow} />
                        <div className={styles.itemContent}>
                          <p>{t('home.acmsWorkFlow')}</p>
                          <p>{t('home.createRequest')}</p>
                        </div>
                        {dataTotalNotifications?.find((it) => it.system_id === SYSTEM_WORKFLOW)?.total > 0 && (
                          <p className={styles?.total}>
                            {dataTotalNotifications &&
                            dataTotalNotifications?.find((it) => it.system_id === SYSTEM_WORKFLOW)?.total > 99
                              ? '99+'
                              : dataTotalNotifications?.find((it) => it.system_id === SYSTEM_WORKFLOW)?.total ?? '0'}
                          </p>
                        )}
                      </div>
                      <div
                        className={`${styles.item} ${systemId === SYSTEM_WIKI && styles.active}`}
                        onClick={() => changeTab(SYSTEM_WIKI)}
                      >
                        <img src={IconWiki} alt={IconWiki} />
                        <div className={styles.itemContent}>
                          <p>{t('home.acmsWiki')}</p>
                          <p>{t('home.portal')}</p>
                        </div>
                        {dataTotalNotifications?.find((it) => it.system_id === SYSTEM_WIKI)?.total > 0 && (
                          <p className={styles?.total}>
                            {dataTotalNotifications &&
                            dataTotalNotifications?.find((it) => it.system_id === SYSTEM_WIKI)?.total > 99
                              ? '99+'
                              : dataTotalNotifications?.find((it) => it.system_id === SYSTEM_WIKI)?.total ?? '0'}
                          </p>
                        )}
                      </div>
                      <div
                        className={`${styles.item} ${systemId === SYSTEM_RECRUIT && styles.active}`}
                        onClick={() => changeTab(SYSTEM_RECRUIT)}
                      >
                        <img src={IconRecruit} alt={IconRecruit} />
                        <div className={styles.itemContent}>
                          <p>{t('home.acmsRecruit')}</p>
                          <p>{t('home.recruitment')}</p>
                        </div>
                        {dataTotalNotifications?.find((it) => it.system_id === SYSTEM_RECRUIT)?.total > 0 && (
                          <p className={styles?.total}>
                            {dataTotalNotifications &&
                            dataTotalNotifications?.find((it) => it.system_id === SYSTEM_RECRUIT)?.total > 99
                              ? '99+'
                              : dataTotalNotifications?.find((it) => it.system_id === SYSTEM_RECRUIT)?.total ?? '0'}
                          </p>
                        )}
                      </div>
                      <div
                        className={`${styles.item} ${systemId === SYSTEM_PROJECT && styles.active}`}
                        onClick={() => changeTab(SYSTEM_PROJECT)}
                      >
                        <img src={IconProject} alt={IconProject} />
                        <div className={styles.itemContent}>
                          <p>{t('home.acmsProject')}</p>
                          <p>{t('home.project')}</p>
                        </div>
                        {dataTotalNotifications?.find((it) => it.system_id === SYSTEM_PROJECT)?.total > 0 && (
                          <p className={styles?.total}>
                            {dataTotalNotifications &&
                            dataTotalNotifications?.find((it) => it.system_id === SYSTEM_PROJECT)?.total > 99
                              ? '99+'
                              : dataTotalNotifications?.find((it) => it.system_id === SYSTEM_PROJECT)?.total ?? '0'}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.contentNotication}`} id={'scroll-notifications'}>
                    <InfiniteScroll
                      scrollableTarget="scroll-notifications"
                      dataLength={dataNotifications?.total_row || 0}
                      next={fetchMore}
                      hasMore={isLoading || page * DEFAULT_LIMIT_LARGE < dataNotifications?.total_row}
                      className={styles.infiniteScroll}
                    >
                      {listNotification?.today?.length === 0 &&
                        listNotification?.orther?.length === 0 &&
                        !isLoading && <p className={`${styles.error} error`}>{t('common.notData')}</p>}
                      {listNotification?.today?.length > 0 && (
                        <p className={styles.titleContent}>{t('notifications.today')}</p>
                      )}
                      {listNotification?.today?.map((item, index) => {
                        return (
                          <Link
                            to={{
                              pathname: getUrl(item?.type, item?.request_id, item),
                            }}
                            className={styles.redirect}
                            target={!getUrl(item?.type, item?.request_id, item) ? '_self' : '_blank'}
                            key={index}
                          >
                            <div
                              className={styles.itemContent}
                              onClick={() => item.is_read === 0 && handleUpdateNotificationRead(item?.id)}
                            >
                              <div className={item.is_read === 0 ? styles.unRead : styles.read}></div>
                              <div className={styles.infor}>
                                <img
                                  onError={handleOnError}
                                  alt={item?.sender_avatar ? item?.sender_avatar : imgAvatar}
                                  src={item?.sender_avatar ? item?.sender_avatar : imgAvatar}
                                  className={`${styles.image}`}
                                />
                                <div className={styles.inforDetail}>
                                  {item?.name && <p>{item?.name}</p>}
                                  {item?.message && <p>{item?.message}</p>}
                                  <p>{customDate(new Date(item?.created_at), 'HH:mm DD/MM/YYYY')}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                      {listNotification?.orther?.length > 0 && (
                        <p className={`${styles.titleContent} ${styles.old}`}>{t('notifications.older')}</p>
                      )}
                      {listNotification?.orther?.map((item) => {
                        return (
                          <a
                            href={getUrl(item?.type, item?.request_id, item)}
                            className={styles.redirect}
                            target="blank"
                          >
                            <div
                              className={styles.itemContent}
                              onClick={() => item.is_read === 0 && handleUpdateNotificationRead(item?.id)}
                            >
                              <div className={item.is_read === 0 ? styles.unRead : styles.read}></div>
                              <div className={styles.infor}>
                                <img
                                  onError={handleOnError}
                                  alt={item?.sender_avatar ? item?.sender_avatar : imgAvatar}
                                  src={item?.sender_avatar ? item?.sender_avatar : imgAvatar}
                                  className={`${styles.image}`}
                                />
                                <div className={styles.inforDetail}>
                                  {item?.name && <p>{item?.name}</p>}
                                  {item?.message && <p>{item?.message}</p>}
                                  <p>{customDate(new Date(item?.created_at), 'HH:mm DD/MM/YYYY')}</p>
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })}
                    </InfiniteScroll>
                    {isLoading && <Loading addClass={styles.loading} />}
                  </div>
                </div>
              </div>
            }
            className={styles.toolTipNotification}
            arrow
          >
            <Button onClick={handleTooltipOpen} className={styles.buttonNoti}>
              {total > 0 && (
                <span className={styles.numberInfo}>
                  {total < 100 && total}
                  {total > 99 && '99+'}
                </span>
              )}
              <img alt={NotificationIcon} src={NotificationIcon} className={styles.iconNoti} />
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default NotificationAgain;
