import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import config from '../../../config';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import styles from './style.module.scss';
import IconResource from 'assets/img/icons/dashboard/resource.svg';
import { ThemContext } from 'layouts/Member';
import { Button, Dropdown, Menu, Tooltip, message } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { USE_GET_ALL_DAILY_REPORT, REPORT_PENDING, REPORT_APPROVED } from 'constants/Projects';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE } from '../Projects/DailyReport/DailyReport';
import { apiGetAllDailyReport, apiDeleteDailyReport } from 'api/projects';
import { useMutation, useQuery } from 'react-query';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { CreateDailyReport, UpdateDailyReport } from '../Projects/DailyReport/create-update';
import { MdDelete, MdEdit } from 'react-icons/md';
import { formatDatePiker } from 'helpers/formatDate';
import moment from 'moment';
import { MAX_LENGTH_15 } from 'constants/index';
import { useGetProjectAllocations, useGetProjectAllocationsByMonth } from 'hook/useDashboard.js';
import showConfirm from '../Projects/DailyReport/create-update/showConfirm';

const Request = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetProjectAllocations(useContext(ThemContext));
  const { data: allocByMonth } = useGetProjectAllocationsByMonth(useContext(ThemContext));
  const filter = {
    page: DEFAULT_CURRENT,
    limit: DEFAULT_PAGE_SIZE,
    work_date: formatDatePiker(moment(), 'YYYY-MM-DD'),
  };
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [selectReport, setSelectReport] = useState();
  const [isOpenEditReport, setIsOpenEditReport] = useState(false);

  const {
    data: listDailyReport,
    isLoading: isLoadingReports,
    refetch: refetchDaiLyReports,
  } = useQuery([USE_GET_ALL_DAILY_REPORT, filter], () => apiGetAllDailyReport({ ...filter }), {
    select: (res) => {
      const reports = res?.data?.data?.data;
      if (reports.length < 5) return reports;
      return reports.filter((_, index) => index < 5);
    },
    onError: (error) => console.log(error),
  });

  const deleteDailyReport = useMutation((id) => apiDeleteDailyReport(id), {
    onSuccess: (res) => {
      message.success(t('common.deleteDataSuccess'));
      refetchDaiLyReports();
    },
    onError: (error) => console.log(error),
  });

  const menu = (
    <Menu
      items={[
        {
          key: 1,
          label: (
            <Button icon={<MdEdit />} className={styles.editBtn} onClick={() => setIsOpenEditReport(!isOpenEditReport)}>
              {t('boxInfoEmployee.dashboard.editReport')}
            </Button>
          ),
        },
        { label: <hr className={styles.hrMenu} /> },
        {
          key: 2,
          label: (
            <div
              className={styles.deleteBtn}
              onClick={() =>
                showConfirm({
                  content: (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('project.daily.listReport.deleteConfirm', { taskName: selectReport?.title }),
                      }}
                    />
                  ),
                  width: 420,
                  onOk() {
                    if (deleteDailyReport?.isLoading) return;
                    deleteDailyReport.mutate(selectReport?.id);
                  },
                })
              }
            >
              <MdDelete />
              <span>{t('boxInfoEmployee.dashboard.deleteReport')}</span>
            </div>
          ),
        },
      ]}
      className={styles.menu}
    />
  );

  const getTime = (format) => {
    return formatDatePiker(moment(), format);
  };

  const renderAllocation = (alloc) => {
    if (alloc && String(alloc).indexOf('.') >= 0) {
      alloc = alloc.toFixed(2);
    }

    let color = 'color-charcoal';
    if (alloc && alloc > 120) {
      color = 'color-red';
    } else if (alloc && alloc > 80 && alloc <= 120) {
      color = 'color-green';
    } else if (alloc && alloc > 1 && alloc <= 80) {
      color = 'color-yellow';
    }

    return <span className={color}>{alloc}</span>;
  };

  return (
    <>
      <Box className={classNames(`border-show pd-15  ${styles.boxProject}`)}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <div className="body-top">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <img src={IconResource} alt={IconResource} className="icon" />
                  <h4 className="title-body">{t('boxInfoEmployee.dashboard.myResources')}</h4>
                </div>
              </div>
              <span className={styles.effortTitle}>
                {t('boxInfoEmployee.dashboard.effortDay', { date: getTime('DD/MM/YYYY') })}
              </span>
              <div className={classNames(`d-flex justify-content-between ${styles.projectInfoTop}`)}>
                <div className={styles.project}>
                  {t('boxInfoEmployee.dashboard.project')}
                  <span>{data?.total < 10 ? `0${data?.total}` : data?.total}</span>
                </div>
                <div className={styles.effort}>
                  {t('boxInfoEmployee.dashboard.effort')}
                  {renderAllocation(data?.allocations)}%
                </div>
              </div>
              <hr />
              <span className={styles.effortTitle}>
                {t('boxInfoEmployee.dashboard.effortMonth', { date: getTime('MM/YYYY') })}
              </span>
              <div className={classNames(`d-flex justify-content-between ${styles.projectInfoTop}`)}>
                <div className={styles.project}>
                  {t('boxInfoEmployee.dashboard.project')}
                  <span>{allocByMonth?.total < 10 ? `0${allocByMonth?.total}` : allocByMonth?.total}</span>
                </div>
                <div className={styles.effort}>
                  {t('boxInfoEmployee.dashboard.effort')}
                  {renderAllocation(allocByMonth?.allocations)}%
                </div>
              </div>
              <hr />
              {isLoading && (
                <p className="error-no-data">
                  <Loading addClass="mt-3" />
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.listProject}>
              {data?.projects.map((item) => (
                <div className={styles.itemProject} key={item.id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className={styles.projectName}>
                      <Tooltip title={item?.name} color="#fff" overlayClassName="custom-tooltip">
                        {item?.name}
                      </Tooltip>
                    </div>
                    <div className={styles.effort}>
                      {String(item?.allocation).indexOf('.') >= 0 ? item?.allocation.toFixed(2) : item?.allocation}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Grid>
          <div className={styles.detail}>
            <a href={config.DOMAIN_AMS_PROJECT} target="_blank" rel="noreferrer">
              {t('boxInfoEmployee.showDetail')}
            </a>
          </div>
        </Grid>
      </Box>
      <Box className={classNames(`border-show pd-15  ${styles.boxDailyReport}`)}>
        <Grid container direction={'row'}>
          <Grid item xs={12}>
            <div className="body-top mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <img src={IconResource} alt={IconResource} className="icon" />
                  <h4 className="title-body">{t('boxInfoEmployee.dashboard.dailyReport')}</h4>
                </div>
                <Button
                  type="primary"
                  icon={<AiOutlinePlus size={20} />}
                  className={styles.btnReport}
                  onClick={() => setIsOpenModalCreate(!isOpenModalCreate)}
                >
                  {t('boxInfoEmployee.dashboard.report')}
                </Button>
              </div>
            </div>
          </Grid>

          {isLoadingReports ? (
            <p className="d-flex justify-content-center w-100">
              <Loading addClass="mt-3" />
            </p>
          ) : (
            <Grid item xs={12}>
              <div className={styles.listDailyReport}>
                {listDailyReport.length > 0 ? (
                  listDailyReport?.map((report) => (
                    <div
                      className={`${styles.report} ${
                        report.status === REPORT_PENDING
                          ? styles.pending
                          : report.status === REPORT_APPROVED
                          ? styles.success
                          : styles.reject
                      }`}
                    >
                      <div className={styles.reportName}>
                        <Tooltip title={report?.project?.name} overlayClassName="custom-tooltip" color="white">
                          <span className={`${styles.textOver1Lines}`}>
                            {`${report?.project?.name?.substring(0, MAX_LENGTH_15)} ${
                              report?.project?.name?.length >= MAX_LENGTH_15 ? '...' : ''
                            }`}
                          </span>
                        </Tooltip>
                      </div>
                      <div>{`${report.actual_time} ${t('boxInfoEmployee.dashboard.hour')}`}</div>
                      <div className={styles.action}>
                        <Dropdown
                          overlay={menu}
                          className={styles.menu}
                          placement="bottomRight"
                          trigger={['click']}
                          disabled={report.status === REPORT_APPROVED}
                        >
                          <BsThreeDotsVertical
                            size={24}
                            className={report.status === REPORT_APPROVED ? styles.disableMenu : styles.actionIcon}
                            onClick={() => setSelectReport(report)}
                          />
                        </Dropdown>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="error">{t('common.notData')}</p>
                )}
              </div>
            </Grid>
          )}
          <div className={styles.detail}>
            <a href={'/project/daily-report'} target="_blank" rel="noreferrer">
              {t('boxInfoEmployee.showDetail')}
            </a>
          </div>
        </Grid>
      </Box>
      <CreateDailyReport
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        refetchDaiLyReports={refetchDaiLyReports}
      />
      <UpdateDailyReport
        isOpen={isOpenEditReport}
        setIsOpen={setIsOpenEditReport}
        refetchDaiLyReports={refetchDaiLyReports}
        selectedReport={selectReport}
      />
    </>
  );
};

export default Request;
