import { Button, Grid } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import AddIcon from '@mui/icons-material/Add';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import styles from './styles.module.scss';
import { getStartOfMonth, getEndOfMonth } from 'helpers/formatDate';
import { getDayOfWeek } from 'helpers/formatDate';
import paidLeaveIcon from 'assets/img/icons/paid-leave.svg';
import holidayIcon from 'assets/img/icons/holiday.svg';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import preIcon from 'assets/img/icons/pre-icon.svg';
import nextIcon from 'assets/img/icons/next-icon.svg';
import filterClockIcon from 'assets/img/icons/background-time-sheet.svg';
import calendarDayOff from 'assets/img/icons/calendar-dayoff.svg';
import calendarIcon from 'assets/img/icons/calendar-icon1.svg';
import docsIcon from 'assets/img/icons/docs-icon.svg';
import clockIcon from 'assets/img/icons/clock-icon.svg';
import moneyIcon from 'assets/img/icons/money-icon.svg';
import unpaidLeaveIcon from 'assets/img/icons/unpaid-leave.svg';
import otTimekeepingIcon from 'assets/img/icons/ot-timekeeping.svg';
import approvedIcon from 'assets/img/icons/approved.svg';
import refuseIcon from 'assets/img/icons/refuse.svg';
import progressIcon from 'assets/img/icons/progress.svg';
import remoteTimeKeepingIcon from 'assets/img/icons/remote-timekeeping.svg';
import additionalWorkTimekeepingIcon from 'assets/img/icons/additional-work-timekeeping.svg';
import goEarlyComeLate from 'assets/img/icons/go-early-come-late.svg';
import Modal from 'components/Modal';
import { customDate } from 'helpers/formatDate';
import ButtonComponent from 'components/Buttons';
import {
  useGetScheduleRequestLeader,
  useGetHolidayScheduleRequestLeader,
  useTotalTimeSheet,
  useGetSchedule,
  useGetDetailRequest,
  useGetListUserNextPersonUpdate,
} from 'hook/useTimeSheet';
import Breadcrumb from '../index';
import { formatNumberCurrency } from 'helpers/format';
import { ThemContext } from 'layouts/Member';
import configs from 'config';
import Loading from 'components/Loading';
import sunIcon from 'assets/img/icons/sun.svg';
import afterNoonIcon from 'assets/img/icons/after-noon.svg';
import { APPROVE } from 'constants/TimeSheet';
import { NOT_APPROVED } from 'constants/TimeSheet';
import { MAX_LENGTH_30 } from 'constants/index.js';
import { apiUpdateRequest, apiRejectRequest } from 'api/timesheet';
import { HTTP_OK } from 'constants/StatusCode';
import { USE_GET_SCHEDULE_REQUEST_LEADER } from 'constants/TimeSheet';
import AlertComponent from 'components/Alert';
import { handleOnError } from 'helpers';
import { USE_GET_LIST_USER_NEXT_PERSON_UPDATE } from 'constants/TimeSheet';
import { USE_GET_DETAIL_REQUEST } from 'constants/TimeSheet';
import styleSelect from 'components/Select/style.module.scss';

const weekDay = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0,
};

const Schedule = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(),
  });
  const userId = useContext(ThemContext);
  const queryClient = useQueryClient();

  const [selectMonth, setSelectMonth] = useState(getStartOfMonth());
  const [listDate, setListDate] = useState();
  const [dataHoliday, setDataHoliday] = useState();
  const [calendarData, setCalendarData] = useState({});
  const [message, setMessage] = useState();
  const [isShowAlert, setShowAlert] = useState(false);
  const [isShowAlertError, setShowAlertError] = useState(false);
  const [isModal, setIsModal] = useState();
  const [filter, setFilter] = useState();
  const [filterListUserNext, setFilterListUserNext] = useState({ key_word: '', page: 1 });
  const [dataUser, setDataUser] = useState([]);
  const [disable, setDisable] = useState({ accept: false, refuse: false });
  const [valueSelect, setValueSelect] = useState("");
  const { data, isLoading } = useGetScheduleRequestLeader({
    category_id: [configs.ID_TIMESHEET_REQUEST, configs.ID_OT_REQUEST],
    month: customDate(selectMonth, 'MM/YYYY'),
  });

  const { data: holiday } = useGetHolidayScheduleRequestLeader({
    filter: customDate(selectMonth, 'YYYY'),
  });

  const { data: detailRequest, isLoading: isLoadingDetailRequest } = useGetDetailRequest({
    id: filter?.id,
    request_type: filter?.request_tyoe,
  });

  const { data: listUserAcceptNext } = useGetListUserNextPersonUpdate(
    filterListUserNext?.u_workflow_id,
    filterListUserNext?.step,
    { ...filterListUserNext, u_workflow_id: null, step: null },
  );

  useEffect(() => {
    setFilterListUserNext((prev) => {
      return { ...prev, u_workflow_id: detailRequest?.data?.u_workflow_id, step: detailRequest?.data?.step };
    });
  }, [detailRequest]);

  const { data: totalTimeSheet } = useTotalTimeSheet({ userId, date: customDate(selectMonth, 'MM/YYYY') });
  const { data: uGetSchedule } = useGetSchedule({ user_id: userId });

  const week = [
    {
      id: 1,
      value: t('common.monday'),
    },
    {
      id: 2,
      value: t('common.tuesday'),
    },
    {
      id: 3,
      value: t('common.wednesday'),
    },
    {
      id: 4,
      value: t('common.thursday'),
    },
    {
      id: 5,
      value: t('common.friday'),
    },
    {
      id: 6,
      value: t('common.saturday'),
    },
    {
      id: 7,
      value: t('common.sunday'),
    },
  ];

  useEffect(() => {
    const startOfMonth = getStartOfMonth(selectMonth);
    const endOfMonth = getEndOfMonth(selectMonth);

    const dayStart = getDayOfWeek(startOfMonth);
    const dayEnd = getDayOfWeek(endOfMonth);

    const startDate = moment(startOfMonth)
      .subtract(dayStart === weekDay.sunday ? 6 : dayStart - 1, 'day')
      .format('YYYY/MM/DD');
    const endDate = moment(endOfMonth)
      .add(7 - dayEnd, 'day')
      .format('YYYY/MM/DD');

    const dates = [];

    for (let date = startDate; date <= endDate; date = moment(date).add(1, 'day').format('YYYY/MM/DD')) {
      dates.push(date);
    }
    setListDate(dates);
  }, [selectMonth]);

  useEffect(() => {
    if (data) {
      const cloneDataRequest = [];
      data?.data?.forEach((item) => {
        let hrDateLog = moment(item.request_date, 'DD/MM/YYYY').format('YYYY/MM/DD');
        if (cloneDataRequest[hrDateLog]) {
          cloneDataRequest[hrDateLog] = [...(cloneDataRequest[hrDateLog] = item), item];
        } else {
          cloneDataRequest[hrDateLog] = item;
        }
      });

      setCalendarData(cloneDataRequest);
    }
  }, [data]);

  useEffect(() => {
    if (holiday) {
      const cloneDataRequest = [];
      holiday?.forEach((item) => {
        if (item.start_date !== item.end_date) {
          let startDate = moment(item.start_date, 'DD/MM/YYYY');
          let endDate = moment(item.end_date, 'DD/MM/YYYY');
          let diffDay = endDate.diff(startDate, 'days');
          if (diffDay) {
            for (let i = 0; i < diffDay + 1; i++) {
              cloneDataRequest[moment(startDate).add(i, 'days').format('YYYY/MM/DD')] = 'holiday';
            }
          }
        } else {
          let startDate = moment(item.start_date, 'DD/MM/YYYY').format('YYYY/MM/DD');
          cloneDataRequest[startDate] = 'holiday';
        }
      });
      setDataHoliday(cloneDataRequest);
    }
  }, [holiday]);

  const checkType = (data) => {
    if (data.status === 1) {
      return styles.notAccept;
    }
    if (configs.TYPE_OT.includes(String(data.type))) {
      return styles.ot;
    }
    if (configs.TYPE_UNPAID_LEAVE.includes(String(data.type))) {
      return styles.unpaidLeave;
    }
    if (configs.TYPE_PAID_LEAVE.includes(String(data.type))) {
      return styles.paidLeave;
    }
    if (configs.TYPE_GO_EARLY_COME_LATE.includes(String(data.type))) {
      return styles.goEarlyComeLate;
    }
    if (configs.TYPE_REMOTE.includes(String(data.type))) {
      return styles.remote;
    }
    if (configs.TYPE_ADDITIONAL_WORK.includes(String(data.type))) {
      return styles.addtionalWork;
    }
  };

  const checkTypeBorder = (data) => {
    if (configs.TYPE_OT.includes(String(data.type))) {
      return styles.bdOt;
    }
    if (configs.TYPE_UNPAID_LEAVE.includes(String(data.type))) {
      return styles.bdUnpaidLeave;
    }
    if (configs.TYPE_PAID_LEAVE.includes(String(data.type))) {
      return styles.bdPaidLeave;
    }
    if (configs.TYPE_GO_EARLY_COME_LATE.includes(String(data.type))) {
      return styles.bdGoEarlyComeLate;
    }
    if (configs.TYPE_REMOTE.includes(String(data.type))) {
      return styles.bdRemote;
    }
    if (configs.TYPE_ADDITIONAL_WORK.includes(String(data.type))) {
      return styles.bdAddtionalWork;
    }
  };

  const seeDetail = (data) => {
    setFilter({ id: data?.id, request_type: data?.type, status: data?.status, workflow_id: data?.workflow_id, is_reject: data?.is_reject });
    setIsModal(true);
  };

  const watchNextPerson = useWatch({
    control,
    name: 'next_person',
  });

  const onSubmit = async (data) => {
    setDisable((prev) => {
      return { ...prev, accept: true };
    });
    const fb = new FormData();
    watchNextPerson?.forEach((item) => {
      fb.append('next_person[]', item);
    });
    fb.append('ok' + String(Number(detailRequest?.data?.step) - 1), true);
    fb.append('notok' + String(Number(detailRequest?.data?.step) - 1), '');
    fb.append('is_edit', false);
    fb.append('request_id', detailRequest?.data?.u_workflow_id);
    let response = await apiUpdateRequest(fb, detailRequest?.data?.u_workflow_id);

    if (response?.status === HTTP_OK) {
      setValue('next_person', []);
      setDataUser([]);
      setFilter((prev) => {
        return { ...prev, page: 1, key_word: '' };
      });
      queryClient.removeQueries(USE_GET_LIST_USER_NEXT_PERSON_UPDATE);
      setMessage(t('timekeeping.approvedSuccessfully'));
      setShowAlert(true);
      queryClient.invalidateQueries(USE_GET_SCHEDULE_REQUEST_LEADER);
      queryClient.invalidateQueries(USE_GET_DETAIL_REQUEST);
      clearErrors();
      setDisable((prev) => {
        return { ...prev, accept: false };
      });
      return;
    }

    if (response?.status === 400 || !response?.success) {
      setMessage(response?.data?.message?.[0]);
      setShowAlertError(true);
      clearErrors();
      setDisable((prev) => {
        return { ...prev, accept: false };
      });
      return;
    }
    setDisable((prev) => {
      return { ...prev, accept: false };
    });
  };

  const refuse = async () => {
    setDisable((prev) => {
      return { ...prev, refuse: true };
    });
    const fb = new FormData();
    fb.append('done_request', 1);
    fb.append('reason', ' ');
    if (
      [
        Number(configs.ID_REGISTER_OT_HOLIDAY),
        Number(configs.ID_REGISTER_OT_SATURDAY),
        Number(configs.ID_REGISTER_OT_SUNDAY),
        Number(configs.ID_REGISTER_OT_WEEKDAYS),
        Number(configs.ID_REGISTER_OT_SATURDAY),
        Number(configs.ID_REGISTER_COMPENSATION),
      ].includes(Number(filter?.workflow_id)) &&
      Number(Number(detailRequest?.data?.step) - 1) > 0
    ) {
      fb.append('ok' + String(Number(detailRequest?.data?.step) - 1), '');
      fb.append('notok' + String(Number(detailRequest?.data?.step) - 1), true);
    } else {
      fb.append('ok', '');
      fb.append('notok', true);
    }
    let response = await apiRejectRequest(fb, detailRequest?.data?.u_workflow_id);

    if (response?.status === HTTP_OK) {
      setValue('next_person', []);
      setDataUser([]);
      setFilter((prev) => {
        return { ...prev, page: 1, key_word: '' };
      });
      queryClient.invalidateQueries(USE_GET_LIST_USER_NEXT_PERSON_UPDATE);
      setMessage(t('timekeeping.refuseRequestSuccess'));
      setShowAlert(true);
      queryClient.invalidateQueries(USE_GET_SCHEDULE_REQUEST_LEADER);
      queryClient.invalidateQueries(USE_GET_DETAIL_REQUEST);
      clearErrors();
      setDisable((prev) => {
        return { ...prev, refuse: false };
      });
      return;
    }

    if (response?.status === 400 || !response?.success) {
      setMessage(response?.data?.message?.[0]);
      setShowAlertError(true);
      clearErrors();
      setDisable((prev) => {
        return { ...prev, refuse: false };
      });
      return;
    }
    setDisable((prev) => {
      return { ...prev, refuse: false };
    });
  };

  useEffect(() => {
    if (!isModal) {
      setValue('next_person', []);
      setDataUser([]);
      setFilter((prev) => {
        return { ...prev, page: 1, key_word: '' };
      });
      setFilterListUserNext({ key_word: '', page: 1 });
      // queryClient.invalidateQueries(USE_GET_LIST_USER_NEXT_PERSON_UPDATE);
    }
    // eslint-disable-next-line
  }, [isModal]);

  useEffect(() => {
    if (Number(listUserAcceptNext?.data?.last_page) > Number(filterListUserNext.page)) {
      setFilterListUserNext({ ...filterListUserNext, page: filterListUserNext.page + 1 });
    }
    if (listUserAcceptNext?.data?.data) {
      if (filter.page !== 1) {
        setDataUser([...dataUser, ...listUserAcceptNext?.data?.data]);
      } else {
        setDataUser([...listUserAcceptNext?.data?.data]);
      }
    }
    // eslint-disable-next-line
  }, [listUserAcceptNext]);

  return (
    <>
      <Grid container className={`${styles.minWidth1200} d-flex nav-fixed justify-content-between`}>
        <Grid className="menu-slider-around">
          <Breadcrumb />
        </Grid>
        <Grid item>
          <a href={`${configs.DOMAIN_WORK_FOLLOW}/request`} target="__blank">
            <ButtonComponent addClass={styles.export} icon={<AddIcon />} text={t('request.createRequest')} />
          </a>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={styles.minWidth1200}>
        <Grid item xs={9} className={styles.filterMonth}>
          <Button
            className={styles.preBtn}
            onClick={() => setSelectMonth(moment(selectMonth).subtract(1, 'month').format('YYYY/MM/DD'))}
          >
            <img src={preIcon} alt="" />
          </Button>
          <div className={styles.month}>
            {t('timekeeping.month')} {customDate(selectMonth, 'MM / YYYY')}
          </div>
          <Button
            className={styles.nextBtn}
            onClick={() => setSelectMonth(moment(selectMonth).add(1, 'month').format('YYYY/MM/DD'))}
          >
            <img src={nextIcon} alt="" />
          </Button>
        </Grid>
        <Grid item xs={9}>
          {isLoading && (
            <div className="center">
              <Loading addClass="mt-3 mb-3" />
            </div>
          )}
          {!isLoading && listDate && (
            <div className={styles.calendar}>
              <div className={styles.calendarHeader}>
                <div className={styles.top}>
                  <div className="d-flex align-items-center mr-4">
                    <img src={holidayIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.holiday')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={paidLeaveIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.totalOffDayHasSalary')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={unpaidLeaveIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.totalOffDayNoSalary')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={goEarlyComeLate} alt="" className="mr-2" />
                    <div>{t('timekeeping.goEarlyComeLate')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={additionalWorkTimekeepingIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.registerAdditionalWork')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={remoteTimeKeepingIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.remoteEN')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={otTimekeepingIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.dashboard.OT')}</div>
                  </div>
                </div>
                <div className={styles.bottom}>
                  {week.map((el) => (
                    <div key={el.id} className={styles.weekDay}>
                      {el.value}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.calendarBody}>
                {listDate?.map((date) => (
                  <div className={styles.date} key={date} id={date}>
                    {calendarData?.[date] ? (
                      <>
                        {dataHoliday?.[date] && <img src={holidayIcon} alt="" className={styles.holiday} />}
                        <div className={styles.borderLeft} />
                        <div className="d-flex justify-content-between">
                          <div className={styles.dateTime}>{customDate(date, 'DD/MM')}</div>
                        </div>
                        <div className={`${styles.listRequest}`}>
                          {calendarData?.[date]?.detail?.map((item) => (
                            <div
                              onClick={() => seeDetail(item)}
                              className={`${styles.itemRequest} ${checkType(item)} ${checkTypeBorder(item)}`}
                            >
                              <img
                                onError={handleOnError}
                                className={styles.avatarRequest}
                                src={item?.avatar || defaultAvatar}
                                alt={'sun-icon'}
                              />
                              <span>{item.name}</span>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        {dataHoliday?.[date] && <img src={holidayIcon} alt="" className={styles.holiday} />}
                        <div
                          className={classNames(styles.normalDate, {
                            [styles.disable]: moment(selectMonth).month() !== moment(date).month(),
                          })}
                        >
                          {customDate(date, 'DD/MM')}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </Grid>
        <Grid xs={3} item>
          <div className={styles.standardShift}>
            <img src={filterClockIcon} className={styles.backGround} alt="" />
            <p className={styles.title}>{t('timekeeping.standardShift')}</p>
            <p className={styles.time}>
              <span>
                <img src={sunIcon} alt={'sun-icon'} />
                {uGetSchedule?.hour_start_morning &&
                  uGetSchedule?.hour_end_morning &&
                  moment(uGetSchedule?.hour_start_morning, 'HH:mm:ss').format('HH:mm') +
                    ' - ' +
                    moment(uGetSchedule?.hour_end_morning, 'HH:mm:ss').format('HH:mm')}
              </span>
              <span className={styles.afterNoon}>
                <img src={afterNoonIcon} alt={'after-noon-icon'} />
                {uGetSchedule?.hour_start_afternoon &&
                  uGetSchedule?.hour_end_afternoon &&
                  moment(uGetSchedule?.hour_start_afternoon, 'HH:mm:ss').format('HH:mm') +
                    ' - ' +
                    moment(uGetSchedule?.hour_end_afternoon, 'HH:mm:ss').format('HH:mm')}
              </span>
            </p>
          </div>
          <div className={styles.dayOff}>
            <img src={calendarDayOff} alt="" />
            <div>
              <div className={styles.label}>{t('timekeeping.offHour')}</div>
              <div className={styles.value}>{totalTimeSheet?.day_offs}</div>
            </div>
          </div>
          <div className={styles.timeSheet}>
            <div className={styles.allTime}>
              <div className={classNames(styles.timeSheetItem, styles.special)}>
                <div className={styles.item}>
                  <img src={docsIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.totalWorkingTime')}</div>
                </div>
                <div className={styles.value}>
                  {totalTimeSheet && totalTimeSheet?.total + '/' + totalTimeSheet?.hour_default}
                </div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={clockIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.numberLateTime')}</div>
                </div>
                <div className={styles.value}>{totalTimeSheet?.late_time}</div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={clockIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.numberLateEarlyTimePropost')}</div>
                </div>
                <div className={styles.value}>
                  {totalTimeSheet
                    ? totalTimeSheet?.total_time_late_early + `/${totalTimeSheet?.default_time_late_early}`
                    : `0/${totalTimeSheet?.default_time_late_early || 0}`}
                </div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={moneyIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.totalMoney')}</div>
                </div>
                <div className={styles.value}>
                  {formatNumberCurrency(totalTimeSheet?.fines)} {t('common.currency')}
                </div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={calendarIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.totalOffDayHasSalaryUnit')}</div>
                </div>
                <div className={styles.value}>{totalTimeSheet?.paid_leave || 0}</div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={calendarIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.totalOffDayNoSalaryUnit')}</div>
                </div>
                <div className={styles.value}>{totalTimeSheet?.unpaid_leave || 0}</div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {isModal && (
        <Modal
          isShowModal={isModal}
          classNameAdd={styles.modalDetail}
          toggle={() => {
            if (isModal) {
              setValue('next_person', '');
            }
            setFilter();
            setIsModal((prev) => !prev);
          }}
          title={detailRequest?.data?.workflow_name}
        >
          <div className={styles.layout}>
            {isLoadingDetailRequest && (
              <div className={styles.center}>
                <Loading addClass="mt-3 mb-3" />
              </div>
            )}
            {!isLoadingDetailRequest && (
              <>
                <div className={`${styles.totalTimeSheetRequest}`}>
                  <div className={styles.head}>
                    <div>
                      <p className={styles.titleType}>{detailRequest?.data?.workflow_name}</p>
                      <p className={styles.statusType}>
                        {t('timekeeping.request.status')}:{' '}
                        <span className={ 
                          `${filter?.status === APPROVE && styles.waitStatus}
                          ${filter?.status === NOT_APPROVED && filter?.is_reject === false && styles.approvedStatus}
                          ${filter?.status === NOT_APPROVED && filter?.is_reject === true && styles.rejectedStatus}`
                        }>
                          {filter?.status === APPROVE && t('timekeeping.request.statusInProgress')}
                          {(filter?.status === NOT_APPROVED && filter?.is_reject === false) && t('timekeeping.approved')}
                          {(filter?.status === NOT_APPROVED && filter?.is_reject === true) && t('timekeeping.rejected')}
                        </span>
                      </p>
                    </div>
                  </div>
                  {detailRequest?.data?.list_step_show.map((item, index) => (
                    <div className={styles.body}>
                      <p className={styles.title}>{t('timekeeping.inforStep') + ' ' + (index + 1)}</p>
                      {item?.detailStep?.map((it) => {
                        return (
                          <Grid container spacing={2} className={styles.itemInfor}>
                            <Grid item className={styles.textNormal} xs={5}>
                              {it?.title}
                            </Grid>
                            <Grid item className={styles.textSpec} xs={7}>
                              {it?.value}
                            </Grid>
                          </Grid>
                        );
                      })}
                      {item?.groupStep?.map((it) => {
                        return (
                          <>
                            <p className={styles.title}>{it?.title}</p>
                            {it?.data?.map((dateContinue) => {
                              return (
                                <>
                                  {dateContinue?.map((dataRender) => {
                                    return (
                                      <>
                                        <Grid container spacing={2} className={styles.itemInfor}>
                                          <Grid item className={styles.textNormal} xs={5}>
                                            {dataRender?.title}
                                          </Grid>
                                          <Grid item className={styles.textSpec} xs={7}>
                                            {dataRender?.value}
                                          </Grid>
                                        </Grid>
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                      {item?.requestStep?.map((it) => {
                        return (
                          <>
                            <Grid container spacing={2} className={styles.itemInfor}>
                              <Grid item className={styles.textNormal} xs={5}>
                                {it?.title}
                              </Grid>
                              <Grid item className={styles.textSpec} xs={7}>
                                {it?.value}
                              </Grid>
                            </Grid>
                          </>
                        );
                      })}
                    </div>
                  ))}
                  {filter?.status === APPROVE && (
                    <div className={`${styles.body} ${styles.userAccept}`}>
                      <form className={styles.modalCreateContract} onSubmit={handleSubmit(onSubmit)}>
                        {!detailRequest?.data?.last_step && (
                          <>
                            <p className={styles.title}>{t('timekeeping.userAcceptContinue')}</p>
                            <div
                              item
                              xs={12}
                              className={classNames(styleSelect.selectComponent, styleSelect.selectComponentAuto)}
                            >
                              {dataUser && (
                                <Controller
                                  control={control}
                                  defaultValue={null}
                                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <Autocomplete
                                      multiple={true}
                                      disableClearable
                                      filterSelectedOptions
                                      autoHighlight
                                      getOptionDisabled={(option) => option.disabled}
                                      getOptionLabel={(option) => {
                                        const filterData = dataUser.filter((e) => e.email.includes(valueSelect));
                                        if (filterData.length > 0) {
                                          return option.email;
                                        }
                                        return option.name;
                                      }}
                                      onInputChange={(event, value) => {
                                        setValueSelect(value);
                                      }}
                                      onChange={(event, value) => {
                                        setValue(
                                          'next_person',
                                          value.map((item) => item.id),
                                        );
                                        field.onChange(value);
                                        setError('next_person', '');
                                      }}
                                      options={dataUser}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                          {...props}
                                          key={option.id}
                                        >
                                          <div>
                                            <img
                                              onError={handleOnError}
                                              className="avatar"
                                              src={option.avatar || defaultAvatar}
                                              alt=""
                                            />
                                          </div>
                                          <div>
                                            <p>{option.name}</p>
                                            <p>{option.email} - {option.position_name}</p>
                                          </div>
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          error={errors?.next_person?.message}
                                          helperText={error?.next_person?.message}
                                          label={t('timekeeping.request.selectUserAccept')}
                                          name="next_person"
                                          type="search"
                                          inputRef={ref}
                                          {...params}
                                          maxLength={MAX_LENGTH_30}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              )}
                              {errors?.next_person?.message && (
                                <p className={styles.error}>{errors.next_person.message}</p>
                              )}
                            </div>
                          </>
                        )}
                        <div className={styles.actionRequest}>
                          <div className={styles.actionBt}>
                            <ButtonComponent
                              text={t('timekeeping.OT.refuse')}
                              addClass="w-100 btn-bg-yellow2"
                              handleClick={refuse}
                              hasDisabled={disable.refuse}
                              isLoading={disable.refuse}
                            />
                          </div>
                          <div className={styles.actionBt}>
                            <ButtonComponent
                              text={t('timekeeping.accept')}
                              addClass="w-100"
                              type="submit"
                              handleClick={handleSubmit(onSubmit)}
                              hasDisabled={disable.accept}
                              isLoading={disable.accept}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
                <div className={styles.otherInfoRequest}>
                  <div>
                    <p className={styles.title}>{t('timekeeping.userCreateRequest')}</p>
                    {detailRequest?.data?.list_user_do?.[0] && (
                      <div className={styles.blockUser}>
                        <img
                          onError={handleOnError}
                          className={styles.avatarRequestOther}
                          src={detailRequest?.data?.list_user_do?.[0]?.avatar || defaultAvatar}
                          alt={'sun-icon'}
                        />
                        <div>
                          <p className={styles.date}>{detailRequest?.data?.list_user_do?.[0]?.user_name}</p>
                          <p className={styles.shift}>{detailRequest?.data?.list_user_do?.[0]?.position}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.topMg}>
                    <p className={styles.title}>{t('timekeeping.created_by')}</p>
                    {detailRequest?.data?.list_user_do?.[1] && (
                      <div className={styles.blockUser}>
                        <img
                          onError={handleOnError}
                          className={styles.avatarRequestOther}
                          src={detailRequest?.data?.list_user_do?.[1]?.avatar || defaultAvatar}
                          alt={'sun-icon'}
                        />
                        <div>
                          <p className={styles.date}>{detailRequest?.data?.list_user_do?.[1]?.user_name}</p>
                          <p className={styles.shift}>{detailRequest?.data?.list_user_do?.[1]?.position}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.topMg}>
                    <p className={styles.title}>{t('timekeeping.userFollow')}</p>
                    {detailRequest?.data?.list_follow?.map((item) => (
                      <div className={styles.blockUser}>
                        <img
                          onError={handleOnError}
                          className={styles.avatarRequestOther}
                          src={item?.avatar || defaultAvatar}
                          alt={'sun-icon'}
                        />
                        <div>
                          <p className={styles.date}>{item?.user_name}</p>
                          <p className={styles.shift}>{item?.position}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.topMg}>
                    <p className={styles.title}>{t('timekeeping.active')}</p>
                    <Timeline className={styles.timeline}>
                      {detailRequest?.data?.info_all_step?.map((item) => {
                        return (
                          <TimelineItem>
                            <TimelineSeparator>
                              {item.status === 4 && (
                                <img className={styles.iconActive} src={approvedIcon} alt={'sun-icon'} />
                              )}
                              {item.status === 3 && (
                                <img className={styles.iconActive} src={refuseIcon} alt={'sun-icon'} />
                              )}
                              {item.status === 2 && (
                                <img className={styles.iconActive} src={progressIcon} alt={'sun-icon'} />
                              )}
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <p className={styles.titleTimeLine}>{item?.updated_at}</p>
                              <p className={styles.typeTimeLine}>{item?.step_name}</p>
                              <div className={styles.blockUser}>
                                <img
                                  onError={handleOnError}
                                  className={styles.avatarRequestOther}
                                  src={item?.avatar || defaultAvatar}
                                  alt={'sun-icon'}
                                />
                                <div>
                                  <p className={styles.date}>{item?.user_name}</p>
                                  <p className={styles.shift}>{item?.position}</p>
                                </div>
                              </div>
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
      <AlertComponent
        toggle={() => {
          setShowAlert(!isShowAlert);
          setIsModal(false);
        }}
        isShowAlert={isShowAlert}
      >
        {message}
      </AlertComponent>
      <AlertComponent
        toggle={() => {
          setShowAlertError(!isShowAlertError);
        }}
        isShowAlert={isShowAlertError}
      >
        {message}
      </AlertComponent>
    </>
  );
};

export default Schedule;
