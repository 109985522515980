import moment from 'moment';

export const customDate = (date, format) => {
  if (!date) return;
  if (moment.isMoment(date)) {
    return date.format(format);
  }
  if (!format) {
    format = 'DD/MM/YYYY - HH:mm';
  }
  return moment(date).format(format);
};

export const getFormattedDate = (date, format) => {
  if (!date) return;
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  return month + format + day + format + year;
};
export const sendDate = (date, format) => {
  if (!date) return;
  if (!format) {
    format = 'DD/MM/YYYY';
  }
  return moment(date).format(format);
};

export const dateResponse = (date) => {
  if (!date) return;

  return moment(date, 'DD/MM/YYYY');
};

export const getStartOfMonth = (date, format = 'MM/DD/YYYY') => {
  return moment(date).startOf('month').format(format);
};

export const getEndOfMonth = (date, format = 'MM/DD/YYYY') => {
  return moment(date).endOf('month').format(format);
};

export const getDayOfWeek = (date) => {
  if (!date) return;

  return moment(date).day();
};

export const formatDatePiker = (date, format) => {
  if (!date) return;
  if (!format) {
    format = 'DD/MM/YYYY - HH:mm';
  }

  if (moment.isMoment(date)) {
    return date.format(format);
  }
  if ((typeof date === 'string' || date instanceof String) && date.includes('/')) {
    return moment(date, 'DD/MM/YYYY').format(format);
  }
  return moment(date).format(format);
};
