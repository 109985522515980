import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import imgOTbackground from 'assets/img/theme/OT.png';
import imgOTclock from 'assets/img/theme/OTclock.png';
import iconLateIcons from 'assets/img/icons/late.png';
import iconTimeLate from 'assets/img/icons/time_late.png';
import iconMoney from 'assets/img/icons/money.png';
import UploadAvatar from './UploadAvatar';
import styles from './styles.module.scss';
import AlertComponent from 'components/Alert';
import { ThemContext } from 'layouts/Member';
import ProgressBar from 'components/ProgressBar/index.js';
import { apiUpdateUserAvatar } from 'api/profileEmployee.js';
import { USE_INFOR_USER } from 'constants/Auth';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_INTERNAL_SERVER_ERROR } from 'constants/StatusCode.js';
import { formatNumberCurrency } from 'helpers/format.js';
import * as Constants from 'constants/ProfileEmployee.js';

const LayoutProfile = (props) => {
  const userId = useContext(ThemContext);
  const queryClient = useQueryClient();
  const { uProfileUser } = props;
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);

  const progressNow = uProfileUser?.percent_profile;
  const progressBar = [
    {
      bgcolor: '#2ECC71',
      completed: progressNow,
    },
  ];

  const handleUploadImg = async (avatar) => {
    const fb = new FormData();
    let response = {};
    if (avatar) {
      fb.append('file', avatar[0]);
      if (uProfileUser?.user_information?.avatar) {
        fb.append('file_deleted', uProfileUser?.user_information?.avatar);
      }
      response = await apiUpdateUserAvatar(fb, userId);
      if (
        (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY ||
          response?.data?.status === HTTP_INTERNAL_SERVER_ERROR) &&
        response?.data?.messages
      ) {
        setMessage(response.data.messages.file[0]);
        setShowAlert(true);
      }
      if (response?.data?.status === HTTP_OK) {
        setMessage(response.data.messages);
        setShowAlert(true);
        await queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
        await queryClient.invalidateQueries(USE_INFOR_USER);
      }
    }
  };

  return (
    <>
      <div className={styles.avatarBox}>
        <div className={styles.avatarImg}>
          <UploadAvatar
            dataImage={uProfileUser?.user_information?.avatar ? [uProfileUser.user_information.avatar] : ['']}
            onChangeImage={handleUploadImg}
          />
        </div>
        <div className={styles.avatarName}>{uProfileUser && uProfileUser?.user_information?.name}</div>
        <div className={styles.avatarPosition}>{uProfileUser && uProfileUser?.user_information?.position}</div>
        <div className={styles.avatarComplete}>{t('boxInfoEmployee.completeProfile')}</div>
        <div className={styles.avatarBar}>
          {progressBar.map((item, idx) => (
            <ProgressBar key={idx} bgcolor={item.bgcolor} completed={item.completed} />
          ))}
          {progressNow}%
        </div>
        <div className={styles.avatarContent}>{t('employees.email')}</div>
        <div className={styles.avatarContentText}>{uProfileUser && uProfileUser?.user_information?.email}</div>

        <div className={styles.avatarContent}>{t('boxInfoEmployee.userCode')}</div>
            <div className={styles.avatarContentText}>
              {uProfileUser && uProfileUser?.user_information?.code}
            </div>
        <div className={styles.avatarContent}>{t('boxInfoEmployee.manager')}</div>

        {uProfileUser?.manager ? (
          <div className={styles.avatarManager}> {uProfileUser?.manager?.user_name}</div>
        ) : (
          <div className={styles.avatarContentText}>{t('boxInfoEmployee.alertNotInfo')}</div>
        )}
        <div className={styles.avatarContent}>{t('common.typeTimeKeeping')}</div>
        <div className={styles.avatarContentText}>
          {uProfileUser && uProfileUser?.user_information?.special_timesheet}
        </div>
        <div className={styles.firstLineBox}>
          <div className={styles.miniBox}>
            <div>{uProfileUser?.timesheet?.day_off}</div>
            <div>{t('boxInfoEmployee.hoursOffForce')} </div>
          </div>
          <div className={styles.miniBox}>
            <div>{uProfileUser?.timesheet?.leave_day}</div>
            <div>{t('boxInfoEmployee.dateOffed')} </div>
          </div>
        </div>
        <div className={styles.miniBox2}>
          <div>
            <img alt="imgOTbackground" src={imgOTbackground} />
            <img alt="imgOTclock" src={imgOTclock} />
          </div>

          <div className={styles.OTBox}>{uProfileUser?.timesheet?.over_time}</div>
          <div>{t('boxInfoEmployee.hoursOT')} </div>
        </div>
        <div className={styles.miniBox3}>
          <div className={styles.miniBox3__item}>
            <div>
              <img alt="iconLateIcons" src={iconLateIcons} /> {t('boxInfoEmployee.timeLate')}
            </div>
            <div className="text-right">{uProfileUser?.timesheet?.total_late_day}</div>
          </div>
          <div className={styles.miniBox3__item}>
            <div>
              <img alt="iconTimeLate" src={iconTimeLate} /> {t('boxInfoEmployee.minuteLate')}
            </div>
            <div className="text-right">{uProfileUser?.timesheet?.late_time}</div>
          </div>
          <div className={styles.miniBox3__item}>
            <div>
              <img alt="iconMoney" src={iconMoney} /> {t('boxInfoEmployee.money')}
            </div>
            <div className="text-right">{formatNumberCurrency(uProfileUser?.timesheet?.fines)}</div>
          </div>
        </div>
        <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
          {message}
        </AlertComponent>
      </div>
    </>
  );
};

export default LayoutProfile;
