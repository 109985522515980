import { Grid } from '@mui/material';
import React, { useState } from 'react';
import Nav from '..';
import FilterDailyReport from './FilterDailyReport';
import TableDailyReport from './TableDailyReport';
import './../style.scss';
import { useQuery } from 'react-query';
import { USE_GET_ALL_DAILY_REPORT } from 'constants/Projects';
import { apiGetAllDailyReport } from 'api/projects';

export const PAGE_SIZE_OPTIONS = [20, 30, 50, 100];
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_CURRENT = 1;

const DailyReport = () => {
  const [filter, setFilter] = useState({
    page: DEFAULT_CURRENT,
    limit: DEFAULT_PAGE_SIZE,
  });

  const {
    data: listDailyReport,
    isFetching,
    refetch: refetchDaiLyReports,
  } = useQuery([USE_GET_ALL_DAILY_REPORT, filter], () => apiGetAllDailyReport({ ...filter }), {
    select: (res) => res?.data?.data,
    onError: (error) => console.log(error),
  });

  return (
    <section className="daily-report">
      <Grid container spacing={2}>
        <Grid container className="nav-fixed pl-15">
          <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
            <Grid container>
              <Nav />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FilterDailyReport filter={filter} setFilter={setFilter} />
      <TableDailyReport
        listDailyReport={listDailyReport}
        setFilter={setFilter}
        filter={filter}
        isFetching={isFetching}
        refetchDaiLyReports={refetchDaiLyReports}
      />
    </section>
  );
};

export default DailyReport;
