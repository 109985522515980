import '@fortawesome/fontawesome-free/css/all.min.css';
import Box from '@mui/material/Box';
import InputField from 'components/Input/InputField';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { BiLinkExternal } from 'react-icons/bi';
import { Col, Row } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
// import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import $ from 'jquery';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

// import iconDownload from '../../../../assets/img/icons/download.png';
import iconDocument from '../../../../assets/img/icons/document.png';

import styles from './../style.module.scss';
import * as Constants from '../../../../constants/ProfileEmployee.js';
import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import DateDayComponent from 'components/DateTime/DateDay.js';
import DocumentCommon from './DocumentCommon/DocumentCommon.js';
// import ListImage from './DocumentCommon/ListImage.js';
import ModalComponent from 'components/Modal';
// import PopupImage from 'components/PopupImage';
// import UploadImages from './UploadImagesCCCD';
import classNames from 'classnames';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from '../../../../constants/StatusCode.js';
import { TYPE_EXPORT_PDF } from './../../../../constants';
import { apiUpdateCCCD, apiCreateCCCD, apiExportCCCD, apiDownloadByLink, apiDeleteCCCD } from 'api/profileEmployee';
import { isNotNullObject } from 'helpers/index';
import { customDate } from 'helpers/formatDate';
import { DATA_TYPE_NUMBER } from 'constants/index.js';
import storageFC from 'helpers/storage';
import { removeSpecialCharactersHaveNumber } from 'helpers';

const DATA_POPUP_FORM = {
  front_image: '',
  back_image: '',
  area: '',
  verify_at: '',
  identity_card_number: '',
  type: Constants.TYPE_CCCD,
  img_deleted: '',
  error_identity_card_number: '',
  error_verify_at: '',
  error_area: '',
  error_image: '',
  error_max_size: '',
  error_back_image: '',
  error_front_image: '',
  error_user_id: '',
};

const CHECK_DEFAULT = {
  isDownload: false,
  number: -1,
};

const TYPE_CERTIFICATE = {
  CCCD: 'idcard',
  CV: 'imgCurriculumVitae',
  HEALTH: 'healthCertification',
  DEGREE: 'degreePhoto',
  BIRTH: 'birthCertificate',
  HOUSE: 'registrationBook',
  PASSPORT: 'passport',
};

const Document = (props) => {
  const queryClient = useQueryClient();
  const { uProfileUser, userId } = props;

  const { t } = useTranslation();
  const [isShowModalExport, setShowModalExport] = useState(false);
  const [textModal] = useState({
    titleEdit: t('document.modalAddTypePaper'),
  });
  const [message, setMessage] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [formPaper, setFormPaper] = useState(DATA_POPUP_FORM);
  const [isShowAlert, setShowAlert] = useState(false);
  const [stateButton, setStateButton] = useState(false);
  const [checkDownload, setCheckDownload] = useState(CHECK_DEFAULT);
  const [isInfoCCCD, setIsInfoCCCD] = useState(false);
  const [isShowModalAdd, setShowModalAdd] = useState({ status: false, type: Constants.TAB_TYPE_CCCD });
  // eslint-disable-next-line
  const [isShowImage, setIsShowImage] = useState(false);
  // eslint-disable-next-line
  const [imageLink, setImageLink] = useState('');
  const [typeCertificate, setTypeCertificate] = useState('');
  // eslint-disable-next-line
  const [detailCertificate, setDetailCertificate] = useState({});
  // eslint-disable-next-line
  const [detailCertificateDoc, setDetailCertificateDoc] = useState({});
  const dataDocumentCommon = {
    message: message,
    isShowAlert: isShowAlert,
    setShowAlert: setShowAlert,
    setMessage: setMessage,
    userId: userId,
    setShowModalAdd: setShowModalAdd,
  };

  const handleInput = (e) => {
    if (
      (e.target.name === 'identity_card_number' && Number(e.target.value) !== 'NaN' && e.target.value >= 0) ||
      e.target.name !== 'identity_card_number'
    ) {
      const error = {};
      if (e.target.name === 'identity_card_number') {
        error.error_identity_card_number = '';
      } else if (e.target.name === 'area') {
        e.target.value = removeSpecialCharactersHaveNumber(e.target.value);
        error.error_area = '';
      }
      if (!isChange) {
        setIsChange(true);
      }
      setFormPaper({
        ...formPaper,
        ...error,
        [e.target.name]: e.target.value,
      });
    }
  };

  $('input[type=tel]').keydown(function () {
    return false;
  });

  useEffect(() => {
    setFormPaper((prevState) => ({
      ...prevState,
      front_image: uProfileUser?.identification?.identity?.link[0]
        ? uProfileUser?.identification?.identity?.link[0]
        : '',
      back_image: uProfileUser?.identification?.identity?.link[1]
        ? uProfileUser?.identification?.identity?.link[1]
        : '',
      area: uProfileUser?.identification?.identity?.detail?.area
        ? uProfileUser?.identification?.identity?.detail?.area
        : '',
      identity_card_number: uProfileUser?.identification?.identity?.detail?.identity_card_number
        ? uProfileUser?.identification?.identity?.detail?.identity_card_number
        : '',
      verify_at: uProfileUser?.identification?.identity?.detail?.verify_at
        ? moment(uProfileUser.identification.identity.detail.verify_at, 'DD/MM/YYYY')
        : moment(),
      img_deleted: uProfileUser?.identification?.identity
        ? uProfileUser?.identification?.identity?.link[0] + ',' + uProfileUser?.identification?.identity?.link[1]
        : '',
    }));

    if (uProfileUser?.identification?.identity?.detail?.identity_card_number) {
      setIsInfoCCCD(true);
    } else {
      setIsInfoCCCD(false);
    }
  }, [uProfileUser, isShowModalAdd]);

  // eslint-disable-next-line
  const handleImageChange = (fileImage) => {
    if (!isChange) {
      setIsChange(true);
    }
    setFormPaper({
      ...formPaper,
      error_image: '',
      error_back_image: '',
      error_front_image: '',
      error_max_size_front: '',
      error_max_size_back: '',
      front_image: fileImage[0] ? fileImage[0] : '',
      back_image: fileImage[1] ? fileImage[1] : '',
    });
  };

  const handleDate = (value) => {
    if (value) {
      setFormPaper({
        ...formPaper,
        verify_at: value,
        error_verify_at: '',
      });
    }
  };

  function validate() {
    var error = {};
    var focus = true;
    if (!formPaper.identity_card_number) {
      error.error_identity_card_number = t('messages.pleaseFillOutThisField');
      if (focus) {
        $('input[name=identity_card_number]').focus();
        focus = false;
      }
    }
    if (!formPaper.identity_card_number > Constants.MAX_LENGTH_20) {
      error.error_identity_card_number = t('messages.pleaseEnterLessThanTwentyCharacters');
      if (focus) {
        $('input[name=identity_card_number]').focus();
        focus = false;
      }
    }
    if (!formPaper.verify_at) {
      error.error_verify_at = t('messages.pleaseFillOutThisField');
      if (focus) {
        $('input[name=verify_at]').focus();
        focus = false;
      }
    }
    if (!formPaper.area) {
      error.error_area = t('messages.pleaseFillOutThisField');
      if (focus) {
        $('input[name=area]').focus();
        focus = false;
      }
    }
    if (!formPaper.area > Constants.MAX_LENGTH_100) {
      error.error_area = t('messages.pleaseEnterLessThanHundredCharacters');
      if (focus) {
        $('input[name=area]').focus();
        focus = false;
      }
    }
    // if (!formPaper.back_image) {
    //   error.error_image = t('messages.pleaseUploadImageOfTheBackOfYourIdCard');
    // }
    // if (!formPaper.front_image) {
    //   error.error_image = t('messages.pleaseUploadImageOfTheFrontOfYourIdCard');
    // }

    if (isNotNullObject(error)) {
      setFormPaper({
        ...formPaper,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmitCCCD = async (event) => {
    if (!isChange) {
      setIsChange(true);
    }
    setFormPaper({
      ...formPaper,
      error_identity_card_number: '',
      error_verify_at: '',
      error_area: '',
      error_image: '',
      error_max_size_front: '',
      error_max_size_back: '',
    });
    setStateButton(true);
    event.preventDefault();
    if (!validate()) {
      setStateButton(false);
      return;
    }
    setFormPaper({
      ...formPaper,
      error_identity_card_number: '',
      error_verify_at: '',
      error_area: '',
      error_image: '',
      error_max_size_front: '',
      error_max_size_back: '',
    });

    const fb = new FormData();
    fb.append('user_id', userId);
    fb.append('area', formPaper.area);
    fb.append('verify_at', customDate(formPaper.verify_at, 'DD/MM/YYYY'));
    fb.append('identity_card_number', formPaper.identity_card_number);
    fb.append('type', formPaper.type);
    fb.append('img_deleted', formPaper.img_deleted);
    fb.append('front_image', formPaper.front_image);
    fb.append('back_image', formPaper.back_image);

    let response = {};
    if (!formPaper.img_deleted) {
      response = await apiCreateCCCD(fb);
    } else {
      response = await apiUpdateCCCD(fb);
    }

    if (
      (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY || response?.data?.status === HTTP_INTERNAL_SERVER_ERROR) &&
      response?.data?.messages
    ) {
      const msg = response.data.messages;
      setFormPaper({
        ...formPaper,
        error_identity_card_number: msg?.identity_card_number
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_verify_at: msg?.verify_at
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_area: msg?.area
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_back_image: msg?.back_image
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_front_image: msg?.front_image
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      setStateButton(false);
      return;
    }

    if (response?.data?.status === HTTP_OK) {
      setShowModalAdd((prev) => {
        return { ...prev, status: false };
      });
      setMessage(response.data.messages);
      setStateButton(false);
      setIsChange(false);
      await queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
    }
    setStateButton(false);
  };

  const handleDeleteCCCD = (identificationId) => {
    confirmAlert({
      title: t('common.doYouWantToDeletedInfoData'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteCCCD(identificationId);
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              setIsDelete(!isDelete);
              queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
            }

            if (response.data.status === HTTP_INTERNAL_SERVER_ERROR) {
              setMessage(response.data.messages);
              setShowAlert(true);
            }
          },
        },
      ],
    });
  };

  // eslint-disable-next-line
  const handleDownloadImage = async (linkImage, index) => {
    setCheckDownload({ ...checkDownload, isDownload: true, number: index });
    fetch(apiDownloadByLink(linkImage), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${storageFC.getToken()}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', linkImage.substr(-(linkImage.length - linkImage.lastIndexOf('/') - 1)));
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setCheckDownload({ ...checkDownload, ...CHECK_DEFAULT });
      });
  };

  const handleEdit = () => {
    setFormPaper({
      ...formPaper,
      verify_at: uProfileUser?.identification?.identity?.detail?.verify_at
        ? moment(uProfileUser.identification.identity.detail.verify_at, 'DD/MM/YYYY')
        : moment(),
      error_identity_card_number: '',
      error_verify_at: '',
      error_area: '',
      error_image: '',
      error_max_size_front: '',
      error_max_size_back: '',
    });
    setShowModalAdd(() => {
      return { type: Constants.TAB_TYPE_CCCD, status: true };
    });
  };
  const onShowModalExport = () => {
    if (isInfoCCCD) {
      setShowModalExport(true);
    } else {
      setMessage(t('messages.profileEmployee.messageNotExportCCCD'));
      setShowAlert(true);
    }
  };

  // eslint-disable-next-line
  const showImage = (linkImage, type) => {
    setIsShowImage(true);
    setImageLink(linkImage);
    setTypeCertificate(type);
  };

  // eslint-disable-next-line
  const renderNameOfCertificate = () => {
    switch (typeCertificate) {
      case TYPE_CERTIFICATE.CCCD:
        return t('document.citizenIdPhoto');
      case TYPE_CERTIFICATE.CV:
        return t('document.imgCurriculumVitae');
      case TYPE_CERTIFICATE.HEALTH:
        return t('document.imgHealthCertification');
      case TYPE_CERTIFICATE.BIRTH:
        return t('document.imgBirthCertificate');
      case TYPE_CERTIFICATE.DEGREE:
        return t('document.degreePhoto');
      case TYPE_CERTIFICATE.HOUSE:
        return t('document.imgRegistrationBook');
      case TYPE_CERTIFICATE.PASSPORT:
        return t('document.imgPassport');
      default:
        return '';
    }
  };

  // eslint-disable-next-line
  const handleSetDetailCertificate = (detail) => {
    setDetailCertificate(detail);
  };

  // eslint-disable-next-line
  const handleSetDetailCertificateDoc = (detail) => {
    setDetailCertificateDoc(detail);
  };

  return (
    <>
      <div className={styles.profileBoxDoc}>
        <Row className={styles.rowTitle}>
          <Col xs="9">
            <div className={styles.profileTitle}>
              <img alt={iconDocument} className={styles.imgTitle} src={iconDocument} />
              {t('infoEmployee.titleDocument')}
            </div>
          </Col>
          <Col xs="3" className={styles.buttonCmt}>
            <ButtonComponent
              className={styles.export}
              icon={<BiLinkExternal />}
              text="Export"
              handleClick={onShowModalExport}
            />
            <div className={classNames(`${styles.editDeleteButton} mr--15 ${styles.colorSilver}`)}>
              <HiPencil onClick={handleEdit} />
            </div>
          </Col>
        </Row>
        <div
          className={styles.profileContent}
          onClick={() => handleSetDetailCertificate(uProfileUser?.identification?.identity?.detail)}
        >
          <div className={styles.cmtContent}>
            <div className={styles.profileTitleChild}>{t('infoEmployee.imgCMT')}</div>
            {isInfoCCCD && (
              <div>
                <div className={styles.editDeleteButtonChild}>
                  <MdDelete onClick={() => handleDeleteCCCD(uProfileUser?.identification?.identity?.id)} />
                </div>
                <div className={styles.editDeleteButtonChild}>
                  <HiPencil onClick={handleEdit} />
                </div>
              </div>
            )}
          </div>
          <Row>
            <Col xs={12} lg={5} xl={5}>
              <div className={styles.profileContentInfo}>{t('infoEmployee.info')}</div>
              <Row>
                <Col xs={4} lg={5} xl={4}>
                  <div className={styles.profileContentInfo}>{t('infoEmployee.CCCD')}</div>
                  <div className={styles.profileContentInfo}>{t('infoEmployee.dayGive')}</div>
                  <div className={styles.profileContentInfo}>{t('infoEmployee.locationGive')}</div>
                </Col>
                {/* md="2 mb-3"  */}
                <Col className={styles.docText} xs={8} lg={7} xl={8}>
                  <div className={styles.profileContentInfo}>
                    {uProfileUser && uProfileUser?.identification?.identity?.detail?.identity_card_number === null ? (
                      <span>{t('infoEmployee.notUpdate')}</span>
                    ) : (
                      uProfileUser?.identification?.identity?.detail?.identity_card_number
                    )}
                  </div>
                  <div className={styles.profileContentInfo}>
                    {uProfileUser && uProfileUser?.identification?.identity?.detail?.identity_card_number === null ? (
                      <span>{t('infoEmployee.notUpdate')}</span>
                    ) : (
                      uProfileUser?.identification?.identity?.detail?.verify_at
                    )}
                  </div>
                  <div className={styles.profileContentInfo}>
                    {uProfileUser && uProfileUser?.identification?.identity?.detail?.identity_card_number === null ? (
                      <span>{t('infoEmployee.notUpdate')}</span>
                    ) : (
                      uProfileUser?.identification?.identity?.detail?.area
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalComponent
            toggle={() => {
              setIsChange(false);
              setShowModalExport(!isShowModalExport);
            }}
            isShowModal={isShowModalExport}
            title={t('common.export')}
          >
            <div className={styles.modalExportCCCD}>
              <Row>
                <Col md={12} className={styles.rowItem}>
                  <p className={styles.titleExport}>{t('infoEmployee.CCCD')}</p>
                  <p className={styles.body}>{uProfileUser?.identification?.identity?.detail?.identity_card_number}</p>
                  <hr />
                </Col>
                <Col md={12} className={styles.rowItem}>
                  <p className={styles.titleExport}>{t('infoEmployee.dayGive')}</p>
                  <p className={styles.body}>{uProfileUser?.identification?.identity?.detail?.verify_at}</p>
                  <hr />
                </Col>
                <Col md={12} className={styles.rowItem}>
                  <p className={styles.titleExport}>{t('infoEmployee.locationGive')}</p>
                  <p className={styles.body}>{uProfileUser?.identification?.identity?.detail?.area}</p>
                  <hr />
                </Col>
                <Col md={12}>
                  <ButtonComponent
                    className={styles.export}
                    text={t('common.export')}
                    type={TYPE_EXPORT_PDF}
                    addClass="w-100 mt-4"
                    apiLink={apiExportCCCD(userId)}
                    nameFile="cccd"
                  />
                </Col>
              </Row>
            </div>
          </ModalComponent>
        </div>
      </div>
      <ModalComponent
        toggle={() =>
          setShowModalAdd((prev) => {
            return { ...prev, status: !isShowModalAdd.status };
          })
        }
        isShowModal={isShowModalAdd.status}
        title={textModal.titleEdit}
        classNameAdd="edit-infor-paper"
      >
        <Tabs
          selectedIndex={isShowModalAdd.type}
          onSelect={(index) => {
            if (index !== isShowModalAdd.type && isChange) {
              confirmAlert({
                title: t('messages.theDataHasNotBeenSavedDoYouStillWantToSwitchTabs'),
                buttons: [
                  {
                    label: t('infoEmployee.cancel'),
                    className: 'btn-alert-cancel',
                  },
                  {
                    label: t('infoEmployee.ok'),
                    className: 'btn-alert-ok',
                    onClick: () => {
                      setShowModalAdd({ type: index, status: true });
                      setIsChange(false);
                    },
                  },
                ],
              });
            } else {
              setShowModalAdd({ type: index, status: true });
            }
          }}
        >
          <Row>
            <Col sm="4">
              <div className="title">
                <p>{t('document.selectPaper')}</p>
              </div>
              <TabList>
                <Tab>
                  <p>{t('document.cardCCCD')}</p>
                  <hr className="hr-border-bottom" />
                </Tab>
                <Tab>
                  <p>{t('document.curriculumVitae')}</p>
                  <hr className="hr-border-bottom" />
                </Tab>
                <Tab>
                  <p>{t('document.healthCertification')}</p>
                  <hr className="hr-border-bottom" />
                </Tab>
                <Tab>
                  <p>{t('document.degreePhoto')}</p>
                  <hr className="hr-border-bottom" />
                </Tab>
                <Tab>
                  <p>{t('document.birthCertificate')}</p>
                  <hr className="hr-border-bottom" />
                </Tab>
                <Tab>
                  <p>{t('document.registrationBook')}</p>
                  <hr className="hr-border-bottom" />
                </Tab>
                <Tab>
                  <p>{t('document.passport')}</p>
                  <hr className="hr-border-bottom" />
                </Tab>
              </TabList>
            </Col>
            <Col sm="8">
              <TabPanel>
                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmitCCCD}>
                  <Row className="head-modal">
                    <Col sm="10">
                      <p className="notification">{t('document.notification')}</p>
                    </Col>
                    <Col sm="2" className="button-save">
                      <ButtonComponent
                        text={t('common.save')}
                        addClass="w-100"
                        type="submit"
                        hasDisabled={stateButton}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <p className="title-img-cccd">{t('document.citizenIdPhoto')}</p>
                  </Row>
                  <Row className="input-modal-document">
                    <Col sm="6">
                      <InputField
                        label={t('document.numberCCCD')}
                        variant="outlined"
                        name="identity_card_number"
                        maxLength={Constants.MAX_LENGTH_20}
                        defaultValue={formPaper.identity_card_number}
                        value={formPaper.identity_card_number}
                        onHandleInput={handleInput}
                        error={formPaper.error_identity_card_number}
                        dataType={DATA_TYPE_NUMBER}
                      />
                    </Col>
                    <Col sm="6">
                      <div className="fieldset-group mb-4">
                        <DateDayComponent
                          label={t('document.dateRange')}
                          valueDefault={formPaper.verify_at}
                          onChangeHandle={handleDate}
                          error={formPaper.error_verify_at}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="input-modal-document">
                    <Col sm="6">
                      <InputField
                        label={t('document.issuedBy')}
                        variant="outlined"
                        name="area"
                        maxLength={Constants.MAX_LENGTH_100}
                        defaultValue={formPaper.area}
                        value={formPaper.area}
                        onHandleInput={handleInput}
                        error={formPaper.error_area}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className="img-cccd">
                      {/*<UploadImages*/}
                      {/*  dataImage={[formPaper.front_image, formPaper.back_image]}*/}
                      {/*  isDelete={isDelete}*/}
                      {/*  setIsDelete={() => setIsDelete(false)}*/}
                      {/*  isShowModal={isShowModalAdd.status}*/}
                      {/*  isData={*/}
                      {/*    uProfileUser?.identification?.identity?.link[0] ||*/}
                      {/*    uProfileUser?.identification?.identity?.link[1]*/}
                      {/*      ? true*/}
                      {/*      : false*/}
                      {/*  }*/}
                      {/*  onChangeImage={handleImageChange}*/}
                      {/*/>*/}
                    </Col>
                  </Row>
                  <p className="error">{formPaper.error_image}</p>
                  <p className="error">{formPaper.error_max_size_front}</p>
                  <p className="error">{formPaper.error_max_size_back}</p>
                  {/*<p className="error">{formPaper.error_back_image}</p>*/}
                  {/*<p className="error">{formPaper.error_front_image}</p>*/}
                </Box>
              </TabPanel>
              <TabPanel>
                <DocumentCommon
                  type={Constants.TYPE_CURRICULUM_VITAE}
                  imgOld={uProfileUser?.identification?.curriculum_vitae?.link}
                  dataDocumentCommon={dataDocumentCommon}
                  isModal={isShowModalAdd.status}
                  setIsChange={setIsChange}
                  isChange={isChange}
                />
              </TabPanel>
              <TabPanel>
                <DocumentCommon
                  type={Constants.TYPE_HEALTH_CERTIFICATE}
                  imgOld={uProfileUser?.identification?.health_certificate?.link}
                  dataDocumentCommon={dataDocumentCommon}
                  isModal={isShowModalAdd.status}
                  setIsChange={setIsChange}
                  isChange={isChange}
                />
              </TabPanel>
              <TabPanel>
                <DocumentCommon
                  type={Constants.TYPE_DEGREE}
                  imgOld={uProfileUser?.identification?.degree?.link}
                  dataDocumentCommon={dataDocumentCommon}
                  isModal={isShowModalAdd.status}
                  setIsChange={setIsChange}
                  isChange={isChange}
                />
              </TabPanel>
              <TabPanel>
                <DocumentCommon
                  type={Constants.TYPE_BIRTH_CERTIFICATE}
                  imgOld={uProfileUser?.identification?.birth_certificate?.link}
                  dataDocumentCommon={dataDocumentCommon}
                  isModal={isShowModalAdd.status}
                  setIsChange={setIsChange}
                  isChange={isChange}
                />
              </TabPanel>
              <TabPanel>
                <DocumentCommon
                  type={Constants.TYPE_REGISTRATION_BOOK}
                  imgOld={uProfileUser?.identification?.registration_book?.link}
                  dataDocumentCommon={dataDocumentCommon}
                  isModal={isShowModalAdd.status}
                  setIsChange={setIsChange}
                  isChange={isChange}
                />
              </TabPanel>
              <TabPanel>
                <DocumentCommon
                  type={Constants.TYPE_PASSPORT}
                  imgOld={uProfileUser?.identification?.passport?.link}
                  dataDocumentCommon={dataDocumentCommon}
                  isModal={isShowModalAdd.status}
                  setIsChange={setIsChange}
                  isChange={isChange}
                />
              </TabPanel>
            </Col>
          </Row>
        </Tabs>
      </ModalComponent>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default Document;
