import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import styles from './style.module.scss';
import IconAsset from 'assets/img/icons/dashboard/asset.svg';
import { ThemContext } from 'layouts/Member';
import { useGetInfoAssets } from 'hook/useDashboard.js';

const Asset = () => {
  const { t } = useTranslation();

  const { data: dataInfoAssets } = useGetInfoAssets(useContext(ThemContext));

  return (
    <Box className={classNames(`border-show pd-15 ${styles.boxAsset}`)}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <div className="body-top">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <img src={IconAsset} alt={IconAsset} className="icon" />
                <h4 className="title-body">{t('boxInfoEmployee.dashboard.assetOwned')}</h4>
              </div>
            </div>
            <div className={classNames(`${styles.assetInfoTop}`)}>
              <div className="d-flex justify-content-between align-items-center">
                <div>{t(`boxInfoEmployee.dashboard.totalAsset`)}</div>
                <div className={styles.total}>{dataInfoAssets?.total_asset}</div>
              </div>
            </div>
            <hr />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.listAsset}>
            <h4>{t('boxInfoEmployee.dashboard.assetName')}</h4>
            {dataInfoAssets?.assets?.map((item) => (
              <div className={styles.itemAsset} key={item?.id}>
                {item?.name}
              </div>
            ))}
          </div>
        </Grid>
        <div className={styles.detail}>
          <Link href="/assets/list-assets">{t('boxInfoEmployee.showDetail')}</Link>
        </div>
      </Grid>
    </Box>
  );
};

export default Asset;
