import 'swiper/swiper.scss';
import React, { useContext } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

import styles from './../style.module.scss';
import LayoutProfile from 'components/LayoutProfile';
import Loading from 'components/Loading';
import historyContract from 'assets/img/icons/history-contract.svg';

import NavProfile from './../Nav.js';
import { ThemContext } from 'layouts/Member';
import { LOADING_MD } from 'constants/index.js';
import { useProfileUser, useGetInforContract, useGetHistoryContract } from 'hook/useProfileEmployee';
import Information from './Infomation';
import { Grid } from '@mui/material';

SwiperCore.use([Navigation]);

const UserContract = (props) => {
  const { t } = useTranslation();
  const userId = useContext(ThemContext);

  //Call Api Profile
  const { data: uProfileUser, isLoading: isLoadingProfileUser } = useProfileUser('', userId);
  const { data: uProfileUserInforContract, isLoading: isLoadingProfileUserInforContract } = useGetInforContract(userId);
  const { data: uProfileUserHistoryContract } = useGetHistoryContract({
    user_id: userId,
    contract_id: uProfileUserInforContract?.id ? uProfileUserInforContract?.id : null,
  });

  return (
    <>
      <div className={styles.containerRoot}>
        <div className={styles.rowInfo}>
          {(isLoadingProfileUser || !userId) && <Loading addClass="mt-10" type={LOADING_MD} />}
          {uProfileUser && (
            <>
              <Row>
                <Col md="3">
                  <LayoutProfile uProfileUser={uProfileUser?.data} />
                </Col>
                <Col md="9">
                  <NavProfile />
                  <div className={styles.contentRoot}>
                    <Row>
                      <Col md="12">
                        <Information userId={userId} isLoading={isLoadingProfileUserInforContract} uProfileUserInforContract={uProfileUserInforContract} />
                        <div className={`${styles.contractHistory} ${styles.profileBoxInfo}`}>
                          <Row className={styles.rowTitle}>
                            <Col xs="10">
                              <div className={classNames(`${styles.profileTitle} w-100`)}>
                                <img alt={historyContract} className={styles.imgTitle} src={historyContract} />
                                {t('contract.contractDetail.contractHistory')}
                              </div>
                            </Col>
                            <Col xs="2"></Col>
                          </Row>
                          <Timeline>
                            {uProfileUserHistoryContract?.map((contract) => (
                              <TimelineItem key={contract.id}>
                                <TimelineOppositeContent className={styles.dateContractLeft} color="text.secondary">
                                  {contract.start_date} ~ {contract.end_date}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                  <hr className={styles.columnTimeline} />
                                </TimelineSeparator>

                                <Grid flex={2}>
                                  <TimelineContent>
                                    <div
                                      className={classNames(styles.timelineDetail, { [styles.active]: contract?.active })}
                                    >
                                      <div className={styles.timelineField}>
                                        <p className={styles.timelineFieldTitle}>
                                          {t('contract.contractDetail.contractType')}
                                        </p>
                                        <p className={styles.timelineFieldValue}>{contract.contract_category_name}</p>
                                      </div>

                                      <div className={styles.timelineField}>
                                        <p className={styles.timelineFieldTitle}>
                                          {t('contract.contractDetail.created_user')}
                                        </p>
                                        <p className={styles.timelineFieldValue}>{contract.created_user}</p>
                                      </div>

                                      <div className={styles.timelineField}>
                                        <p className={styles.timelineFieldTitle}>
                                          {t('contract.contractDetail.customerType')}
                                        </p>
                                        <p className={styles.timelineFieldValue}>{contract.group_name}</p>
                                      </div>

                                      <div className={styles.timelineField}>
                                        <p className={styles.timelineFieldTitle}>
                                          {t('contract.contractDetail.date_created')}
                                        </p>
                                        <p className={styles.timelineFieldValue}>{contract.date_created}</p>
                                      </div>
                                    </div>
                                  </TimelineContent>
                                </Grid>
                              </TimelineItem>
                            ))}
                          </Timeline>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {userId && !isLoadingProfileUser && !uProfileUser && (
            <p className={classNames(`${styles.loading} text-center m-0`)}>{t('common.notData')}</p>
          )}
        </div>
      </div>
    </>
  );
};
export default UserContract;
