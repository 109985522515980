import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import iconChecked from './../../assets/img/icons/checked.png';
import * as Constants from 'constants/index';

import styles from './styles.module.scss';

const ModalCommon = (props) => {
  const { toggle, modal } = props;

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className={`${styles.modalCommon} modal-dialog-centered`}>
        <ModalHeader className={styles.modalTitle} toggle={toggle}>
          {props.title}
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          {props.items &&
            props.items.map((item, index) => (
              <div key={index}>
                <div
                  className={item.status ? `${styles.itemCheck} ${styles.activeExport}` : styles.itemCheck}
                  onClick={() => props.onClick(item)}
                >
                  {item.status && <img src={iconChecked} alt="checked" className={styles.checked} />}
                  <p className={styles.exportTitle}>{item.name}</p>
                </div>
                <hr className={styles.hrModal} />
              </div>
            ))}
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            onClick={props.onSubmit}
            className={styles.buttonSubmit}
            disabled={
              props.nameModal === Constants.EXPORT && !props.items.some((item) => true === item.status) ? true : false
            }
          >
            {props.footer}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalCommon;
