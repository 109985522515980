export const TYPE_EXPORT_PDF = 'type_export_pdf';
export const DEFAULT_LIMIT_10 = 10;
export const DEFAULT_LIMIT = 16;
export const DEFAULT_LIMIT_LARGE = 20;
export const DEFAULT_LIMIT_LARGE_EMPLOYEES_COMPANY = 30;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_MUI = 0;
export const DEFAULT_OFFSET = 1;
export const MAX_LENGTH_225 = 225;
export const PER_PAGE_OPTION = [16, 30, 50];
export const PER_PAGE_OPTION_V2 = [20, 40, 60];

export const LOADING_SM = 'sm';
export const LOADING_MD = 'md';

export const ALL = 'all';

export const SEND_EMAIL = 1;
export const NOT_SEND_EMAIL = 0;
export const DEFAULT_LIMIT_20 = 20;

export const MAX_LENGTH_3 = 3;
export const MAX_LENGTH_7 = 7;
export const MAX_LENGTH_15 = 15;
export const MAX_LENGTH_20 = 20;
export const MAX_LENGTH_30 = 30;
export const MAX_LENGTH_40 = 40;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_60 = 60;
export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_255 = 255;
export const MAX_LENGTH_1000 = 1000;

export const DATA_TYPE_NUMBER = 'number';
export const DATA_TYPE_CURRENCY = 'currency';
export const DATA_TYPE_FLOAT = 'float';

export const FORMAT_IMAGES = ['JPG', 'JPEG', 'PNG', 'JFIF', 'jpg', 'jpeg', 'png', 'jfif'];

export const EXPORT = 'export';

export const TAB_0 = 0;
export const TAB_1 = 1;
export const TAB_2 = 2;
export const TAB_3 = 3;
export const TAB_4 = 4;

export const ZERO = 0;
export const NO_DATA = 0;
export const VALIDATE_NOT_ZERO = 0;

export const USE_GET_NOTIFICATIONS = 'USE_GET_NOTIFICATIONS';
export const USE_TOTAL_GET_NOTIFICATIONS = 'USE_TOTAL_GET_NOTIFICATIONS';

export const SYSTEM_ME = 1;
export const SYSTEM_BO = 2;
export const SYSTEM_RECRUIT = 3;
export const SYSTEM_WORKFLOW = 4;
export const SYSTEM_WIKI = 5;
export const SYSTEM_PROJECT = 6;
