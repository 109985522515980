import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import styles from './style.module.scss';
import iconInputError from './../../assets/img/icons/input_error.png';
import * as COLOR from 'constants/ColorForm.js';
import { DATA_TYPE_NUMBER, DATA_TYPE_CURRENCY, DATA_TYPE_FLOAT } from 'constants/index.js';
import { isNumberAnode, isNumberCurrency, isFloatAnode } from 'helpers/index.js';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 12px',

    '&:focus': {
      borderColor: COLOR.GREEN,
    },
    '&:hover': {
      borderColor: COLOR.GREEN,
    },
  },
}));

const CssTextField = styled(TextField)({
  '& label': {
    fontSize: '14px',
  },
  '& label.Mui-focused': {
    color: COLOR.GREEN,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLOR.GREEN,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLOR.WHEAT,
    },

    '&:hover fieldset': {
      borderColor: COLOR.GREEN,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLOR.GREEN,
    },

    '&.Mui-disabled fieldset': {
      borderColor: COLOR.WHEAT,
    },
    '&.Mui-disabled:hover fieldset': {
      borderColor: COLOR.WHEAT,
    },
  },
  '& label.Mui-error': {
    color: COLOR.RED,
  },
  '& .MuiOutlinedInput-root.Mui-error': {
    '&:hover fieldset': {
      borderColor: COLOR.RED,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLOR.RED,
    },
  },
});

const InputField = (props) => {
  const {
    label,
    maxLength,
    isRequired,
    defaultValue,
    onHandleInput,
    name,
    variant,
    placeholder,
    error,
    dataType,
    autoComplete,
    sizeInput,
    type,
    rows,
    addClass,
    disabled,
  } = props;

  const [valueInput, setValueInput] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [_autoComplete, setAutoComplete] = useState('off');

  useEffect(() => {
    setValueInput(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setAutoComplete(autoComplete);
  }, [autoComplete]);

  useEffect(() => {
    setAutoComplete(autoComplete);
  }, [autoComplete]);

  const handleInput = (event) => {
    let checkInput = false;
    if (dataType) {
      switch (dataType) {
        case DATA_TYPE_NUMBER:
          if (isNumberAnode(event.target.value)) checkInput = true;
          break;

        case DATA_TYPE_CURRENCY:
          if (isNumberCurrency(event.target.value)) {
            checkInput = true;
            // onHandleInput(event);
            // setValueInput(formatNumberCurrency(event.target.value));
            // return;
          }
          break;

        case DATA_TYPE_FLOAT:
          if (isFloatAnode(event.target.value)) checkInput = true;
          break;

        default:
          break;
      }

      if (checkInput) {
        if (onHandleInput) onHandleInput(event);
        setValueInput(event.target.value);
      }
    } else {
      if (onHandleInput) onHandleInput(event);
      setValueInput(event.target.value);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className={classNames(styles.inputFiled, styles.inputGroup, `${addClass}`)}>
        {!label && (
          <div className={styles.notLabel}>
            <FormControl variant={variant} error={true}>
              {type && type === 'password' ? (
                <BootstrapInput
                  value={valueInput}
                  error={error ? true : false}
                  name={name}
                  variant={variant}
                  onChange={handleInput}
                  placeholder={placeholder}
                  type={showPassword ? 'text' : 'password'}
                  disabled={disabled}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  size={sizeInput ? sizeInput : ''}
                />
              ) : (
                <BootstrapInput
                  value={valueInput}
                  error={error ? true : false}
                  name={name}
                  multiline={rows ? true : false}
                  rows={rows ? rows : false}
                  variant={variant}
                  onChange={handleInput}
                  placeholder={placeholder}
                  type={showPassword ? 'text' : 'password'}
                  inputProps={{
                    style: rows
                      ? {}
                      : sizeInput
                      ? { padding: '8px 15px 7px 15px', fontSize: '14px' }
                      : { padding: '14px 15px 14px 15px', fontSize: '14px' },
                    maxLength: maxLength,
                  }}
                />
              )}
            </FormControl>
          </div>
        )}

        {label && (
          <CssTextField
            label={
              <React.Fragment>
                {label}
                {isRequired && label ? <sup> *</sup> : ''}
              </React.Fragment>
            }
            inputProps={{
              style: rows
                ? {}
                : sizeInput
                ? { padding: '8px 15px 7px 15px', fontSize: '14px' }
                : { padding: '14px 15px 14px 15px', fontSize: '14px' },
              maxLength: maxLength,
            }}
            value={valueInput}
            name={name}
            multiline={rows ? true : false}
            rows={rows ? rows : false}
            variant={variant}
            error={error ? true : false}
            size={sizeInput ? sizeInput : ''}
            autoComplete={_autoComplete}
            onChange={handleInput}
          />
        )}

        {(error ? true : false) && (
          <div className={styles.image}>
            <img scr={iconInputError} alt={iconInputError} className="hidden" />
            <img alt={iconInputError} src={iconInputError} className={styles.imgInputError} />
          </div>
        )}
      </div>
      <p className="error-input error">{error}</p>
    </>
  );
};

export default InputField;
