export const USE_TIME_SHEET = 'USE_TIME_SHEET';
export const USE_TOTAL_TIME_SHEET = 'USE_TOTAL_TIME_SHEET';

export const USE_GET_TIME_SHEET_LIST = 'USE_GET_TIME_SHEET_LIST';
export const USE_GET_TIME_SHEET_TOTAL = 'USE_GET_TIME_SHEET_TOTAL';
export const USE_LIST_ALL_DIVISION = 'USE_LIST_ALL_DIVISION';

export const USE_LIST_REQUEST_INDIVIDUAL = 'USE_LIST_REQUEST_INDIVIDUAL';
export const USE_LIST_TYPE_REQUEST_INDIVIDUAL = 'USE_LIST_TYPE_REQUEST_INDIVIDUAL';
export const USE_LIST_OT_INDIVIDUAL = 'USE_LIST_OT_INDIVIDUAL';
export const USE_LIST_TYPE_OT_INDIVIDUAL = 'USE_LIST_TYPE_OT_INDIVIDUAL';
export const USE_TIMEKEEPING_TOTAL_YEAR = 'USE_TIMEKEEPING_TOTAL_YEAR';
export const USE_TIMEKEEPING_TOTAL_MONTH = 'USE_TIMEKEEPING_TOTAL_MONTH';
export const USE_TIMEKEEPING_LATE_OT_COMPANY = 'USE_TIMEKEEPING_LATE_OT_COMPANY';
export const USE_LIST_TYPE_REQUEST_BY_CATEGORY = 'USE_LIST_TYPE_REQUEST_BY_CATEGORY';
export const USE_LIST_TABLE_OT = 'USE_LIST_TABLE_OT';
export const USE_GET_SCHEDULE_REQUEST_LEADER = 'USE_GET_SCHEDULE_REQUEST_LEADER';
export const USE_GET_LIST_USER_ACCEPT_WORK_FOLLOW = 'USE_GET_LIST_USER_ACCEPT_WORK_FOLLOW';
export const USE_GET_HOLIDAY_SCHEDULE_REQUEST_LEADER = 'USE_GET_HOLIDAY_SCHEDULE_REQUEST_LEADER';
export const USE_GET_HOLIDAYS = 'USE_GET_HOLIDAYS';
export const USE_GET_SCHEDULE = 'USE_GET_SCHEDULE';
export const USE_GET_LIST_CHECKBOX_WORK_FOLLOW = 'USE_GET_LIST_CHECKBOX_WORK_FOLLOW';
export const USE_GET_PROJECT = 'USE_GET_PROJECT';
export const USE_GET_NUMBER_MINUTES_REGISTER_LATE = 'USE_GET_NUMBER_MINUTES_REGISTER_LATE';
export const USE_GET_NUMBER_MINUTES_REGISTER_EARLY = 'USE_GET_NUMBER_MINUTES_REGISTER_EARLY';
export const USE_GET_NUMBER_MINUTES_REGISTER = 'USE_GET_NUMBER_MINUTES_REGISTER';
export const USE_GET_SCHEDULE_OF_USER = 'USE_GET_SCHEDULE_OF_USER';
export const USE_GET_DETAIL_REQUEST = 'USE_GET_DETAIL_REQUEST';
export const USE_GET_LIST_USER_NEXT_PERSON_UPDATE = 'USE_GET_LIST_USER_NEXT_PERSON_UPDATE';

export const DEFAULT_COMPLETE_TIMESHEETS = -1;
export const COMPLETED_TIMESHEETS = 1;
export const LENGTH_TO_DISPLAY_TOOLTIP = 0;
export const LENGTH_TO_REMOVE_BORDER_TOOLTIP = 2;
export const NOT_COMPLETE_TIMESHEETS = 0;

export const APPROVE = 1;
export const NOT_APPROVED = 2;
export const CATEGORY_ID_LIST_REQUEST = 7;
export const CATEGORY_ID_LIST_OT = 8;
export const LENGTH_NO_BORDER = 1;

export const REQUEST_TYPE_MORNING_SHIFT = 1;
export const REQUEST_TYPE_AFTERNOON_SHIFT = 2;
export const REQUEST_TYPE_ALL_DAY = 3;

export const NOT_DATA = -1;
export const NOT_LATE = 0;

export const MINUTES_NOT_LATE = 10;

export const REGISTRATION_LEAVE = {
  EARLY_MORNING: 0,
  LATE_MORNING: 1,
  EARLY_AFTERNOON: 2,
  LATE_AFTERNOON: 3,
};
