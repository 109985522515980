import React, { useRef } from 'react';
import { Box } from "@mui/material"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper } from 'swiper/react';
import iconNext from '../../assets/img/icons/next.png';
import iconPrev from '../../assets/img/icons/prev.png';
import styles from './styles.module.scss';

SwiperCore.use([Navigation]);

const Carousel = ({
  modules = [Navigation, Pagination, Scrollbar, A11y],
  hideImageTransfer,
  children,
  spaceBetween = 15,
  slidesPerView = 3,
  showButtons = true,
  pagination = true,
  ...otherProps
}) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <Swiper
      modules={modules}
      pagination={pagination && { clickable: true }}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      onInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      {...otherProps}
    >
      {children}
      <Box hidden={hideImageTransfer}>
        <button type="button" className={styles.swiperBtnPrev} ref={navigationPrevRef}>
          <img alt={iconPrev} src={iconPrev} />
        </button>
        <button type="button" className={styles.swiperBtnNext} ref={navigationNextRef}>
          <img alt={iconNext} src={iconNext} />
        </button>
      </Box>
    </Swiper>
  );
};

export default Carousel;
