import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import ProgressBar from 'components/ProgressBar/index.js';
import styles from './style.module.scss';
import { ThemContext } from 'layouts/Member';
import { useGetProfileEmployee } from 'hook/useDashboard.js';
import avatarDefault from 'assets/img/icons/default-avatar.svg';
import { handleOnError } from 'helpers';

const InfoEmployee = () => {
  const { t } = useTranslation();
  const { data: dataInfoEmployee } = useGetProfileEmployee(useContext(ThemContext));
  return (
    <Box className={styles.infoEmployee}>
      <div className="d-flex">
        <div className={styles.leftInfoEmployee}>
          <div className={styles.avatarInfoEmployee}>
            <div className={classNames(`${styles.avatar}`)}>
              <img
                onError={handleOnError}
                src={dataInfoEmployee?.avatar ? dataInfoEmployee?.avatar : avatarDefault}
                alt="avatar"
                className={styles.avatarDashboard}
              />
            </div>
            <div className={styles.info}>
              <h4>{dataInfoEmployee?.user_name}</h4>
              <p>{dataInfoEmployee?.email}</p>
              <p>
                {dataInfoEmployee?.position} - {dataInfoEmployee?.division}
              </p>
            </div>
          </div>

          <p className={styles.avatarComplete}>{t('boxInfoEmployee.completeProfile')}</p>
          <div className={styles.avatarBar}>
            <ProgressBar completed={dataInfoEmployee?.percent_profile} />
            {dataInfoEmployee?.percent_profile}%
          </div>
        </div>

        <div className={styles.rightInfoEmployee}>
          <div>
            <p className={styles.textTitleYellow}>{t('boxInfoEmployee.manager')}</p>
            <p className={styles.manage}>{dataInfoEmployee?.manager?.user_name ?? t('boxInfoEmployee.alertNotInfo')}</p>
          </div>

          <div className={styles.onboard}>
            <div className="d-flex justify-content-between">
              <p className={styles.dateOnboard}></p>
              <p className={styles.detail}>
                <Link href={`/employee/profile`}>{t('boxInfoEmployee.showDetail')}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default InfoEmployee;
