import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import * as COLOR from 'constants/ColorForm.js';

const styleDefault = {
  '& label': {
    fontSize: '14px',
  },
  '& label.Mui-focused': {
    color: COLOR.GREEN,
  },
  '&:hover label': {
    color: COLOR.GREEN,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLOR.GREEN,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLOR.WHEAT,
    },
    '&:hover fieldset': {
      borderColor: COLOR.GREEN,
    },
    '&:hover .MuiSvgIcon-root': {
      color: COLOR.GREEN,
    },
    '&.Mui-focused .MuiSvgIcon-root': {
      color: COLOR.GREEN,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLOR.GREEN,
    },
  },
  '& label.Mui-error': {
    color: COLOR.RED,
  },
  '& .MuiOutlinedInput-root.Mui-error': {
    '&:hover fieldset': {
      borderColor: COLOR.RED,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLOR.RED,
    },
    '& .MuiSvgIcon-root': {
      color: COLOR.RED,
    },
  },
};

export const TextFieldDate = styled(TextField)({ ...styleDefault });

export const TextFieldNoneDate = styled(TextField)(
  { ...styleDefault },
  {
    '& label': {
      color: COLOR.SILVER_LABEL_NONE,
    },
    '& label.Mui-focused': {
      display: 'none',
    },
    '& label.Mui-error': {
      color: COLOR.SILVER_LABEL_NONE,
    },
    '& fieldset legend': {
      width: '0px',
    },
  },
);
