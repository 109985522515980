import { useState, useContext } from 'react';
// eslint-disable-next-line
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line
import IconNotifyTop from 'assets/img/icons/dashboard/notify_top.svg';
import calendarDayOff from 'assets/img/icons/calendar-dayoff.svg';

import { customDate } from 'helpers/formatDate';
import { useTotalTimeSheet } from 'hook/useTimeSheet';
import { getStartOfMonth } from 'helpers/formatDate';
import styles from './style.module.scss';
import Asset from './Asset.js';
import InfoEmployee from './InfoEmployee.js';
import Project from './Project.js';
import Request from './Request.js';
import { ThankYouDashboard } from './Thankyou.js';
import { TimekeepingToDay, TimeKeepingStatistical } from './Timekeeping.js';
import { ThemContext } from 'layouts/Member';
import { Box } from '@mui/material';
// import warningIcon from 'assets/img/icons/dashboard/warning-icon.svg';
// import { useGetUnReportDays } from 'hook/useDashboard';

const DATA_NOTIFY = [
  {
    id: 1,
    name: 'Nay bạn đã đi muộn, tạo request ngay !',
  },
  {
    id: 2,
    name: 'Đăng ký tiêm đợt 1',
  },
  {
    id: 3,
    name: 'Đăng ký tiêm đợt 2',
  },
  {
    id: 4,
    name: 'Đăng ký tiêm đợt 3',
  },
  {
    id: 5,
    name: 'Nay bạn đã đi muộn, tạo request ngay',
  },
  {
    id: 6,
    name: 'Hôm nay là sinh nhật công ty',
  },
];

const Home = (props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const [dataNotify] = useState(DATA_NOTIFY);
  const userId = useContext(ThemContext);
  const { data: totalTimeSheet } = useTotalTimeSheet({ userId, date: customDate(getStartOfMonth(), 'MM/YYYY') });

  // const { data: listUnReportDays } = useGetUnReportDays();

  return (
    <div className={styles.dashboard}>
      {/* {listUnReportDays && listUnReportDays?.length > 0 && (
        <div className={styles.noticeDailyReport}>
          <img src={warningIcon} alt="warning" />
          <span>
            {t('boxInfoEmployee.dashboard.unReportDays', {
              listDate: listUnReportDays?.map((date) => customDate(date, 'DD/MM')).join(', '),
            })}
          </span>
        </div>
      )} */}
      <Box className={styles.containerDashboard}>
        <Box gridColumn="span 3">
          <ThankYouDashboard userId={userId} />
        </Box>
        <Box gridColumn="span 6">
          <InfoEmployee userId={userId} />
        </Box>
        <Box gridColumn="span 3" gridRow="span 2">
          <Request />
        </Box>
        <Box gridColumn="span 3">
          <Box display="flex" flexDirection="column" gap={2} className="h-100">
            <TimekeepingToDay userId={userId} />
            <Box>
              <div className={styles.dayOff}>
                <img src={calendarDayOff} alt="" />
                <div>
                  <div className={styles.label}>{t('timekeeping.offHour')}</div>
                  <div className={styles.value}>{totalTimeSheet?.day_offs}</div>
                </div>
              </div>
            </Box>
            <Asset userId={userId} />
          </Box>
        </Box>
        <Box gridColumn="span 3">
          <TimeKeepingStatistical userId={userId} />
        </Box>
        <Box gridColumn="span 3" className='d-flex flex-column'>
          <Project userId={userId} />
        </Box>
      </Box>
    </div>
  );
};

export default Home;
