import { sendDelete, sendGet, sendPost, sendPut } from '../helpers/axios';
import configs from '../config/index';

export const apiGetListProjects = (params, userId) => sendGet(`/api/my-projects/${userId}`, params);
export const apiGetListStatusProjects = (params, userId) => sendGet(`/api/my-projects/total-status/${userId}`, params);
export const apiGetEffortNow = (params, userId) => sendGet(`/api/my-projects/total-current/${userId}`, params);
export const apiGetAllDailyReport = (params) => sendGet('/api/daily-reports', params);
export const apiGetUserProject = (params) => sendGet(`${configs.API_DOMAIN_PROJECT}/api/user-projects`, params);
export const apiCreateDailyReport = (payload) => sendPost(`/api/daily-reports`, payload);
export const apiDeleteDailyReport = (reportId, config) => sendDelete(`/api/daily-reports/${reportId}`, config);
export const apiUpdateDailyReport = (reportId, config) => sendPut(`/api/daily-reports/${reportId}`, config);
export const apiGetCalendarReport = (params) => sendGet(`/api/daily-reports-calendar`, params);
export const apiGetDetailCalendarReport = (params) => sendGet(`/api/daily-reports-calendar-detail`, params);
