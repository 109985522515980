import { Select, Space } from 'antd';
import classNames from 'classnames';
import { FaCaretDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styles from './styles.module.scss';
import { t } from 'i18next';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../DailyReport';

const { Option } = Select;

const CustomPagination = (props) => {
  const { loading, totalPage, currentPage, limit, from, to, onChangePage } = props;

  if (loading || !totalPage) {
    return null;
  }

  const lastPage = Math.ceil(totalPage / limit);

  return (
    <div className={styles.container}>
      <span>
        {from} - {to} {t('project.daily.pagination.from')} {totalPage}
      </span>
      <div className="flex items-center">
        <span>{t('project.daily.pagination.rowsPerPage')}</span>
        <Select
          value={limit}
          defaultValue={DEFAULT_PAGE_SIZE}
          suffixIcon={<FaCaretDown size={15} color="#8D8D8D" />}
          onChange={(limit) => onChangePage(DEFAULT_CURRENT, limit)}
        >
          {PAGE_SIZE_OPTIONS.map((size) => (
            <Option key={size} value={size}>
              {size}
            </Option>
          ))}
        </Select>
        <Space size={40} className="flex ml-3">
          <button
            className={classNames(
              { [styles.cursorNoDrop]: currentPage === DEFAULT_CURRENT },
              { [styles.opacity50]: currentPage === DEFAULT_CURRENT },
              { [styles.cursorPointer]: currentPage !== DEFAULT_CURRENT },
            )}
            disabled={currentPage === DEFAULT_CURRENT}
            onClick={() => onChangePage(currentPage - DEFAULT_CURRENT, limit)}
          >
            <FaChevronLeft color="#FEA628" />
          </button>
          <button
            className={classNames(
              { [styles.cursorNoDrop]: currentPage === lastPage },
              { [styles.opacity50]: currentPage === lastPage },
              { [styles.cursorPointer]: currentPage !== lastPage },
            )}
            disabled={currentPage === lastPage}
            onClick={() => onChangePage(currentPage + DEFAULT_CURRENT, limit)}
          >
            <FaChevronRight color="#FEA628" />
          </button>
        </Space>
      </div>
    </div>
  );
};

export default CustomPagination;
