import React, { useState, useEffect } from 'react';
import { ButtonBase, FormControl, Grid } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import Nav from '../Nav';
import SearchComponent from 'components/Search';
import RadioComponent from 'components/RadioComponent';
import Loading from 'components/Loading';

import styles from './styles.module.scss';
import './styles.scss';

import { useGetCompanyEmployees, useGetCountDivisionMember, useGetCountBranchMember } from 'hook/useCompany';
import { DEFAULT_PAGE, DEFAULT_LIMIT_LARGE_EMPLOYEES_COMPANY } from 'constants/index';
import { USE_COMPANY_EMPLOYEES } from 'constants/Company.js';

import totalIcon from 'assets/img/icons/common/company/total-icon.svg';
import iconLoading from 'assets/img/icons/loading.png';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import { handleOnError } from 'helpers';

const DEFAULT_FILTER = {
  division_id: '',
  branch_id: '',
  key_word: '',
};

const CompanyEmployees = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [per_page, setPerPage] = useState(DEFAULT_LIMIT_LARGE_EMPLOYEES_COMPANY);

  const [companyEmployees, setCompanyEmployees] = useState({});

  const { data: uCompanyEmployees, total, isLoading } = useGetCompanyEmployees({ ...filter, page, per_page });
  const { data: uCountDivisionMember } = useGetCountDivisionMember(filter);
  const { data: uCountBranchMember } = useGetCountBranchMember(filter);

  const { control, setValue } = useForm({
    mode: 'onBlur',
  });

  const fetchMore = () => {
    if (!isLoading) setPage((value) => value + 1);
  };

  const handleSearch = async (value) => {
    setCompanyEmployees({});
    setFilter({
      ...filter,
      key_word: value,
    });
    setPage(DEFAULT_PAGE);
    queryClient.removeQueries(USE_COMPANY_EMPLOYEES);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeDivision = async (event) => {
    setCompanyEmployees({});
    if (event.target.value === '') {
      setFilter({ ...filter, division_id: '' });
    } else {
      setFilter({ ...filter, division_id: event.target.value });
    }
    setPage(DEFAULT_PAGE);
    queryClient.removeQueries(USE_COMPANY_EMPLOYEES);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeBranch = async (event) => {
    setCompanyEmployees({});
    if (event.target.value === '') {
      setFilter({ ...filter, branch_id: '' });
    } else {
      setFilter({ ...filter, branch_id: event.target.value });
    }
    setPage(DEFAULT_PAGE);
    queryClient.removeQueries(USE_COMPANY_EMPLOYEES);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleResetFilter = () => {
    setFilter(DEFAULT_FILTER);
    setPage(DEFAULT_PAGE);
    setPerPage(DEFAULT_LIMIT_LARGE_EMPLOYEES_COMPANY);
    queryClient.removeQueries(USE_COMPANY_EMPLOYEES);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (uCompanyEmployees) {
      const dataClone = { ...companyEmployees };

      uCompanyEmployees?.forEach((employee) => {
        if (!dataClone[employee.division_name]) {
          dataClone[employee.division_name] = [];
        }

        dataClone[employee.division_name].push(employee);
      });

      setCompanyEmployees(dataClone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uCompanyEmployees]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries(USE_COMPANY_EMPLOYEES);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="nav-fixed">
        <Nav />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={9}>
          <div className={`${styles.leftComponent}`} id="scrollCustom">
            <InfiniteScroll
              scrollableTarget="scrollCustom"
              dataLength={total || 0}
              next={fetchMore}
              hasMore={isLoading || page * per_page < total}
            >
              {Object.keys(companyEmployees).map((division) => (
                <div key={division} className={styles.division}>
                  <div className={styles.divisionName}>
                    {t('common.employees')} {division}
                  </div>

                  <div className={styles.divisionMember}>
                    {companyEmployees[division].map((employee) => (
                      <div className={styles.member} key={employee.id}>
                        <img
                          onError={handleOnError}
                          className={styles.avatar}
                          src={employee.avatar || defaultAvatar}
                          alt=""
                        />

                        <div className={styles.info}>
                          <div className={styles.name}>{employee.name}</div>

                          <div className={styles.email}>{employee.email}</div>
                          <div className={styles.code}>
                            {employee.code} - {employee.position_name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {isLoading && <Loading addClass={styles.loading} />}
              {!Object.keys(companyEmployees)?.length && !isLoading && (
                <p className="error-no-data">{t('common.notData')}</p>
              )}
            </InfiniteScroll>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={styles.rightComponent}>
            <div className={styles.totalMember}>
              <img src={totalIcon} alt="" />

              <div>
                <div className={styles.title}>{t('company.employees.totalEmployee')}</div>
                <div className={styles.total}>{total}</div>
              </div>
            </div>

            <div className={styles.searchComponent}>
              <SearchComponent
                txtPlaceholder={t('company.employees.searchEmployee')}
                addClass="input-search-box"
                handleSearch={handleSearch}
                defaultValue={filter?.key_word}
              />

              <FormControl component="fieldset">
                <div className={styles.filterTitle}>{t('common.division')}</div>
                {uCountDivisionMember?.length > 0 && (
                  <RadioComponent
                    name="division"
                    control={control}
                    defaultValue={filter?.division_id}
                    isHaveAll={true}
                    radioList={uCountDivisionMember}
                    handleChange={handleChangeDivision}
                    setValue={setValue}
                  />
                )}
              </FormControl>

              <FormControl component="fieldset">
                <div className={styles.filterTitle}>{t('common.branch')}</div>
                {uCountBranchMember?.length > 0 && (
                  <RadioComponent
                    name="branch"
                    control={control}
                    defaultValue={filter?.branch_id}
                    isHaveAll={true}
                    radioList={uCountBranchMember}
                    handleChange={handleChangeBranch}
                    setValue={setValue}
                  />
                )}
              </FormControl>
              <div className="strike" />
              <ButtonBase className="btn-reset" size="lg" onClick={handleResetFilter}>
                <img src={iconLoading} alt="Reset" width="20px" className={styles.imgReset} /> &emsp;
                {t('common.reset')}
              </ButtonBase>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyEmployees;
