import { useQuery } from 'react-query';

import { USE_DETAIL_DEPARTMENT } from 'constants/Department';
import { HTTP_OK } from 'constants/StatusCode';
import { apiDetailDepartment } from 'api/department';
import { USE_DETAIL_MEMBER } from 'constants/Department';
import { apiDetailMember } from 'api/department';
import { USE_OTHER_MEMBER } from 'constants/Department';
import { apiOtherMember } from 'api/department';

export function useDetailDepartment(params) {
  const { data: response, isLoading } = useQuery([USE_DETAIL_DEPARTMENT, params], async () => {
    const response = await apiDetailDepartment(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, isLoading };
}

export function useOtherMember(params) {
  const { data: response, isLoading } = useQuery([USE_OTHER_MEMBER, params], async () => {
    const response = await apiOtherMember(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, isLoading };
}

export function useDetailUser(params) {
  const { data: response, isLoading } = useQuery(
    [USE_DETAIL_MEMBER, params],
    async () => {
      const response = await apiDetailMember(params);
      if (response.status === HTTP_OK) {
        return response;
      }
      return null;
    },
    {
      enabled: params ? true : false,
    },
  );

  return { ...response, isLoading };
}
