import { InputAdornment } from '@mui/material';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import React, { useEffect } from 'react';

import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import { CssTextField, IS_INPUT_SEARCH } from './constant';

function CustomInputYup({
  label,
  maxLength,
  defaultValue,
  name,
  placeholder,
  control,
  register,
  errors,
  useWatch,
  required,
  setValue,
  type,
  setError,
  handleSearch,
  onHandelChange,
  multiline,
  rows,
  disabled,
  addErrors,
}) {
  const showError = errors[name]?.message;
  // Style for custom input label

  const titleWatch = useWatch({
    control,
    name: name,
  });

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, setValue, name]);

  useEffect(() => {
    if (type === IS_INPUT_SEARCH) {
      handleSearch(titleWatch);
    }
    if (titleWatch && onHandelChange) {
      onHandelChange(titleWatch);
    }
    // eslint-disable-next-line
  }, [titleWatch]);

  return (
    <div>
      <Controller
        render={({ field }) => (
          <CssTextField
            inputProps={{
              maxLength: maxLength,
              style: multiline ? { padding: '0px' } : { padding: '15px' },
            }}
            InputProps={{
              startAdornment: type === IS_INPUT_SEARCH && (
                <InputAdornment position="start">
                  <i className={classNames(`fa fa-search`)} style={{ color: COLOR_CHECKBOX_CUSTOM }} />
                </InputAdornment>
              ),
            }}
            placeholder={type === IS_INPUT_SEARCH ? placeholder : undefined}
            {...register(name)}
            label={<React.Fragment>{placeholder && titleWatch ? placeholder : label}</React.Fragment>}
            variant="outlined"
            required={required}
            error={showError || addErrors ? true : false}
            name={name}
            value={titleWatch || ''}
            {...field}
            multiline={multiline ? true : false}
            rows={rows ? rows : 1}
            disabled={disabled || false}
            // CSS
            labelspace={titleWatch === null || titleWatch === '' ? '-1px' : 0}
            heightauto={rows ? 'auto' : '50px'}
          />
        )}
        control={control}
        name={name}
      />
      <p className="error-input error">{addErrors && addErrors}</p>
      <p className="error-input error">{showError && errors[`${name}`]?.message}</p>
    </div>
  );
}

export default CustomInputYup;
