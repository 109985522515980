import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import SideNavBar from 'components/SideNavBar';
import { TAB_0 } from 'constants/index';
import { TAB_1 } from 'constants/index';
import { TAB_2 } from 'constants/index';
import { TAB_3 } from 'constants/index';
import { TAB_4 } from 'constants/index';

const TimeKeeping = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(TAB_0);

  const routes = [
    {
      name: t('timekeeping.timeSheets'),
    },
    {
      name: t('timekeeping.schedule'),
    },
    {
      name: t('timekeeping.listOT'),
    },
    {
      name: t('timekeeping.listRequests'),
    },
    {
      name: t('timekeeping.requestOvertime'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case TAB_0:
        history.push('/timekeeping/time-sheets');
        break;
      case TAB_1:
        history.push('/timekeeping/schedule');
        break;
      case TAB_2:
        history.push('/timekeeping/list-ot');
        break;
      case TAB_3:
        history.push('/timekeeping/list-requests');
        break;
      case TAB_4:
        history.push('/timekeeping/request-ot');
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/timekeeping/time-sheets':
        setValue(TAB_0);
        break;
      case '/timekeeping/schedule':
        setValue(TAB_1);
        break;
      case '/timekeeping/list-ot':
        setValue(TAB_2);
        break;
      case '/timekeeping/list-requests':
        setValue(TAB_3);
        break;
      case '/timekeeping/request-ot':
        setValue(TAB_4);
        break;

      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar routes={routes} value={value} onChange={handleChange} hasMarginBottom />;
};

export default TimeKeeping;
