import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { WHEAT } from 'constants/ColorForm';
import { BG_POSITION } from 'constants/ColorMap';
import * as Constants from './../../constants/index.js';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

function RadioComponent({ control, radioList, defaultValue, name, setValue, handleChange, isHaveAll }) {
  const { t } = useTranslation();

  return (
    <Controller
      render={({ field }) => (
        <RadioGroup {...field} value={`${defaultValue}`}>
          {isHaveAll && (
            <FormControlLabel
              value=""
              onChange={(e) => {
                setValue(name, e.target.value);
                handleChange(e);
              }}
              control={
                <Radio
                  sx={{
                    color: WHEAT,
                    '&.Mui-checked': {
                      color: BG_POSITION[0],
                    },
                  }}
                />
              }
              label={t('employees.all')}
            />
          )}

          {radioList?.map((item, index) => (
            <div key={index} className={styles.division}>
              <FormControlLabel
                value={item?.id}
                onChange={(e) => {
                  setValue(name, e.target.value);
                  handleChange(e);
                }}
                control={
                  <Radio
                    sx={{
                      color: WHEAT,
                      '&.Mui-checked': {
                        color: BG_POSITION[0],
                      },
                    }}
                  />
                }
                label={item?.name}
              />
              {item?.value !== Constants.ALL && <span className={styles.total}>{item?.total || item?.total_member || 0}</span>}
            </div>
          ))}
        </RadioGroup>
      )}
      name={name}
      control={control}
      value={`${defaultValue}`}
    />
  );
}

export default RadioComponent;
