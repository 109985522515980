import { 
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS, 
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED,
  STATUS_REQUEST_DENY
} from 'constants/TimeKeeping';
import { t } from 'i18next';
/* eslint-disable react-hooks/rules-of-hooks */
export const transferStatus = (status) => {
  switch (status) {
    case STATUS_REQUEST_OPEN:
      return t('timekeeping.request.statusOpen');
    case STATUS_REQUEST_IN_PROGRESS:
      return t('timekeeping.request.statusInProgress');
    case STATUS_REQUEST_COMPLETED:
      return t('timekeeping.request.statusCompleted');
    case STATUS_REQUEST_DENY:
      return t('timekeeping.request.statusDeny');
    case STATUS_REQUEST_REWORK:
      return t('timekeeping.request.statusRework');
    default:
      return t('timekeeping.request.statusRecall');
  }
};

export const transferStatusToColor = (status) => {
  switch (status) {
    case STATUS_REQUEST_OPEN:
      return 'status-open';
    case STATUS_REQUEST_IN_PROGRESS:
      return 'status-in-progress';
    case STATUS_REQUEST_COMPLETED:
      return 'status-completed';
    case STATUS_REQUEST_DENY:
      return 'status-deny';
    case STATUS_REQUEST_REWORK:
      return 'status-rework';
    default:
      return 'status-recall';
  }
}

export const transferStatusToBackgroundColor = (status) => {
  switch (status) {
    case STATUS_REQUEST_OPEN:
      return 'status-open-bg';
    case STATUS_REQUEST_IN_PROGRESS:
      return 'status-in-progress-bg';
    case STATUS_REQUEST_COMPLETED:
      return 'status-completed-bg';
    case STATUS_REQUEST_DENY:
      return 'status-deny-bg';
    case STATUS_REQUEST_REWORK:
      return 'status-rework-bg';
    default:
      return 'status-recall-bg';
  }
}
