export const STATUS_OT_APPROVED = 1;
export const STATUS_OT_PEDDING = 2;
export const STATUS_OT_REFUSE = 3;

export const STATUS_REQUEST_APPROVED = 1;
export const STATUS_REQUEST_PEDDING = 2;
export const STATUS_REQUEST_REFUSE = 3;

export const STATUS_OT_DISPOSED = 1;
export const STATUS_OT_IN_PROCESS = 2;
export const STATUS_INDIVIDUAL_PROCESSED = 1;
export const STATUS_INDIVIDUAL_PROCESSING = 2;

export const STATUS_REQUEST_OPEN = 1;
export const STATUS_REQUEST_IN_PROGRESS = 2;
export const STATUS_REQUEST_REWORK = 3;
export const STATUS_REQUEST_COMPLETED = 4;
export const STATUS_REQUEST_RECALL = 6;
export const STATUS_REQUEST_DENY = 7;

export const MIN_TOTAL_REQUEST = 1;
