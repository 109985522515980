import { useQuery } from 'react-query';

import * as Constants from './../constants/Assets.js';
import { HTTP_OK } from './../constants/StatusCode.js';
import { apiGetListAssetsUser, apiCountAssetsUser, apiGetListRequest, apiRequestFifth } from './../api/assets';

export function useGetListAssetsUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_USER_LIST_ASSETS, params],
    async () => {
      const response = await apiGetListAssetsUser(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useCountListAssetsUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_USER_COUNT_ASSETS, params],
    async () => {
      const response = await apiCountAssetsUser(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetListRequest(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_USER_LIST_ASSETS, params],
    async () => {
      const response = await apiGetListRequest({ ...params, status: params.status || null });
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.user_ids ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useRequestFifth(ID) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_REQUEST_FIFTH, ID],
    async () => {
      const response = await apiRequestFifth(ID);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
  );

  return { ...response, error, isLoading };
}
