import { Col, Row, Form } from 'reactstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import { IoIosAdd } from 'react-icons/io';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import iconEducation from '../../../../assets/img/icons/education.png';
import iconClock from '../../../../assets/img/icons/clock.png';
import DateMonth from 'components/DateTime/DateMonth';
import styles from './../style.module.scss';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';
import ModalComponent from 'components/Modal';
import InputField from 'components/Input/InputField';
import Carousel from 'components/Carousel';
import { MAX_LENGTH_40 } from 'constants/ProfileEmployee';
import moment from 'moment';
import { customDate, getStartOfMonth } from 'helpers/formatDate';
import ButtonComponent from 'components/Buttons';
import { confirmAlert } from 'react-confirm-alert';
import { HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { HTTP_UNPROCESSABLE_ENTITY } from 'constants/StatusCode';
import { HTTP_OK } from 'constants/StatusCode';
import { isNotNullObject } from 'helpers';
import { USE_USER_PROFILE } from 'constants/ProfileEmployee';
import { apiCreateEdu } from 'api/profileEmployee';
import { apiUpdateEdu } from 'api/profileEmployee';
import AlertComponent from 'components/Alert';
import { apiDeleteEdu } from 'api/profileEmployee';

const DEFAULT_FORM_DATA = {
  id: 0,
  name: '',
  major: '',
  start_date: null,
  end_date: null,
  error_major: '',
  error_name: '',
  error_start: '',
  error_end: '',
};

const DEFAULT_FORM_DATA_ERROR = {
  error_major: '',
  error_name: '',
  error_start: '',
  error_end: '',
};

const Education = (props) => {
  const { uProfileUser, userId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isShowModal, setShowModal] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [stateButton, setStateButton] = useState(false);
  const [textModal] = useState({
    title: t('infoEmployee.titleEducation'),
    btn_submit: t('common.save'),
  });
  const [formAddEdu, setformAddEdu] = useState(DEFAULT_FORM_DATA);
  const handleFilterEduStart = (value) => {
    setformAddEdu({ ...formAddEdu, start_date: getStartOfMonth(value, 'DD/MM/YYYY'), error_start: '' });
  };

  const handleFilterEduEnd = (value) => {
    setformAddEdu({ ...formAddEdu, end_date: getStartOfMonth(value, 'DD/MM/YYYY'), error_end: '' });
  };

  const handleInput = (e) => {
    const error = {};
    if (e.target.name === 'name') {
      error.error_name = '';
    } else if (e.target.name === 'major') {
      error.error_major = '';
    }
    setformAddEdu({ ...formAddEdu, [e.target.name]: e.target.value, ...error });
  };

  const openAddModal = () => {
    setShowModal(true);
    setformAddEdu({
      ...formAddEdu,
      ...DEFAULT_FORM_DATA,
    });
  };

  function onclickEditEdu(education) {
    setShowModal(true);

    setformAddEdu({
      ...formAddEdu,
      id: education.id,
      name: education.name,
      major: education.major,
      start_date: education.start_date,
      end_date: education.end_date,
      ...DEFAULT_FORM_DATA_ERROR,
    });
  }

  function validate() {
    var errors = {};
    if (!formAddEdu.name) {
      errors.error_name = t('common.thisIsObligation');
    }
    if (formAddEdu.name.length > MAX_LENGTH_40) {
      errors.error_name = t('messages.pleaseEnterLessThanFortyCharacters');
    }
    if (!formAddEdu.major) {
      errors.error_major = t('common.thisIsObligation');
    }
    if (formAddEdu.major.length > MAX_LENGTH_40) {
      errors.error_major = t('messages.pleaseEnterLessThanFortyCharacters');
    }
    if (!formAddEdu.start_date) {
      errors.error_start = t('common.thisIsObligation');
    }
    if (!formAddEdu.end_date) {
      errors.error_end = t('common.thisIsObligation');
    }
    if (isNotNullObject(errors)) {
      setformAddEdu({
        ...formAddEdu,
        ...errors,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (event) => {
    setformAddEdu({
      ...formAddEdu,
      ...DEFAULT_FORM_DATA_ERROR,
    });
    setStateButton(true);
    event.preventDefault();

    if (!validate()) {
      setStateButton(false);
      return;
    }

    setformAddEdu({
      ...formAddEdu,
      ...DEFAULT_FORM_DATA_ERROR,
    });

    const data = {
      name: formAddEdu.name.trim(),
      major: formAddEdu.major.trim(),
      start_date: getStartOfMonth(moment(formAddEdu.start_date, 'DD/MM/YYYY'), 'DD/MM/YYYY'),
      end_date: getStartOfMonth(moment(formAddEdu.end_date, 'DD/MM/YYYY'), 'DD/MM/YYYY'),
      user_id: userId,
    };

    let response = {};
    if (!formAddEdu.id) {
      response = await apiCreateEdu(data);
    } else {
      response = await apiUpdateEdu(data, formAddEdu.id);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setformAddEdu({
        ...formAddEdu,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_major: msg?.major
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_start: msg?.start_date
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_end: msg?.end_date
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModal(false);
      setStateButton(false);
      setformAddEdu({
        ...formAddEdu,
        ...DEFAULT_FORM_DATA,
      });
      setMessage(response.data.messages);
      setShowAlert(true);
      await queryClient.invalidateQueries(USE_USER_PROFILE);
    }
    setStateButton(false);
  };

  const alertConfirm = (id) => {
    confirmAlert({
      title: t('common.doYouReallyWantToDeletedData?'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteEdu({ user_id: userId }, id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              await queryClient.invalidateQueries(USE_USER_PROFILE);
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <div className={styles.profileBoxEdu}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className="ml-13">
              <div className={styles.profileTitle}>
                <img alt={iconEducation} className={styles.imgTitle} src={iconEducation} />
                {t('infoEmployee.titleEducation')}
              </div>
            </div>
          </Col>
          {uProfileUser?.education?.length !== 0 && (
            <Col xs="2">
              <div className={classNames(`${styles.editDeleteButton} mr--15`)} onClick={() => openAddModal()}>
                <IoIosAdd />
              </div>
            </Col>
          )}
        </Row>
        <div>
          {uProfileUser && (uProfileUser?.education?.length === 0) | (uProfileUser?.education === null) ? (
            <div className={styles.achievementNull}>
              <div className={classNames(`${styles.editDeleteButton}`)} onClick={() => openAddModal()}>
                <IoIosAdd />
              </div>
              <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addEducation')}</div>
            </div>
          ) : (
            <Carousel
              spaceBetween={15}
              slidesPerView={3}
              navigation={{
                prevEl: '.prevEdu',
                nextEl: '.nextEdu',
              }}
              hideImageTransfer={uProfileUser?.education?.length <= 3}
              breakpoints={{
                1366: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
              className={styles.salaryBoxParent}
            >
              {uProfileUser?.education
                ?.slice(0)
                .reverse()
                .map((education, index) => (
                  <SwiperSlide key={index}>
                    <div className={styles.educationBox}>
                      <div className={styles.educationHeaderTitle}>
                        <div className={classNames(`md-9 ${styles.educationBoxTitle} ${styles.titleBox}`)}>
                          <p>{education.name}</p>
                        </div>
                        <div className={classNames(`md-3 ${styles.educationEdit}`)}>
                          <div onClick={() => onclickEditEdu(education)} className={`${styles.editDeleteButtonChild}`}>
                            <HiPencil />
                          </div>
                          <div
                            onClick={() => alertConfirm(education.id)}
                            className={classNames(`${styles.editDeleteButtonChild} ${styles.btnDeleted}`)}
                          >
                            <MdDelete />
                          </div>
                        </div>
                      </div>
                      <div className={styles.educationDetail}>
                        <div className={classNames(`${styles.profileContentInfo} text-bold`)}>
                          <img alt={iconEducation} src={iconEducation} /> {t('infoEmployee._address')}:{' '}
                          <p className={styles.major}>&nbsp;{education.major}</p>
                        </div>
                        <div className={classNames(`${styles.profileContentInfo} text-bold`)}>
                          <img alt={iconClock} src={iconClock} /> {t('infoEmployee.time')}:{' '}
                          {customDate(moment(education.start_date, 'DD/MM/YYYY'), 'MM/YYYY') === customDate(moment(education.end_date, 'DD/MM/YYYY'), 'MM/YYYY') ? (
                              customDate(moment(education.start_date, 'DD/MM/YYYY'), 'MM/YYYY')
                          ) : (
                            <>
                              {customDate(moment(education.start_date, 'DD/MM/YYYY'), 'MM/YYYY')} - {customDate(moment(education.end_date, 'DD/MM/YYYY'), 'MM/YYYY')}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Carousel>
          )}
        </div>
      </div>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>

      <ModalComponent toggle={() => setShowModal(!isShowModal)} isShowModal={isShowModal} title={textModal.title}>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <div className="fieldset-group mb-4">
            <InputField
              label={t('infoEmployee.courseAndMajor')}
              isRequired={true}
              variant="outlined"
              name="name"
              maxLength={MAX_LENGTH_40}
              defaultValue={formAddEdu.name}
              onHandleInput={handleInput}
              error={formAddEdu.error_name}
            />
          </div>

          <div className="fieldset-group mb-4">
            <InputField
              label={t('infoEmployee._address')}
              isRequired={true}
              variant="outlined"
              name="major"
              maxLength={MAX_LENGTH_40}
              defaultValue={formAddEdu.major}
              onHandleInput={handleInput}
              error={formAddEdu.error_major}
            />
          </div>

          <Row>
            <Col className="md-6">
              <div>
                <DateMonth
                  label=""
                  valueDefault={
                    typeof formAddEdu.start_date === 'string'
                      ? moment(formAddEdu.start_date, 'DD/MM/YYYY')
                      : formAddEdu.start_date
                  }
                  onChangeHandle={handleFilterEduStart}
                  error={formAddEdu?.error_start}
                  placeholder={t('common.startDate')}
                />
              </div>
            </Col>
            <Col className="md-6">
              <div>
                <DateMonth
                  label=""
                  valueDefault={
                    typeof formAddEdu.end_date === 'string'
                      ? moment(formAddEdu.end_date, 'DD/MM/YYYY')
                      : formAddEdu.end_date
                  }
                  onChangeHandle={handleFilterEduEnd}
                  error={formAddEdu?.error_end}
                  placeholder={t('common.endDate')}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={t('common.save')}
                addClass="w-100"
                handleClick={() => setShowModal(true)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Form>
      </ModalComponent>
    </>
  );
};

export default Education;
