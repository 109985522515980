import { Table } from 'antd';
import classNames from 'classnames';
import React from 'react';
import CustomPagination from './CustomPagination';

const CustomTable = (props) => {
  const { fixedActionOverlay = 'right', paginationProps, actionOverlay, ...rest } = props;

  const columns = (() => {
    if (props.columns && actionOverlay) {
      return [
        ...props.columns,
        {
          key: 'action',
          fixed: fixedActionOverlay,
          render: (value, record, index) => (
            <div className="flex items-center justify-end">{actionOverlay(value, record, index)}</div>
          ),
        },
      ];
    }
    return props.columns;
  })();

  const customPagination = {};
  if (paginationProps) {
    customPagination.pagination = false;
    customPagination.footer = () => <CustomPagination {...paginationProps} />;
  }

  return <Table  {...rest} {...customPagination} columns={columns} className={classNames(props.className)} />;
};

export default CustomTable;
