import { sendGet } from '../helpers/axios';
//import { sendGet as sendGetAms } from '../helpers/axiosAms';
import { sendGet as sendGetBwf, sendPost as sendPostBwf } from '../helpers/axiosBwf';
import configs from "../config/index";

export const apiGetTimeSheet = (params) => sendGet(`/api/time-sheet/user/${params.userId}`, params);
export const apiGetTotalTimeSheet = (params) => sendGet(`/api/time-sheet/total/user/${params.userId}`, params);

export const apiGetTimeSheetList = (params) => sendGet(`/api/time-sheet`, params);
export const apiGetTimeSheetTotal = (params) => sendGet(`/api/time-sheet/total`, params);

export const apiGetRequestIndividual = (params) => sendGet(`/api/request-personal/timekeeping`, params);
export const apiGetListTypeRequestIndividual = (params) =>
  sendGet(`/api/request-personal/list-type-request-timekeeping`, params);
export const apiGetOtIndividual = (params) => sendGet(`/api/request-personal/ot`, params);
export const apiGetListTypeOtIndividual = (params) => sendGet(`/api/request-personal/list-type-request-ot`, params);

export const apiGetTotalTimekeepingYear = (params) => sendGet('api/time-sheet/dashboard/total/year', params);
export const apiGetTotalTimekeepingMonth = (params) => sendGet('api/time-sheet/dashboard/total/month', params);
export const apiGetLateTimeAllCompany = (params) => sendGet('api/time-sheet/dashboard/late-time', params);

export const apiListTypeOfCategory = (params) => sendGet('api/list-type-of-category', params);

export const apiGetListTableOT = (params) => sendGet('/api/list-overtime', params);

export const apiGetScheduleRequestLeader = (params) => sendGet('/api/schedule-request-leader', params);

export const apiGetHolidayScheduleRequestLeader = (params) => sendGet('/api/holiday/schedule-leader', params);

export const apiGetSchedule = (params) => sendGet(`/api/schedule-standard/${params.user_id}`);
export const apiGetNumberMinutesRegisteredLate = (params) => sendGet('/api/get-late-time', params);
export const apiGetNumberMinutesRegistered = (params) => sendGet('/api/get-total-time', params);
export const apiGetNumberMinutesRegisteredEarly = (params) => sendGet('/api/get-early-time', params);
export const apiGetScheduleOfUser = (params) => sendGet('/api/get-schedule', params);
export const apiGetListUserAccept = (workFollowId, params) =>
  sendGetBwf(`/api/v1/next_person_create/${workFollowId}`, params);
export const apiGetListUserNextPersonUpdate = (workFollowId, step, params) =>
  sendGetBwf(`/api/v1/next_person_update/${workFollowId}/step/${step}`, params);
export const apiGetListCheckbox = (params) => sendGetBwf('/api/v1/hr-checkbox-value', params);
export const apiCreateRequest = (params, workFollowId) => sendPostBwf(`/api/v1/user_request/${workFollowId}`, params);
export const apiUpdateRequest = (params, workFollowId) =>
  sendPostBwf(`/api/v1/user_update_request/${workFollowId}`, params);
export const apiRejectRequest = (params, workFollowId) =>
  sendPostBwf(`/api/v1/user_reject_request/${workFollowId}`, params);

export const apiGetProject = (params) => sendGet(`${configs.API_DOMAIN_PROJECT}/api/list-project?disable_paginate=1`, params);

export const apiGetDetailRequest = (params) => sendGetBwf(`/api/v1/request/${params.id}`);

export const apiGetHolidays = (params) => sendGet('/api/holidays', params);